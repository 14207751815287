import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Skeleton } from 'antd';

const RowDetail = ({ classes, type, name, varity, title }) => (
  <Grid item container spacing={3}>
    <Grid item xs={4}>
      <div className={`${classes.type} ${title ? 'faded' : ''}`}>{type}</div>
    </Grid>
    <Grid item xs={4}>
      <div className={`${classes.name} ${title ? 'faded' : ''}`}>{name}</div>
    </Grid>
    <Grid item xs={4}>
      <div className={`${classes.varity} ${title ? 'faded' : ''}`}>{varity}</div>
    </Grid>
  </Grid>
);

const useStyles = makeStyles(() => ({
  header: { fontSize: '1.2rem', fontFamily: 'Alegreya-Sans-Medium' },
  type: {
    fontSize: '1rem',
    fontFamily: 'Alegreya-Sans-Medium',
    '&.faded': {
      opacity: 0.7,
    },
  },
  name: {
    fontSize: '1rem',
    fontFamily: 'Alegreya-Sans-Regular',
    '&.faded': {
      fontFamily: 'Alegreya-Sans-Medium',
      opacity: 0.7,
    },
  },
  varity: {
    fontSize: '1rem',
    fontFamily: 'Alegreya-Sans-Regular',
    '&.faded': {
      fontFamily: 'Alegreya-Sans-Medium',
      opacity: 0.7,
    },
  },
  avatarSkeleton: {
    '& .ant-skeleton-content h3.ant-skeleton-title': {
      marginTop: 0,
    },
    '& .ant-skeleton-content .ant-skeleton-title+.ant-skeleton-paragraph': {
      marginTop: 0,
      '&>li': {
        width: '50%',
      },
      '&>li:nth-child(5),>li:nth-child(6),>li:nth-child(7)': {
        width: '100%',
      },
    },
  },
}));

const Traits = ({ data, rows }) => {
  const classes = useStyles();

  return (
    <Grid item container direction='column' spacing={1}>
      <Grid item>
        <div className={classes.header}>Traits</div>
      </Grid>
      <Grid item container direction='column' spacing={1}>
        <RowDetail type='TYPE' name='NAME' varity='VARITY' classes={classes} title />
        {data ? (
          rows.map(({ label, value }) => (
            <RowDetail
              key={`${label}_${value}`}
              type={label}
              name={data[value].name}
              varity={data[value].rarity}
              classes={classes}
            />
          ))
        ) : (
          <Skeleton
            active
            paragraph={{
              rows: 4,
            }}
            className={classes.avatarSkeleton}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Traits;
