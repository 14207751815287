import { arrayOf, shape, string, instanceOf } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import { CheckCircleIcon } from 'components/Icons';

const PasswordMatcher = ({ value, validationProps }) => {
  const { checkCircleSuccess, checkCircleError } = useTheme();

  return (
    <Grid container spacing={1}>
      {validationProps.map(({ text, rule }) => {
        const fillColor = rule.test(value) ? checkCircleSuccess : checkCircleError;
        return (
          <Grid key={text} item container>
            <Grid item xs={3} />
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <CheckCircleIcon fillColor={fillColor} />
                </Grid>
                <Grid item>{text}</Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

PasswordMatcher.propTypes = {
  value: string,
  validationProps: arrayOf(
    shape({
      text: string,
      rule: instanceOf(RegExp),
      type: string,
      errorMessage: string,
    }),
  ),
};

export default PasswordMatcher;
