import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Button, Col, Row } from 'antd';
import { makeStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import sampleWineRegion from 'assets/images/sample-wineRegion.png';
import grapeAvatar from './avatar.svg';
import GrapeIcon from './grape.svg';
import MapPath from './MapPath.js';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Line } from 'react-lineto';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const useStyles = makeStyles((theme) => ({
  transformWrapper: {
    width: 'inherit',
  },
  svg: {
    width: '100%',
    maxWidth: 547,
    height: '100%',
    maxHeight: 698,
  },
}));

const Map = ({ wines, selectedWine, setSelectedWine }) => {
  const classes = useStyles();
  const [zoomScale, setZoomScale] = useState(1);
  const [id, setID] = useState(1);

  return (
    <Row>
      <Col span={3} style={{ zIndex: 99 }}>
        <span style={{ fontSize: 1, position: 'absolute', top: 200, left: 100 }} id='a'>
          a
        </span>
        <span style={{ fontSize: 1, position: 'absolute', top: 370, left: 100 }} id='t'>
          t
        </span>
        {selectedWine && (
          <Line
            x0={document.getElementById('a')?.getBoundingClientRect().x}
            y0={document.getElementById('a')?.getBoundingClientRect().y}
            x1={document.getElementById('grape-' + id)?.getBoundingClientRect().x}
            y1={document.getElementById('grape-' + id)?.getBoundingClientRect().y}
            borderColor={'#C3A240'}
            borderWidth={3}
            zIndex={9}
          />
        )}
        {selectedWine && (
          <Line
            x0={document.getElementById('t')?.getBoundingClientRect().x}
            y0={document.getElementById('t')?.getBoundingClientRect().y}
            x1={document.getElementById('grape-' + id)?.getBoundingClientRect().x}
            y1={document.getElementById('grape-' + id)?.getBoundingClientRect().y}
            borderColor={'#C3A240'}
            borderWidth={3}
            zIndex={9}
          />
        )}
        {selectedWine && (
          <img id='grape-avatar' src={grapeAvatar} width={180} style={{ marginTop: 200 }} />
        )}
      </Col>
      <Col span={17}>
        <TransformWrapper
          initialScale={zoomScale}
          initialPositionX={1}
          initialPositionY={1}
          onWheel={(a, e) => {
            setSelectedWine(null);
            setZoomScale(a.state.scale);
          }}
          maxScale={40}
          doubleClick={{
            disabled: true,
          }}
          onZoom={(a, e) => {
            setZoomScale(a.state.scale);
          }}
        >
          {({ zoomIn, zoomOut, resetTransform, centerView, ...rest }) => (
            <React.Fragment>
              <div className='tools' style={{ marginLeft: 400, paddingTop: 12 }}>
                <ButtonGroup color='primary' orientation='vertical' style={{ marginLeft: 130 }}>
                  <Button
                    onClick={() => {
                      setZoomScale(zoomScale + 4);
                      zoomIn();
                      setSelectedWine(null);
                    }}
                  >
                    +
                  </Button>
                  <Button
                    disabled={zoomScale <= 1}
                    onClick={() => {
                      setZoomScale(zoomScale - 4);
                      zoomOut();
                      setSelectedWine(null);
                    }}
                  >
                    -
                  </Button>
                </ButtonGroup>
              </div>
              <TransformComponent
                wrapperStyle={{ width: 'inherit' }}
                contentStyle={{ width: 'inherit' }}
              >
                <ScrollContainer
                  className='scroll-container'
                  style={{
                    height: '80vh',
                    overflow: 'auto',
                    width: 'inherit',
                  }}
                  onStartScroll={() => {
                    setSelectedWine(null);
                  }}
                >
                  <svg id='svgMap' viewBox='0 0 547 698' fill='none' className={classes.svg}>
                    <MapPath />
                    {wines.map((wine, idx) => {
                      return (
                        <image
                          id={'grape-' + idx}
                          href={GrapeIcon}
                          x={Math.abs(wine.longitude) + 100}
                          y={Math.abs(wine.latitude) + 100}
                          width={zoomScale === 1 ? 20 : (3 / zoomScale) * 10}
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setSelectedWine(wine);
                            setID(idx);
                          }}
                        />
                      );
                    })}
                  </svg>
                </ScrollContainer>
              </TransformComponent>
            </React.Fragment>
          )}
        </TransformWrapper>

        {zoomScale === 1 && (
          <img
            style={{ position: 'absolute', right: -100, top: 470, color: 'transparent' }}
            src={sampleWineRegion}
          />
        )}
      </Col>
    </Row>
  );
};

export default Map;
