import { number, string, object } from 'prop-types';
import common from '@material-ui/core/colors/common';

const WalletIcon = ({
  className = '',
  fillColor = common.black,
  height = 20,
  style = {},
  width = 20,
}) => (
  <svg
    style={style}
    className={className}
    height={height}
    width={width}
    viewBox='0 0 20 20'
    fill='none'
  >
    <path
      d='M17.5 15V15.8333C17.5 16.75 16.75 17.5 15.8333 17.5H4.16667C3.24167 17.5 2.5 16.75 2.5 15.8333V4.16667C2.5 3.25 3.24167 2.5 4.16667 2.5H15.8333C16.75 2.5 17.5 3.25 17.5 4.16667V5H10C9.075 5 8.33333 5.75 8.33333 6.66667V13.3333C8.33333 14.25 9.075 15 10 15H17.5ZM10 13.3333H18.3333V6.66667H10V13.3333ZM13.3333 11.25C12.6417 11.25 12.0833 10.6917 12.0833 10C12.0833 9.30833 12.6417 8.75 13.3333 8.75C14.025 8.75 14.5833 9.30833 14.5833 10C14.5833 10.6917 14.025 11.25 13.3333 11.25Z'
      fill={fillColor}
    />
  </svg>
);

WalletIcon.propTypes = {
  className: string,
  fillColor: string,
  height: number,
  style: object,
  width: number,
};

export default WalletIcon;
