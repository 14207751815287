import { methods, fetchApi } from 'utils/apiConfig';
const { GET, POST, DELETE } = methods;

const queryUploadTypes = {
  uploadedFiles: 'uploadedFiles',
};

const fetchUploadedFiles = ({ signal }) => fetchApi('/uploaded-files', GET, { signal });

const uploadFile = (formData) =>
  fetchApi('/uploaded-files/uploadMany', POST, {
    data: formData,
  });

const deleteUploadedFile = (id) => fetchApi(`/uploaded-files/deleteBy/${id}`, DELETE);

const deleteUploadedFiles = (ids) =>
  fetchApi('/uploaded-files/deleteMany', DELETE, { data: { ids } });

export {
  queryUploadTypes,
  fetchUploadedFiles,
  deleteUploadedFile,
  deleteUploadedFiles,
  uploadFile,
};
