import { number, string, object } from 'prop-types';
import common from '@material-ui/core/colors/common';

const AttachmentIcon = ({
  className = '',
  fillColor = common.black,
  height = 25,
  style = {},
  width = 25,
}) => (
  <svg style={style} className={className} height={height} viewBox='0 0 22 10' width={width}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.2539 9.5H5.67393C3.58393 9.5 1.72393 7.97 1.52393 5.89C1.29393 3.51 3.16393 1.5 5.50393 1.5H17.8639C19.1739 1.5 20.3639 2.44 20.4939 3.74C20.6439 5.24 19.4739 6.5 18.0039 6.5H7.50393C6.95393 6.5 6.50393 6.05 6.50393 5.5C6.50393 4.95 6.95393 4.5 7.50393 4.5H16.2539C16.6639 4.5 17.0039 4.16 17.0039 3.75C17.0039 3.34 16.6639 3 16.2539 3H7.64393C6.33393 3 5.14393 3.94 5.01393 5.24C4.86393 6.74 6.03393 8 7.50393 8H17.8339C19.9239 8 21.7839 6.47 21.9839 4.39C22.2139 2 20.3439 0 18.0039 0H5.73393C2.86393 0 0.293929 2.1 0.0239288 4.96C-0.276071 8.25 2.28393 11 5.50393 11H16.2539C16.6639 11 17.0039 10.66 17.0039 10.25C17.0039 9.84 16.6639 9.5 16.2539 9.5Z'
      fill={fillColor}
    />
  </svg>
);

AttachmentIcon.propTypes = {
  className: string,
  fillColor: string,
  height: number,
  style: object,
  width: number,
};

export default AttachmentIcon;
