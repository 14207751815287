import { number, string, object } from 'prop-types';
import common from '@material-ui/core/colors/common';

const SendImageIcon = ({
  className = '',
  fillColor = common.black,
  height = 25,
  style = {},
  width = 25,
}) => (
  <svg style={style} className={className} height={height} viewBox='0 0 20 13' width={width}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.4 16.8849L18.85 9.40488C19.66 9.05488 19.66 7.91488 18.85 7.56488L1.4 0.0848802C0.74 -0.20512 0.01 0.28488 0.01 0.99488L0 5.60488C0 6.10488 0.37 6.53488 0.87 6.59488L15 8.48488L0.87 10.3649C0.37 10.4349 0 10.8649 0 11.3649L0.01 15.9749C0.01 16.6849 0.74 17.1749 1.4 16.8849Z'
      fill={fillColor}
    />
  </svg>
);

SendImageIcon.propTypes = {
  className: string,
  fillColor: string,
  height: number,
  style: object,
  width: number,
};

export default SendImageIcon;
