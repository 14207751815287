import VinesMap from './VinesMap';
import VineyardMap from './VineyardMap';
import BlockMap from './BlockMap';

const Map = ({ activeTab, wines, selectedWine, setSelectedWine }) => {
  return (
    <>
      {activeTab === 'vines' && <VinesMap
        wines={wines || []}
        selectedWine={selectedWine}
        setSelectedWine={setSelectedWine}
      />}
      {activeTab === 'block' && <BlockMap />}
      {activeTab === 'vineyard' && <VineyardMap />}
    </>
  );
};

export default Map;
