import { useContext, useCallback } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Divider } from 'antd';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { LogoIcon } from 'components/Icons';
import { AppDataContext } from 'Context';
import { login } from 'store/auth/login';

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    height: 'inherit',
  },
  boxContainer: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '60%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '70%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '85%',
    },
    padding: theme.spacing(4),
    backgroundColor: theme.boxBackgroundColor,
    boxShadow: theme.boxShadow,
    borderRadius: 8,
  },
  loginText: {
    fontSize: '1.8rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.7rem',
    },
  },
  formElement: { width: '100%' },
  textField: {
    '& label.Mui-focused': {
      color: theme.brown,
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: theme.grey[600],
      },
      '&.Mui-error:hover fieldset': {
        borderColor: theme.muiError,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.brown,
      },
      '&.Mui-focused.Mui-error fieldset': {
        borderColor: theme.muiError,
      },
    },
    '& legend span': {
      padding: 10,
    },
  },
  button: {
    textTransform: 'none',
    borderRadius: 25,
    height: theme.spacing(6.5),
    fontWeight: 'bold',
    fontSize: '1.3rem',
    backgroundColor: theme.brown,
    color: theme.common.white,
    '&:hover': {
      color: theme.common.white,
      backgroundColor: theme.lightBrown,
      borderColor: theme.lightBrown,
    },
  },
  circular: { color: theme.common.white },
  loginErrorMessage: {
    paddingTop: theme.spacing(1.5),
    fontSize: '1rem',
    color: theme.muiError,
  },
  divider: { margin: 0 },
  link: {
    color: theme.brown,
    borderBottom: `1px solid ${theme.brown}`,
    fontSize: '1.1rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '.9rem',
    },
    '&:hover': {
      color: theme.lightBrown,
    },
  },
}));

const Login = () => {
  const { onSetAuth } = useContext(AppDataContext);
  const classes = useStyles();
  const {
    common: { black },
  } = useTheme();

  const { error, isLoading, mutate, reset } = useMutation({
    mutationFn: login,
    onSuccess: ({ jwt }) => {
      onSetAuth(jwt, true);
    },
  });

  const { handleSubmit, handleChange, getFieldProps, errors, touched } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Please enter a valid email address')
        .required('Email address is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: (data) => {
      mutate(data);
    },
  });

  const handleFieldChange = useCallback(
    (e) => {
      if (error) reset();
      handleChange(e);
    },
    [error, handleChange],
  );

  return (
    <Grid container justifyContent='center' alignItems='center' className={classes.loginContainer}>
      <Grid item className={classes.boxContainer}>
        <Grid container direction='column' alignItems='center' spacing={3}>
          <Grid item>
            <LogoIcon width={160} height={40} fillColor={black} />
          </Grid>
          <Grid item container direction='column' alignItems='center' spacing={4}>
            <Grid item>
              <div className={classes.loginText}>Log in</div>
            </Grid>
            <Grid item container>
              <form className={classes.formElement} onSubmit={handleSubmit}>
                <Grid container direction='column' spacing={3}>
                  <Grid item>
                    <TextField
                      label='Email address'
                      variant='outlined'
                      fullWidth
                      classes={{ root: classes.textField }}
                      error={!!errors.email && !!touched.email}
                      helperText={errors.email && touched.email && errors.email}
                      {...getFieldProps('email')}
                      onChange={handleFieldChange}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label='Password'
                      variant='outlined'
                      type='password'
                      fullWidth
                      classes={{ root: classes.textField }}
                      error={!!errors.password && !!touched.password}
                      helperText={errors.password && touched.password && errors.password}
                      {...getFieldProps('password')}
                      onChange={handleFieldChange}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={isLoading}
                      type='submit'
                      fullWidth
                      size='large'
                      classes={{ root: classes.button }}
                    >
                      {isLoading ? (
                        <CircularProgress size={30} classes={{ root: classes.circular }} />
                      ) : (
                        'Log in'
                      )}
                    </Button>
                    {error && <div className={classes.loginErrorMessage}>{error.message}</div>}
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item container>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
          <Grid item container justifyContent='center' spacing={2}>
            <Grid item>
              <Link to='/auth/register' className={classes.link}>
                Create account
              </Link>
            </Grid>
            <Grid item>
              <Link to='/auth/forgot-password' className={classes.link}>
                Forgot Password
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
