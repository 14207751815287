import { methods, fetchApi } from 'utils/apiConfig';
const { GET, PATCH } = methods;

const queryNotificationsTypes = {
  notifications: 'notifications',
};

const fetchNotifications = ({ signal }) =>
  fetchApi('/userNotifications/getAllAvailableNotificationsByCondition', GET, { signal });

const clickNotification = (data) =>
  fetchApi('/userNotifications/updateUserNotification_CTAClickedStatus', PATCH, { data });

const updateNotificationReadStatus = (data) =>
  fetchApi('/userNotifications/updateUserNotification_ReadStatus', PATCH, { data });

const dismissNotification = (data) =>
  fetchApi('/userNotifications/updateUserNotification_DismissedStatus', PATCH, { data });

export {
  queryNotificationsTypes,
  fetchNotifications,
  clickNotification,
  updateNotificationReadStatus,
  dismissNotification,
};
