import { QueryClient } from '@tanstack/react-query';

// react-query global configuration & initialization
const queryClientInitialization = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
      },
    },
  });

export default queryClientInitialization;
