import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { WagmiConfig } from 'wagmi';
import App from 'App';
import { AppDataProvider } from 'Context';
import theme from 'assets/theme';
import { initApiConfiguration } from 'utils/apiConfig';
import queryClientInitialization from 'store/storeConfig';
import { wagmiClientInitialization } from 'config/wallets';
import { alertTemplateOptions } from 'config/ui';

import 'antd/dist/antd.min.css';
import 'assets/styles/index.css';

// server state initialization
const queryClient = queryClientInitialization();

// web3 client initialization
const wagmiClient = wagmiClientInitialization();

//axios configuration setup
initApiConfiguration();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  /**
   * Tricky behaviour of useEffect in StrictMode in React18 (called twice on mount/unmount)
   * Waiting for possible fix
   */
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <AlertProvider template={AlertTemplate} {...alertTemplateOptions}>
        <CssBaseline />
        <AppDataProvider>
          <BrowserRouter>
            <WagmiConfig client={wagmiClient}>
              <App />
            </WagmiConfig>
          </BrowserRouter>
        </AppDataProvider>
      </AlertProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ThemeProvider>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
