import { number, string, object } from 'prop-types';
import common from '@material-ui/core/colors/common';

const ArrowBottomIcon = ({
  className = '',
  fillColor = common.black,
  height = 25,
  style = {},
  width = 25,
}) => (
  <svg style={style} className={className} height={height} viewBox='0 0 11 11' width={width}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.293031 1.29308C0.480558 1.10561 0.734866 1.00029 1.00003 1.00029C1.26519 1.00029 1.5195 1.10561 1.70703 1.29308L5.00003 4.58608L8.29303 1.29308C8.38528 1.19757 8.49562 1.12139 8.61763 1.06898C8.73963 1.01657 8.87085 0.988985 9.00363 0.987831C9.13641 0.986677 9.26809 1.01198 9.39098 1.06226C9.51388 1.11254 9.62553 1.18679 9.71943 1.28069C9.81332 1.37458 9.88757 1.48623 9.93785 1.60913C9.98813 1.73202 10.0134 1.8637 10.0123 1.99648C10.0111 2.12926 9.98354 2.26048 9.93113 2.38249C9.87872 2.50449 9.80254 2.61483 9.70703 2.70708L5.70703 6.70708C5.5195 6.89455 5.26519 6.99987 5.00003 6.99987C4.73487 6.99987 4.48056 6.89455 4.29303 6.70708L0.293031 2.70708C0.10556 2.51955 0.000244141 2.26525 0.000244141 2.00008C0.000244141 1.73492 0.10556 1.48061 0.293031 1.29308Z'
      fill={fillColor}
      fillOpacity='0.86'
    />
  </svg>
);

ArrowBottomIcon.propTypes = {
  className: string,
  fillColor: string,
  height: number,
  style: object,
  width: number,
};

export default ArrowBottomIcon;
