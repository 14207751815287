import { methods, fetchApi } from 'utils/apiConfig';
const { GET } = methods;

const queryUserTypes = {
  users: 'users',
};

const fetchUsers = ({ signal }) => fetchApi('/users', GET, { signal });

export { queryUserTypes, fetchUsers };
