import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LocationInfo from '../../components/LocationDetails';

const locationDetailsVinesMock = [
  { value: 'name', label: 'Name' },
  { value: 'soil_type', label: 'Soil Type' },
  { value: 'slope', label: 'Slope' },
];

const useStyles = makeStyles(() => ({
  blockContainer: {},
}));

const Block = ({ wine }) => {
  const classes = useStyles();

  return (
    <Grid container direction='column' className={classes.blockContainer}>
      <LocationInfo data={wine?.block} rows={locationDetailsVinesMock} />
    </Grid>
  );
};

export default Block;
