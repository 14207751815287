const pollCategories = [
  {
    id: 1,
    title: 'Community Voting',
  },
  {
    id: 2,
    title: 'Shareholders Voting',
  },
];

export default pollCategories;
