import { Tabs } from 'antd';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TabBarrels from './Tabs/Barrels';
import TabBottles from './Tabs/Bottles';
import TabVines from './Tabs/Vines';
import LocationInfo from '../../components/LocationDetails';

const tabs = [
  { label: 'Vines', key: 'Vines', children: <TabVines /> },
  { label: 'Bottles', key: 'Bottles', children: <TabBottles /> },
  { label: 'Barrels', key: 'Barrels', children: <TabBarrels /> },
];

const locationDetailsVineyardMock = [
  { value: 'name', label: 'Name' },
  { value: 'soil_type', label: 'Soil Type' },
  { value: 'slope', label: 'Slope' },
];

const useStyles = makeStyles(() => ({}));

const Content = ({ wine }) => {
  return (
    <Grid container spacing={7} direction='column'>
      <LocationInfo rows={locationDetailsVineyardMock} data={wine?.vineyard} />
      <Grid item>
        <Tabs defaultActiveKey={tabs[0].key} items={tabs} />
      </Grid>
    </Grid>
  );
};

export default Content;
