import { useState, useEffect, useCallback, useMemo, createContext } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import jwt_decode from 'jwt-decode';
import { useAlert } from 'react-alert';
import { DateTime } from 'luxon';
import {
  getTokenFromStorage,
  setTokenOnApiHeaders,
  setTokenOnStorage,
  removeTokenFromApiHeaders,
  removeTokenFromStorage,
} from 'utils/apiConfig';
import { fetchProfile, queryProfileTypes } from 'store/auth/profile';

const AppDataContext = createContext({});

const defaultAppData = {
  initialLoad: true,
  redirectAfterAuth: false,
  auth: null,
  user: null,
  votingData: null,
  walletData: null,
};

const useContextData = () => {
  const queryClient = useQueryClient();
  const alert = useAlert();
  const [appData, setAppData] = useState(defaultAppData);

  const onSetAppData = useCallback((data, context, clean = false) => {
    setAppData((prev) => {
      const prevContextData = clean ? {} : prev[context];
      return { ...prev, [context]: { ...prevContextData, ...data } };
    });
  }, []);

  const onResetAppData = useCallback((context) => {
    setAppData((prev) => ({ ...prev, [context]: defaultAppData[context] }));
  }, []);

  const onSetAuth = useCallback((token, fromLogin = false) => {
    setTokenOnApiHeaders(token);
    setTokenOnStorage(token);
    setAppData((prev) => ({ ...prev, auth: { token, fromLogin } }));
  }, []);

  const onSetUser = useCallback((data) => {
    setAppData((prev) => ({ ...prev, initialLoad: false, user: { ...data } }));
  }, []);

  const onLogout = useCallback((data) => {
    removeTokenFromApiHeaders();
    removeTokenFromStorage();
    setAppData({ ...defaultAppData, ...data, initialLoad: false });
  }, []);

  useEffect(() => {
    const token = getTokenFromStorage();
    if (!token) {
      setAppData((prev) => ({ ...prev, initialLoad: false, redirectAfterAuth: true }));
      return;
    }
    const { exp } = jwt_decode(token);
    const now = DateTime.now().toMillis();
    const then = DateTime.fromSeconds(exp).toMillis();

    if (now > then) {
      onLogout({ redirectAfterAuth: true });
      return;
    }

    onSetAuth(token);
  }, []);

  useQuery({
    queryKey: [queryProfileTypes.profile],
    queryFn: fetchProfile,
    enabled: !!appData.auth,
    onSuccess: (data) => {
      onSetUser(data);
      if (appData.auth.fromLogin)
        alert.success(
          <div>
            <strong>You have successfully logged in</strong>
          </div>,
        );
    },
    onError: (error) => {
      onLogout();
      alert.error(
        <div>
          <strong>An error occured while retrieving user profile</strong>
          <br />
          {error.message}
        </div>,
      );
    },
  });

  useEffect(() => {
    if (!appData.auth) queryClient.removeQueries({ queryKey: [queryProfileTypes.profile] });
  }, [appData.auth]);

  // context provider data
  const contextData = useMemo(
    () => ({
      appData,
      onLogout,
      onSetAppData,
      onResetAppData,
      onSetAuth,
      onSetUser,
      setAppData,
    }),
    [appData],
  );

  return contextData;
};

const AppDataProvider = ({ children }) => {
  const contextData = useContextData();

  return <AppDataContext.Provider value={contextData}>{children}</AppDataContext.Provider>;
};

export { AppDataContext, AppDataProvider };
