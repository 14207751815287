import { methods, fetchApi } from 'utils/apiConfig';
const { GET, POST, DELETE } = methods;

const queryWhitelistedAccountTypes = {
  whitelistedAccounts: 'whitelistedAccounts',
};

const fetchWhitelistedAccounts = ({ signal }) =>
  fetchApi('/whiteListAccounts/getAllAccounts', GET, { signal });

const addAccountToWhitelist = (data) =>
  fetchApi('/whiteListAccounts/addAccountToWhiteList', POST, { data });

const deleteWhitelistedAccount = (id) =>
  fetchApi(`/whiteListAccounts/removeWhitelistAccountById/${id}`, DELETE);

const deleteWhitelistedAccounts = (ids) =>
  fetchApi('/whiteListAccounts/deleteMany', DELETE, { data: { ids } });

export {
  queryWhitelistedAccountTypes,
  fetchWhitelistedAccounts,
  addAccountToWhitelist,
  deleteWhitelistedAccount,
  deleteWhitelistedAccounts,
};
