import { number, string, object } from 'prop-types';
import common from '@material-ui/core/colors/common';

const HappyFaceIcon = ({
  className = '',
  fillColor = common.black,
  height = 50,
  style = {},
  width = 50,
}) => (
  <svg style={style} className={className} height={height} viewBox='0 0 50 50' width={width}>
    <path
      d='M20 0C8.97201 0 0 8.97201 0 20C0 31.028 8.97201 40 20 40C31.028 40 40 31.028 40 20C40 8.97201 31.0281 0 20 0ZM20 37.8378C15.1005 37.8378 10.6562 35.8518 7.42929 32.6431C6.14062 31.3618 5.04669 29.885 4.19492 28.262C2.89761 25.7905 2.16219 22.9798 2.16219 20C2.16219 10.1642 10.1642 2.16219 20 2.16219C24.6653 2.16219 28.9173 3.9633 32.0992 6.90577C33.749 8.4312 35.1113 10.2631 36.0936 12.3108C37.2112 14.6403 37.8378 17.2483 37.8378 20C37.8378 29.8358 29.8358 37.8378 20 37.8378Z'
      fill={fillColor}
    />
    <path
      d='M14.8064 16.9643C15.2164 16.5708 15.4729 16.0185 15.4729 15.4054C15.4729 14.2113 14.5048 13.2432 13.3107 13.2432C12.1166 13.2432 11.1485 14.2113 11.1485 15.4054C11.1485 16.0185 11.4049 16.5709 11.8151 16.9643C12.2035 17.3373 12.7299 17.5675 13.3107 17.5675C13.8915 17.5675 14.4179 17.3373 14.8064 16.9643Z'
      fill={fillColor}
    />
    <path
      d='M28.1849 16.9643C28.5949 16.5708 28.8514 16.0185 28.8514 15.4054C28.8514 14.2113 27.8832 13.2432 26.6892 13.2432C25.4951 13.2432 24.527 14.2113 24.527 15.4054C24.527 16.0185 24.7835 16.5709 25.1936 16.9643C25.582 17.3373 26.1084 17.5675 26.6892 17.5675C27.27 17.5675 27.7963 17.3373 28.1849 16.9643Z'
      fill={fillColor}
    />
    <path
      d='M19.9835 29.16C21.6032 29.16 22.9778 28.0908 23.4386 26.6211H16.5281C16.9891 28.0908 18.3637 29.16 19.9835 29.16Z'
      fill='white'
    />
    <path
      d='M25.7658 25.5411V24.46H14.144V25.5411C14.144 28.7295 16.7665 31.3233 19.9549 31.3233C23.1434 31.3233 25.7658 28.7295 25.7658 25.5411ZM23.4388 26.6222C22.9779 28.0919 21.6033 29.1611 19.9836 29.1611C18.3638 29.1611 16.9892 28.0919 16.5284 26.6222H23.4388Z'
      fill={fillColor}
    />
  </svg>
);

HappyFaceIcon.propTypes = {
  className: string,
  fillColor: string,
  height: number,
  style: object,
  width: number,
};

export default HappyFaceIcon;
