import walletImg from 'assets/images/wallet.jpg';

const styles = (theme) => ({
  walletContainer: {
    height: 'inherit',
    backgroundImage: `url(${walletImg})`,
    backgroundSize: 'cover',
    '&.black': {
      backgroundImage: `linear-gradient(to right, ${theme.common.fadedBlack}, ${theme.common.fadedBlack}), url(${walletImg})`,
    },
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: theme.common.white },
  boxContainer: {
    width: '45%',
    [theme.breakpoints.down('md')]: {
      width: '55%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '65%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
    backgroundColor: theme.grey.A400,
    color: theme.common.white,
    borderRadius: 12,
    paddingTop: theme.spacing(1),
    '&.black': {
      backgroundColor: theme.grey[200],
      color: theme.grey[900],
    },
  },
  bigHeader: {
    fontSize: '3.2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.8rem',
    },
  },
  mediumHeader: {
    fontSize: '2.1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.9rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.7rem',
    },
  },
  mediumText: {
    fontSize: '1.15rem',
    '&.spaceLeft': { paddingLeft: theme.spacing(0.3) },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      '&.spaceLeft': { paddingLeft: theme.spacing(1) },
    },
    '&.center': { textAlign: 'center' },
    '&.padding': {
      padding: `0 ${theme.spacing(6)}px`,
    },
  },
  marginRight: {
    marginRight: 15,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  pointer: { cursor: 'pointer' },
  providerContainer: {
    position: 'relative',
    border: `1px solid ${theme.jet}`,
    borderRadius: 8,
    cursor: 'pointer',
    '& .MuiGrid-item': {
      padding: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
    },
    '&.selected': {
      backgroundColor: theme.grey[400],
      opacity: 0.8,
    },
  },
  providerName: {
    fontSize: '1.3rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem',
    },
  },
  walletImg: {
    width: 36,
    height: 36,
  },
  circularProgressContainer: {
    position: 'absolute',
    width: 'inherit',
    display: 'flex',
    justifyContent: 'center',
  },
  circular: { color: theme.common.white },
  customXsGrid: {
    maxWidth: '4%',
    flexBasis: '4%',
  },
  exclamationIcon: { marginTop: 2 },
  status: {
    backgroundColor: theme.success.dark,
    fontWeight: 'bold',
    padding: `${theme.spacing(0.4)}px ${theme.spacing(1.2)}px`,
    borderRadius: 8,
  },
  button: {
    textTransform: 'none',
    borderRadius: 25,
    fontWeight: 'bold',
    backgroundColor: theme.copperRed,
    color: theme.common.white,
    '&:hover': {
      color: theme.common.white,
      backgroundColor: theme.lightBrown,
      borderColor: theme.lightBrown,
    },
    height: theme.spacing(6.5),
    fontSize: '1.3rem',
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(6.5),
      fontSize: '1.1rem',
    },
  },
  countInfo: {
    fontSize: '1rem',
    opacity: 0.8,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
    },
  },
  countValue: {
    fontSize: '1.6rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.45rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3rem',
    },
  },
  remainingTimeDigit: {
    marginRight: theme.spacing(0.5),
    fontSize: '1.9rem',
    opacity: 0.8,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.6rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  remainingTimeChar: {
    marginRight: theme.spacing(1),
    fontSize: '1rem',
    opacity: 0.8,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
    },
  },
  monkImg: {
    width: 120,
    height: 120,
    [theme.breakpoints.down('sm')]: {
      width: 100,
      height: 100,
    },
    [theme.breakpoints.down('xs')]: {
      width: 80,
      height: 80,
    },
    borderRadius: 15,
  },
  howTo: {
    '&.MuiGrid-item': {
      paddingBottom: theme.spacing(6),
      [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacing(4),
      },
    },
  },
});

export default styles;
