import { number, string, object } from 'prop-types';
import common from '@material-ui/core/colors/common';

const ExclamationCircleIcon = ({
  className = '',
  fillColor = common.black,
  height = 20,
  style = {},
  width = 20,
}) => (
  <svg
    style={style}
    className={className}
    height={height}
    width={width}
    viewBox='0 0 16 16'
    fill='none'
  >
    <path
      d='M15.5 8C15.5 9.98912 14.7098 11.8968 13.3033 13.3033C11.8968 14.7098 9.98912 15.5 8 15.5C6.01088 15.5 4.10322 14.7098 2.6967 13.3033C1.29018 11.8968 0.5 9.98912 0.5 8C0.5 6.01088 1.29018 4.10322 2.6967 2.6967C4.10322 1.29018 6.01088 0.5 8 0.5C9.98912 0.5 11.8968 1.29018 13.3033 2.6967C14.7098 4.10322 15.5 6.01088 15.5 8ZM9.06066 13.0607C9.34196 12.7794 9.5 12.3978 9.5 12C9.5 11.6022 9.34196 11.2206 9.06066 10.9393C8.77936 10.658 8.39783 10.5 8 10.5C7.60217 10.5 7.22064 10.658 6.93934 10.9393C6.65804 11.2206 6.5 11.6022 6.5 12C6.5 12.3978 6.65804 12.7794 6.93934 13.0607C7.22064 13.342 7.60217 13.5 8 13.5C8.39783 13.5 8.77936 13.342 9.06066 13.0607ZM8 2.5C7.60217 2.5 7.22064 2.65804 6.93934 2.93934C6.65804 3.22064 6.5 3.60217 6.5 4V8C6.5 8.39783 6.65804 8.77936 6.93934 9.06066C7.22064 9.34196 7.60217 9.5 8 9.5C8.39783 9.5 8.77936 9.34196 9.06066 9.06066C9.34196 8.77936 9.5 8.39783 9.5 8V4C9.5 3.60217 9.34196 3.22064 9.06066 2.93934C8.77936 2.65804 8.39783 2.5 8 2.5Z'
      fill={fillColor}
      stroke={fillColor}
    />
  </svg>
);

ExclamationCircleIcon.propTypes = {
  className: string,
  fillColor: string,
  height: number,
  style: object,
  width: number,
};

export default ExclamationCircleIcon;
