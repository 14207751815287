import Admin from 'routes/Admin';
import DataManagement from 'routes/Admin/DataManagement';
import Uploads from 'routes/Admin/Uploads';
import Polls from 'routes/Admin/Polls';
import CreatePoll from 'routes/Admin/Polls/CreatePoll';
import Login from 'routes/Auth/Login';
import ForgotPassword from 'routes/Auth/ForgotPassword';
import ResetPassword from 'routes/Auth/ResetPassword';
import Register from 'routes/Auth/Register';
import EmailVerify from 'routes/Auth/EmailVerify';
import Home from 'routes/Home';
import Settings from 'routes/Settings';
import Wallet from 'routes/Wallet';

// app routes schema
const routeSchema = [
  {
    path: '/admin',
    element: Admin,
    authRequired: true,
    adminRoute: true,
    routes: [
      {
        path: '/admin/uploads',
        element: Uploads,
      },
      {
        path: '/admin/data-management',
        element: DataManagement,
      },
      {
        path: '/admin/polls',
        element: Polls,
      },
      {
        path: '/admin/polls/create',
        element: CreatePoll,
      },
      {
        path: '/admin',
        redirectPath: '/admin/uploads',
        fallback: true,
      },
    ],
  },
  {
    path: '/auth/login',
    element: Login,
    authRequired: false,
  },
  {
    path: '/auth/register',
    element: Register,
    authRequired: false,
  },
  {
    path: '/auth/verify',
    element: EmailVerify,
    authRequired: false,
  },
  {
    path: '/auth/forgot-password',
    element: ForgotPassword,
    authRequired: false,
  },
  {
    path: '/auth/password-reset',
    element: ResetPassword,
    authRequired: false,
  },
  {
    path: '/home',
    element: Home,
    authRequired: true,
  },
  {
    path: '/settings',
    element: Settings,
    authRequired: true,
  },
  {
    path: '/sotw/mint',
    element: Wallet,
  },
  {
    path: '*',
    redirectPath: '/home',
    fallback: true,
  },
];

export default routeSchema;
