const walletBtnStyles = (theme) => ({
  borderRadius: 50,
  fontSize: '0.9rem',
  textTransform: 'none',
  marginBottom: theme.spacing(0.7),
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.8rem',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '0.7rem',
  },
});

const styles = (theme) => ({
  headerContainer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3.5)}px ${theme.spacing(0.3)}px 0`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(0.3)}px 0`,
    },
    borderBottom: `1px solid ${theme.grey[600]}`,
    backgroundColor: theme.greyHeader,
    '&.hide': {
      display: 'none',
    },
    '&.lightGreyBg': {
      borderBottom: `1px solid ${theme.grey[300]}`,
      backgroundColor: theme.grey[200],
    },
    '&.transparent': {
      borderBottom: `1px solid ${theme.grey[300]}`,
      backgroundColor: 'transparent',
    },
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 1000,
  },
  logo: {
    paddingTop: theme.spacing(0.3),
    cursor: 'pointer',
  },
  noNotificationsContainer: {
    width: 337,
    height: 520,
  },
  notificationBellImage: {
    maxWidth: 190,
    maxHeight: 180,
  },
  actionIcon: {
    cursor: 'pointer',
  },
  discordBtn: {
    backgroundColor: theme.common.white,
    color: theme.common.black,
    ...walletBtnStyles(theme),
  },
  walletConnectBtn: {
    backgroundColor: theme.copperRed,
    color: theme.common.white,
    ...walletBtnStyles(theme),
  },
  overlay: {
    '& .ant-dropdown-menu': { padding: 0 },
    '&.notifications ul': {
      maxWidth: 337,
      maxHeight: 690,
      overflowY: 'scroll',
      '& li': { borderBottom: `1px solid ${theme.grey[200]}` },
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    '&.scrollable.notifications ul': {
      '&::-webkit-scrollbar': {
        width: 9,
        height: 8,
        display: 'block',
      },
      '&::-webkit-scrollbar-track': {
        background: 'inherit',
      },
      '&::-webkit-scrollbar-thumb': {
        visibility: 'hidden',
      },
      '&:hover::-webkit-scrollbar-thumb': {
        background: theme.grey[400],
        borderRadius: 4,
        visibility: 'visible',
      },
    },
  },
  badge: {
    backgroundColor: theme.orange,
    color: theme.common.white,
  },
  link: {
    padding: `${theme.spacing(1.2)}px ${theme.spacing(3)}px ${theme.spacing(1.2)}px ${theme.spacing(
      0.5,
    )}px`,
    '& a': {
      color: 'inherit',
    },
  },
});

export default styles;
