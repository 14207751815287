import { number, string, object } from 'prop-types';
import common from '@material-ui/core/colors/common';

const ResizeIcon = ({
  className = '',
  fillColor = common.black,
  height = 25,
  style = {},
  width = 25,
}) => (
  <svg style={style} className={className} height={height} viewBox='0 0 18 12' width={width}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 2H8.48528L5.77817 4.70711L4.70711 5.77817L4.70652 5.77877L2 8.48528V8C2 7.45 1.55 7 1 7C0.45 7 0 7.45 0 8V11C0 11.55 0.45 12 1 12H4C4.55 12 5 11.55 5 11C5 10.45 4.55 10 4 10H3.31371L6.12132 7.19239L7.19239 6.12132L7.19266 6.12105L10 3.31371V4C10 4.55 10.45 5 11 5C11.55 5 12 4.55 12 4V1C12 0.45 11.55 0 11 0H8C7.45 0 7 0.45 7 1C7 1.55 7.45 2 8 2Z'
      fill={fillColor}
    />
  </svg>
);

ResizeIcon.propTypes = {
  className: string,
  fillColor: string,
  height: number,
  style: object,
  width: number,
};

export default ResizeIcon;
