import Grid from '@material-ui/core/Grid';
import LocationDetails from '../../components/LocationDetails';
import Traits from './Traits';
import VineHealth from './VineHealth';

const locationDetailsVinesRows = [
  { value: 'longitude', label: 'Longitude' },
  { value: 'latitude', label: 'Latitude' },
  { value: 'altitude', label: 'Altitude' },
  { value: 'soil_type', label: 'Soil Type' },
  { value: 'slope', label: 'Slope' },
];

const locationTraitsRows = [
  { label: 'Varietal', value: 'varietal_trait' },
  { label: 'Elevation', value: 'elevation_trait' },
  { label: 'Block', value: 'block_trait' },
  { label: 'Slope', value: 'slope_direction_trait' },
  { label: 'Age', value: 'age_trait' },
];

const Vines = ({ wine }) => {
  return (
    <Grid container direction='column' spacing={9}>
      <LocationDetails data={wine} rows={locationDetailsVinesRows} />
      <Traits data={wine} rows={locationTraitsRows} />
      <VineHealth />
    </Grid>
  );
};

export default Vines;
