import { useState, useEffect, useMemo, useCallback, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Tabs } from 'antd';
import { useAlert } from 'react-alert';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { querySettingsTypes, fetchSettings, updateSetting } from 'store/settings';
import whiteBackground from 'assets/images/background.jpg';
import { commonBtnStyles, containedOrangeButtonStyles } from 'assets/styles/common';

const useStyles = makeStyles((theme) => ({
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: theme.common.white },
  settingsContainer: {
    height: 'inherit',
    backgroundImage: `url(${whiteBackground})`,
  },
  content: {
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
    '& .ant-tabs-tab-btn': {
      color: theme.vinesTabItem,
    },
    '& .ant-tabs-tab-btn:hover': {
      color: theme.vinesTabItemSelected,
    },
    '& .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn': {
      color: theme.vinesTabItemSelected,
    },
    '& .ant-tabs-ink-bar': {
      background: theme.satinSheetGold,
    },
    '& .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav': {
      width: 'fit-content',
    },
  },
  tabTitle: { fontSize: theme.spacing(4) },
  tabDescription: { fontSize: theme.spacing(2) },
  tabOptionLabel: { fontSize: theme.spacing(2) },
  tabOptionContent: { fontSize: theme.spacing(1.8) },
  hr: {
    border: `.5px solid ${theme.grey[300]}`,
    width: '42%',
  },
  checked: {
    color: theme.orange,
  },
  footer: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(3.5)}px`,
    backgroundColor: theme.grey[200],
  },
  btn: {
    ...commonBtnStyles,
    borderRadius: 50,
  },
  submitBtn: {
    ...containedOrangeButtonStyles(theme),
    borderRadius: 50,
  },
  saveBtnContainer: {
    marginLeft: theme.spacing(2.5),
  },
}));

const NotificationSettings = () => {
  const classes = useStyles();
  const alert = useAlert();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [initialUserChoices, setInitialUserChoices] = useState({});
  const [userChoices, setUserChoices] = useState({});
  const [activeTabKey, setActiveTabKey] = useState(null);

  const {
    data,
    isFetching: dataLoading,
    error: dataError,
  } = useQuery({
    queryKey: [querySettingsTypes.settings],
    queryFn: fetchSettings,
    onSuccess: (data) => {
      const choices = (data || []).reduce((obj, { tab_channel_id, tab_options }) => {
        if (Array.isArray(tab_options) && tab_options.length) {
          tab_options.forEach(({ category_id, checked }) => {
            obj[`${tab_channel_id}_${category_id}`] = checked;
          });
        }
        return obj;
      }, {});
      setInitialUserChoices(choices);
      setUserChoices(choices);
    },
    onError: (err) => {
      alert.error(
        <div>
          <strong>An error occured while retrieving settings</strong>
          <div>{err.message}</div>
        </div>,
      );
    },
  });

  const { mutate: onUpdateSetting, isLoading: updateSettingIsLoading } = useMutation({
    mutationFn: updateSetting,
    onSuccess: () => {
      alert.success(
        <div>
          <strong>Settings were updated successfully</strong>
        </div>,
      );
      queryClient.invalidateQueries({ queryKey: [querySettingsTypes.settings] });
    },
    onError: (err) => {
      alert.error(
        <div>
          <strong>An error occured while updating settings</strong>
          <div>{err.message}</div>
        </div>,
      );
    },
  });

  const handleSubmit = useCallback(() => {
    const dataToUpdate = Object.keys(userChoices).reduce((arr, key) => {
      if (userChoices[key] !== initialUserChoices[key]) {
        const [tab_channel_id, category_id] = key.split('_');
        arr.push({
          tab_channel_id: parseInt(tab_channel_id),
          category_id: parseInt(category_id),
          checked: userChoices[key],
        });
      }
      return arr;
    }, []);

    onUpdateSetting({ notificationSettings: dataToUpdate });
  }, [userChoices, onUpdateSetting]);

  const items = useMemo(
    () =>
      data
        ? data.map((item) => ({
            key: item.tab_label,
            label: item.tab_label,
            children: (
              <Grid container direction='column' spacing={3}>
                <Grid item container direction='column'>
                  <Grid item className={classes.tabTitle}>
                    {item.tab_title}
                  </Grid>
                  <Grid item className={classes.tabDescription}>
                    {item.tab_description}
                  </Grid>
                </Grid>

                <Grid item container direction='column' spacing={4}>
                  {(item.tab_options || []).map(({ category_id, label, content }) => {
                    const cat_id = `${item.tab_channel_id}_${category_id}`;

                    return (
                      <Fragment key={cat_id}>
                        <Grid item container spacing={2} alignItems='center'>
                          <Grid item>
                            <Checkbox
                              checked={!!userChoices[cat_id]}
                              onChange={() =>
                                setUserChoices((prev) => ({
                                  ...prev,
                                  [cat_id]: !prev[cat_id],
                                }))
                              }
                              color='default'
                              classes={{ checked: classes.checked }}
                            />
                          </Grid>
                          <Grid item>
                            <Grid container direction='column' spacing={1}>
                              <Grid item className={classes.tabOptionLabel}>
                                {label}
                              </Grid>
                              <Grid item className={classes.tabOptionContent}>
                                {content}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <hr className={classes.hr} />
                      </Fragment>
                    );
                  })}
                </Grid>
              </Grid>
            ),
          }))
        : null,
    [data, userChoices],
  );

  useEffect(
    () => () => {
      queryClient.invalidateQueries({ queryKey: [querySettingsTypes.settings] });
    },
    [],
  );

  const saveBtnDisabled = useMemo(
    () =>
      (Object.keys(initialUserChoices).length === Object.keys(userChoices).length &&
        Object.keys(userChoices).every((key) => userChoices[key] === initialUserChoices[key])) ||
      !!dataError,
    [initialUserChoices, userChoices, dataError],
  );

  const loading = dataLoading || updateSettingIsLoading;

  return (
    <>
      {loading && (
        <Backdrop open className={classes.backdrop}>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
      <Grid
        container
        direction='column'
        justifyContent='space-between'
        className={classes.settingsContainer}
      >
        <Grid item className={classes.content}>
          <Tabs
            defaultActiveKey={activeTabKey}
            items={items}
            onChange={(key) => setActiveTabKey(key)}
          />
        </Grid>

        <Grid item container justifyContent='flex-end' className={classes.footer}>
          <Grid item>
            <Button
              onClick={() => {
                navigate('/home');
              }}
              className={classes.btn}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item className={classes.saveBtnContainer}>
            <Button
              disabled={saveBtnDisabled}
              onClick={handleSubmit}
              variant='contained'
              className={classes.submitBtn}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default NotificationSettings;
