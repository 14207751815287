import { number, string, object } from 'prop-types';
import common from '@material-ui/core/colors/common';

const UploadIcon = ({
  className = '',
  fillColor = common.black,
  height = 14,
  style = {},
  width = 14,
}) => (
  <svg
    style={style}
    className={className}
    height={height}
    width={width}
    viewBox='0 0 14 14'
    fill={fillColor}
  >
    <path d='M5.24988 3.9689H6.40457V9.25484C6.40457 9.32359 6.46082 9.37984 6.52957 9.37984H7.46707C7.53582 9.37984 7.59207 9.32359 7.59207 9.25484V3.9689H8.74988C8.85457 3.9689 8.91238 3.84859 8.84832 3.76734L7.09832 1.55171C7.08662 1.53677 7.07168 1.52469 7.05463 1.51638C7.03757 1.50807 7.01885 1.50375 6.99988 1.50375C6.98091 1.50375 6.96218 1.50807 6.94513 1.51638C6.92807 1.52469 6.91313 1.53677 6.90144 1.55171L5.15144 3.76578C5.08738 3.84859 5.14519 3.9689 5.24988 3.9689ZM12.7186 8.78609H11.7811C11.7124 8.78609 11.6561 8.84234 11.6561 8.91109V11.3173H2.34363V8.91109C2.34363 8.84234 2.28738 8.78609 2.21863 8.78609H1.28113C1.21238 8.78609 1.15613 8.84234 1.15613 8.91109V12.0048C1.15613 12.2814 1.37957 12.5048 1.65613 12.5048H12.3436C12.6202 12.5048 12.8436 12.2814 12.8436 12.0048V8.91109C12.8436 8.84234 12.7874 8.78609 12.7186 8.78609Z' />
  </svg>
);

UploadIcon.propTypes = {
  className: string,
  fillColor: string,
  height: number,
  style: object,
  width: number,
};

export default UploadIcon;
