import { useState, useMemo, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAlert } from 'react-alert';
import { Divider, Select } from 'antd';
import Grid from '@material-ui/core/Grid';
import { AppDataContext } from 'Context';
import { queryPollTypes } from 'store/polls';
import { queryUserTypes, fetchUsers } from 'store/users';
import { Collapse } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(() => ({
  select: { width: '90%' },
}));

const Configuration = () => {
  const {
    onSetAppData,
    appData: { user },
  } = useContext(AppDataContext);
  const queryClient = useQueryClient();
  const classes = useStyles();
  const alert = useAlert();

  const [authUser, setAuthUser] = useState(null);
  const [poll, setPoll] = useState(null);
  const [question, setQuestion] = useState(null);

  const [selectedUser, setSelecterUser] = useState(null);
  const [userForView, setUserForView] = useState(null);

  useEffect(() => {
    if (user) setAuthUser({ label: user.username, value: user.id });
  }, [user]);

  const initPolls = queryClient.getQueryData([queryPollTypes.polls]);

  const polls = useMemo(
    () => (initPolls ? initPolls.map(({ title, id }) => ({ label: title, value: id })) : []),
    [initPolls],
  );

  const questions = useMemo(
    () =>
      !!poll
        ? initPolls
            .find((p) => p.id === poll)
            .pollQuestions.map((q) => ({ label: q.content, value: q.id }))
        : [],
    [poll, initPolls],
  );

  // update context data upon completion
  useEffect(() => {
    if (poll && question)
      onSetAppData(
        {
          poll_id: poll,
          pollQuestion_id: question,
        },
        'votingData',
      );
  }, [poll, question]);

  // temporary
  const { data: initUsers } = useQuery({
    queryKey: [queryUserTypes.users],
    queryFn: fetchUsers,
    onSuccess: (data) => {
      console.log(data);
    },
    onError: (e) => {
      console.log(e.message);
      alert.error(
        <div>
          <strong>An error occured while retrieving users</strong>
        </div>,
      );
    },
  });

  useEffect(
    () => () => {
      queryClient.invalidateQueries({ queryKey: [queryUserTypes.users] });
    },
    [],
  );

  const users = useMemo(
    () => (initUsers ? initUsers.map((user) => ({ label: user.email, value: user.id })) : []),
    [initUsers],
  );
  // end

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item container direction='column'>
        <Grid item>Loged in user</Grid>
        <Grid item>
          <Select
            value={authUser}
            className={classes.select}
            placeholder='User'
            disabled
            notFoundContent={<div>No users found</div>}
          />
        </Grid>
      </Grid>

      <Grid item container direction='column'>
        <Grid item>Please select a poll</Grid>
        <Grid item>
          <Select
            value={poll}
            onChange={(poll) => {
              setQuestion(null);
              setPoll(poll);
            }}
            className={classes.select}
            options={polls}
            placeholder='Poll'
            notFoundContent={<div>No polls found</div>}
          />
        </Grid>
      </Grid>

      <Grid item container direction='column'>
        <Grid item>Please select a question</Grid>
        <Grid item>
          <Select
            value={question}
            onChange={(question) => setQuestion(question)}
            className={classes.select}
            options={questions}
            placeholder='Question'
            notFoundContent={<div>No questions found</div>}
          />
        </Grid>
      </Grid>

      <Grid item container>
        <Divider />
      </Grid>

      <Grid item container direction='column'>
        <Grid item>Please select a user to see details</Grid>
        <Grid item>
          <Select
            value={selectedUser}
            onChange={(user) => {
              setSelecterUser(user);
              setUserForView(initUsers.find((u) => u.id === user));
            }}
            className={classes.select}
            options={users}
            placeholder='User'
            notFoundContent={<div>No users found</div>}
          />
        </Grid>
      </Grid>

      <Grid item>
        <Collapse in={!!userForView}>
          <Paper style={{ padding: '5px 0' }}>
            <pre>
              User username: {userForView?.username ?? '-'}
              <br />
              <br />
              User email: {userForView?.email}
              <br />
              <br />
              User first name: {userForView?.first_name ?? '-'}
              <br />
              <br />
              User middle name: {userForView?.middle_name ?? '-'}
              <br />
              <br />
              User last name: {userForView?.last_name ?? '-'}
              <br />
              <br />
              User created at: {userForView?.created_at ?? '-'}
              <br />
              <br />
              User last login at: {userForView?.last_login_at ?? '-'}
              <br />
              <br />
              User mobile: {userForView?.mobile ?? '-'}
            </pre>
          </Paper>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default Configuration;
