import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { CloudyDayIcon, HappyFaceIcon } from 'components/Icons';

const useStyles = makeStyles((theme) => ({
  vineHealthContainer: {
    // padding: theme.spacing(1),
  },
  vineHealthInnerContainer: {
    backgroundColor: theme.common.white,
  },
  header: { fontSize: '1.1rem', fontFamily: 'Alegreya-Sans-Bold' },
  info: { fontFamily: 'Alegreya-Sans-Medium' },
}));

const VineHealth = () => {
  const classes = useStyles();

  return (
    <Grid item container direction='column' spacing={2}>
      <Grid item className={classes.vineHealthInnerContainer}>
        <div className={classes.header}>Vine Health</div>
      </Grid>
      <Grid item container spacing={2} className={classes.vineHealthInnerContainer}>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <div className={classes.info}>May 07, 2022</div>
              <div>
                <CloudyDayIcon />
              </div>
            </Grid>
            <Grid item>
              <div className={classes.value}>76°</div>
              <div className={classes.value}>3 KN</div>
              <div className={classes.value}>0”</div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <div className={classes.info}>Status</div>
              <div>
                <HappyFaceIcon height={65} />
              </div>
            </Grid>
            <Grid item>
              <div className={classes.value}>Happy</div>
              <div className={classes.value}>Healthy</div>
              <div className={classes.value}>Strong</div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VineHealth;
