import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  educationContainer: { color: theme.common.white },
  educationIntro: { fontSize: '1.2rem', fontWeight: 'bold' },
  educationDescription: { fontSize: '0.9rem' },
  iframe: { width: '100%' },
}));

const Education = () => {
  const classes = useStyles();

  return (
    <Grid container direction='column' spacing={2} className={classes.educationContainer}>
      <Grid item>
        <div className={classes.educationIntro}>How watering impacts the flavor of your wine.</div>
      </Grid>

      <Grid item>
        <div className={classes.educationDescription}>
          In this video, we break down the pros and cons of watering your vines.
        </div>
      </Grid>

      <Grid item>
        <iframe
          className={classes.iframe}
          src='https://www.youtube.com/embed/K4TOrB7at0Y?autoplay=0'
          frameBorder='0'
          type='video/mp4'
          autoPlay
          controls
          allowFullScreen
          title='Title'
        />
      </Grid>
    </Grid>
  );
};

export default Education;
