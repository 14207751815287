import { Tabs } from 'antd';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TabVoting from './Tabs/Voting';
import TabEducation from './Tabs/Education';
import TabDiscussion from './Tabs/Discussion';
import SeasonProgress from './SeasonProgress';

const tabs = [
  { label: 'Voting', key: 'Voting', children: <TabVoting /> },
  { label: 'Education', key: 'Education', children: <TabEducation /> },
  { label: 'Discussion', key: 'Discussion', children: <TabDiscussion /> },
];

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    position: 'relative',
    zIndex: 1,
    padding: theme.spacing(2),
  },
  contentTabsContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.jet,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    '& .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before': {
      borderBottom: `1px solid ${theme.common.fadedWhite}`,
    },
    '& .ant-tabs-tab-btn': {
      color: theme.common.fadedWhite,
    },
    '& .ant-tabs-tab-btn:hover': {
      color: theme.common.white,
    },
    '& .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn': {
      color: theme.satinSheetGold,
    },
    '& .ant-tabs-ink-bar': {
      background: theme.satinSheetGold,
    },
    '& .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav': {
      width: 'fit-content',
    },
  },
  contentSeasonProgressContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.grey[100],
  },
}));

const Content = () => {
  const classes = useStyles();

  return (
    <Grid container direction='column' className={classes.contentContainer}>
      <Grid item className={classes.contentTabsContainer}>
        <Tabs defaultActiveKey={tabs[0].key} items={tabs} />
      </Grid>
      <Grid item className={classes.contentSeasonProgressContainer}>
        <SeasonProgress />
      </Grid>
    </Grid>
  );
};

export default Content;
