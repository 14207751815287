import { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Button } from 'antd';
import Grid from '@material-ui/core/Grid';
import sampleWineRegion from 'assets/images/sample-wineRegion.png';

const useStyles = makeStyles((theme) => ({
  mapContainer: { padding: theme.spacing(2) },
  mapInnerContainer: { position: 'relative', zIndex: 0 },
  svg: {
    transition: 'transform 0.3s ease-in-out',
    transformOrigin: '30% 40%',
  },
  grapeAvatar: { position: 'absolute', left: 0, top: '20%', maxWidth: 120, maxHeight: 120 },
  zoomButtonGroup: {
    position: 'absolute',
    right: 5,
    top: '7%',
    '& .ant-btn': {
      fontSize: '1rem',
    },
    '& .ant-btn:first-child': { borderTopLeftRadius: '12px', borderTopRightRadius: '12px' },
    '& .ant-btn:last-child': { borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px' },
  },
  wineRegionImage: {
    position: 'absolute',
    maxWidth: 200,
    maxHeight: 200,
    right: 0,
    bottom: 1,
  },
}));

const Map = () => {
  const classes = useStyles();
  const [zoomScale, setZoomScale] = useState(1);

  const onZoom = useCallback((zoomIn = false) => {
    setZoomScale((prevZoom) => {
      const calculatedZoom = prevZoom + 0.3 * (!!zoomIn ? 1 : -1);

      return calculatedZoom;
    });
  }, []);

  return (
    <Grid
      container
      className={classes.mapContainer}
      justifyContent='center'
    >
      <Grid item className={classes.mapInnerContainer}>
        <div>
          <svg
            width='500'
            height='700'
            // viewBox='0 0 400 400'
            fill='none'
            style={{ transform: `scale(${zoomScale})` }}
            className={classes.svg}
          >
            <polygon
              points='10,100 330,60 480,180 480,220 120,700'
              stroke='#bdbdbd'
              strokeWidth='2'
            />
            <polygon
              points='10,100 150,90 170,180 28,190'
              fill='#C3A240'
              fillOpacity='0.1'
              stroke='#C3A240'
              strokeWidth='5'
            ></polygon>
            <polygon
              points='155,90 277,77 300,195 250,189 174 181'
              fill='#333333'
              fillOpacity='0.1'
              stroke='#838383'
              strokeWidth='5'
            ></polygon>
            <polygon
              points='282,77 335,75 450 230 400 250 370 230 306 203'
              fill='#C3A240'
              fillOpacity='0.1'
              stroke='#C3A240'
              strokeWidth='5'
            ></polygon>
            <polygon
              points='31,195 170,185 300,200 320 270 300 290 250 330 120 300'
              fill='#333333'
              fillOpacity='0.1'
              stroke='#838383'
              strokeWidth='5'
            ></polygon>
          </svg>
        </div>

        <div className={classes.zoomButtonGroup}>
          <ButtonGroup color='primary' orientation='vertical'>
            <Button onClick={() => onZoom(true)}>+</Button>
            <Button onClick={() => onZoom()}>-</Button>
          </ButtonGroup>
        </div>
        <div>
          <img src={sampleWineRegion} className={classes.wineRegionImage} />
        </div>
      </Grid>
    </Grid>
  );
};

export default Map;
