import { useMemo, useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import { useAlert } from 'react-alert';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Input, Table, Tabs, Modal, DatePicker } from 'antd';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Highlighter from 'react-highlight-words';
import { BinIcon, SearchTorchIcon } from 'components/Icons';
import {
  queryWhitelistedAccountTypes,
  fetchWhitelistedAccounts,
  addAccountToWhitelist,
  deleteWhitelistedAccount,
  deleteWhitelistedAccounts,
} from 'store/admin/whitelistedAccounts';
import { commonBtnStyles, containedOrangeButtonStyles } from 'assets/styles/common';

const { RangePicker } = DatePicker;

const initialDeleteModalState = { open: false, ids: null };
const initialWalletFormState = {
  email: '',
  wallet: '',
};

const useStyles = makeStyles((theme) => ({
  dataManagementContainer: {
    height: 'inherit',
    padding: `${theme.spacing(10)}px ${theme.spacing(15)}px 0 ${theme.spacing(6)}px`,
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: theme.common.white },
  header: {
    fontSize: theme.spacing(3.2),
  },
  subHeader: {
    fontSize: theme.spacing(2.5),
  },
  featuresContainer: {
    backgroundColor: theme.common.white,
    padding: theme.spacing(2),
  },
  datePicker: {
    borderRadius: 4,
    '&.ant-picker:hover': {
      borderColor: theme.common.black,
    },
    '&.ant-picker-focused': {
      boxShadow: 'none',
      borderWidth: 1.5,
      borderColor: theme.palette.primary.main,
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  noValueContainer: { marginLeft: '40%' },
  form: { width: 'inherit' },
  defaultBtn: commonBtnStyles,
  actionBtn: containedOrangeButtonStyles(theme),
  searchContainer: {
    padding: theme.spacing(1),
  },
  searchInput: {
    marginBottom: 8,
    display: 'block',
  },
  highlightText: {
    backgroundColor: theme.orangeTextHighlight,
    padding: 0,
  },
  bin: { cursor: 'pointer' },
  deleteText: { padding: theme.spacing(2), fontSize: '1.1rem' },
  deleteBtn: {
    ...commonBtnStyles,
    backgroundColor: theme.error.dark,
    color: theme.common.white,
    '&:hover': {
      opacity: 0.8,
      backgroundColor: theme.error.dark,
    },
  },
  entriesInfo: {
    fontSize: theme.spacing(2.3),
    minHeight: theme.spacing(5),
    paddingBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(3),
  },
  table: { width: 'auto' },
  count: {
    backgroundColor: theme.lightOrange,
    padding: `0px ${theme.spacing(1.1)}px`,
    borderRadius: '40%',
  },
  tabsContainer: {
    '& .ant-tabs-tab-btn': {
      color: theme.vinesTabItem,
    },
    '& .ant-tabs-tab-btn:hover': {
      color: theme.orange,
    },
    '& .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn': {
      color: theme.orange,
    },
    '& .ant-tabs-ink-bar': {
      background: theme.orange,
    },
    '& .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav': {
      display: 'flex',
      justifyContent: 'space-between',
      width: 'inherit',
    },
  },
}));

const tabTypes = [
  {
    key: 'all',
    renderLabel: (dataSource, classes) => (
      <Grid container spacing={1}>
        <Grid item>
          <div>All</div>
        </Grid>
        <Grid item>
          <div className={classes.count}>{dataSource.length}</div>
        </Grid>
      </Grid>
    ),
    renderChildren: (columns, dataSource, rowSelection, classes) => (
      <Table
        className={classes.table}
        columns={columns.filter((column) => column.dataIndex !== 'minted_at')}
        dataSource={dataSource}
        rowSelection={rowSelection}
        scroll={{ x: '100px' }}
      />
    ),
  },
  {
    key: 'minted',
    renderLabel: (dataSource, classes) => (
      <Grid container spacing={1}>
        <Grid item>
          <div>Minted</div>
        </Grid>
        <Grid item>
          <div className={classes.count}>{dataSource.filter((item) => item.hasMinted).length}</div>
        </Grid>
      </Grid>
    ),
    renderChildren: (columns, dataSource, rowSelection, classes) => (
      <Table
        className={classes.table}
        columns={columns.filter(
          (column) => column.dataIndex !== 'hasMinted' && column.key !== 'delete',
        )}
        dataSource={dataSource
          .filter((item) => item.hasMinted)
          .map((item, index) => ({ ...item, index: index + 1 }))}
        rowSelection={rowSelection}
        scroll={{ x: '100px' }}
      />
    ),
  },
  {
    key: 'private',
    renderLabel: (dataSource, classes) => (
      <Grid container spacing={1}>
        <Grid item>
          <div>Private</div>
        </Grid>
        <Grid item>
          <div className={classes.count}>
            {
              dataSource.filter(
                (item) => item.isWhitelisted && item.hasMinted && !item.hasPublicMinted,
              ).length
            }
          </div>
        </Grid>
      </Grid>
    ),
    renderChildren: (columns, dataSource, rowSelection, classes) => (
      <Table
        className={classes.table}
        columns={columns.filter(
          (column) =>
            column.dataIndex !== 'hasMinted' &&
            column.dataIndex !== 'isWhitelisted' &&
            column.key !== 'delete',
        )}
        dataSource={dataSource
          .filter((item) => item.isWhitelisted && item.hasMinted && !item.hasPublicMinted)
          .map((item, index) => ({ ...item, index: index + 1 }))}
        rowSelection={rowSelection}
        scroll={{ x: '100px' }}
      />
    ),
  },
];

const DataManagement = () => {
  const classes = useStyles();
  const {
    primary: { main: primaryMain },
  } = useTheme();
  const alert = useAlert();
  const queryClient = useQueryClient();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [dateRange, setDateRange] = useState(null);
  const [applyRangeFilter, setApplyRangeFilter] = useState(false);
  const [deleteModal, setDeleteModal] = useState(initialDeleteModalState);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const [activeTab, setActiveTab] = useState(tabTypes[0].key);

  const handleDeleteSuccess = useCallback((singular = true) => {
    alert.success(
      <div>
        <strong>Record{singular ? '' : 's'} successfully deleted</strong>
      </div>,
    );
    setDeleteModal(initialDeleteModalState);
    setSelectedRowKeys([]);
    queryClient.invalidateQueries({
      queryKey: [queryWhitelistedAccountTypes.whitelistedAccounts],
    });
  }, []);

  const handleDeleteError = useCallback((err, singular = true) => {
    alert.error(
      <div>
        <strong>An error occured while deleting the record{singular ? '' : 's'}</strong>
        <div>{err.message}</div>
      </div>,
    );
    setDeleteModal(initialDeleteModalState);
  }, []);

  const { mutate: onDeleteWhitelistedAccount, isLoading: accountDeleteIsLoading } = useMutation({
    mutationFn: deleteWhitelistedAccount,
    onSuccess: () => handleDeleteSuccess(),
    onError: (err) => handleDeleteError(err),
  });

  const { mutate: onDeleteWhitelistedAccounts, isLoading: accountsDeleteIsLoading } = useMutation({
    mutationFn: deleteWhitelistedAccounts,
    onSuccess: () => handleDeleteSuccess(false),
    onError: (err) => handleDeleteError(err, false),
  });

  const { mutate: onAddAccountToWhitelist, isLoading: accountAddisLoading } = useMutation({
    mutationFn: addAccountToWhitelist,
    onSuccess: () => {
      alert.success(
        <div>
          <strong>Account was successfully added to whitelist</strong>
        </div>,
      );
      queryClient.invalidateQueries({
        queryKey: [queryWhitelistedAccountTypes.whitelistedAccounts],
      });
    },
    onError: (err) => {
      alert.error(
        <div>
          <strong>An error occured while adding the account</strong>
          <div>{err.message}</div>
        </div>,
      );
    },
  });

  const { data, isFetching: dataLoading } = useQuery({
    queryKey: [queryWhitelistedAccountTypes.whitelistedAccounts],
    queryFn: fetchWhitelistedAccounts,
    onError: (err) => {
      alert.error(
        <div>
          <strong>An error occured while retrieving accounts</strong>
          <div>{err.message}</div>
        </div>,
      );
    },
  });

  useEffect(
    () => () => {
      queryClient.invalidateQueries({
        queryKey: [queryWhitelistedAccountTypes.whitelistedAccounts],
      });
    },
    [],
  );

  const { handleSubmit, getFieldProps, resetForm, errors, touched } = useFormik({
    initialValues: initialWalletFormState,
    validationSchema: Yup.object({
      email: Yup.string().email('Please enter a valid email address'),
      wallet: Yup.string().required('Wallet is required'),
    }),
    onSubmit: (data, { resetForm }) => {
      onAddAccountToWhitelist(data, {
        onSuccess: () => {
          resetForm();
        },
      });
    },
  });

  const clearRangeFilter = useCallback(() => {
    setDateRange(null);
    setApplyRangeFilter(false);
  }, []);

  const {
    dataSource,
    adminUsernameFilters,
    originFilters,
    hasMintedFilters,
    isWhitelistedFilters,
  } = useMemo(() => {
    if (!data)
      return {
        dataSource: [],
        adminUsernameFilters: [],
        originFilters: [],
        hasMintedFilters: [],
        isWhitelistedFilters: [],
      };

    const {
      dataSource,
      adminUsernameFilters,
      originFilters,
      hasMintedFilters,
      isWhitelistedFilters,
    } = data.reduce(
      (obj, item, index) => {
        if (applyRangeFilter) {
          const [start, end] = dateRange;

          const startDate = DateTime.fromISO(start.format());
          const endDate = DateTime.fromISO(end.format());
          const itemDate = DateTime.fromISO(item.created_at);

          if (startDate > itemDate || endDate < itemDate) return obj;
        }

        const email = item.email ?? '';

        obj.dataSource.push({ ...item, email, key: index, index: obj.dataSource.length + 1 });
        obj.adminUsernameFilters.add(item.username);
        obj.originFilters.add(item.origin);
        obj.hasMintedFilters.add(item.hasMinted);
        obj.isWhitelistedFilters.add(item.isWhitelisted);
        return obj;
      },
      {
        dataSource: [],
        adminUsernameFilters: new Set(),
        originFilters: new Set(),
        hasMintedFilters: new Set(),
        isWhitelistedFilters: new Set(),
      },
    );

    return {
      dataSource,
      adminUsernameFilters: Array.from(adminUsernameFilters),
      originFilters: Array.from(originFilters),
      hasMintedFilters: Array.from(hasMintedFilters),
      isWhitelistedFilters: Array.from(isWhitelistedFilters),
    };
  }, [data, dateRange, applyRangeFilter]);

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  });

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
    setSearchText('');
  });

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div className={classes.searchContainer} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          className={classes.searchInput}
        />
        <Grid container spacing={1}>
          <Grid item>
            <Button
              className={classes.defaultBtn}
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              variant='contained'
              color='primary'
              size='small'
            >
              Search
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.defaultBtn}
              onClick={() => clearFilters && handleReset(clearFilters)}
              variant='outlined'
              size='small'
            >
              Reset
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.defaultBtn}
              color='primary'
              size='small'
              onClick={() => {
                confirm();
              }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </div>
    ),
    filterIcon: (filtered) => <SearchTorchIcon fillColor={filtered ? primaryMain : undefined} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightClassName={classes.highlightText}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text || <div className={classes.noValueContainer}>-</div>
      ),
  });

  const onSelectChange = useCallback((selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  }, []);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.hasMinted,
    }),
  };

  const columns = [
    {
      title: '#',
      key: 'index',
      dataIndex: 'index',
      sorter: (a, b) => a.index - b.index,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email', 'Email'),
    },
    {
      title: 'Wallet',
      dataIndex: 'wallet',
      key: 'wallet',
      ...getColumnSearchProps('wallet', 'Wallet'),
    },
    {
      title: 'Origin',
      dataIndex: 'origin',
      key: 'origin',
      filters: originFilters.map((item) => ({ text: item || '-', value: item })),
      onFilter: (value, record) => record.origin === value,
      render: (value) => value || <div className={classes.noValueContainer}>-</div>,
    },
    {
      title: 'Whitelisted',
      dataIndex: 'isWhitelisted',
      key: 'isWhitelisted',
      filters: isWhitelistedFilters.map((item) => ({ text: String(item), value: item })),
      onFilter: (value, record) => record.isWhitelisted === value,
      render: (value) => String(value),
    },
    {
      title: 'Has minted',
      dataIndex: 'hasMinted',
      key: 'hasMinted',
      filters: hasMintedFilters.map((item) => ({ text: String(item), value: item })),
      onFilter: (value, record) => record.hasMinted === value,
      render: (value) => String(value),
    },
    {
      title: 'Minted date',
      dataIndex: 'minted_at',
      key: 'minted_at',
      render: (value) =>
        value ? (
          DateTime.fromISO(value).toLocaleString()
        ) : (
          <div className={classes.noValueContainer}>-</div>
        ),
      sorter: (a, b) => DateTime.fromISO(a.minted_at) - DateTime.fromISO(b.minted_at),
    },
    {
      title: 'Minted time',
      dataIndex: 'minted_at',
      key: 'minted_at_time',
      render: (value) =>
        value ? (
          DateTime.fromISO(value).toLocaleString(DateTime.TIME_24_WITH_SECONDS)
        ) : (
          <div className={classes.noValueContainer}>-</div>
        ),
    },
    {
      title: 'Admin account',
      dataIndex: 'username',
      key: 'username',
      filters: adminUsernameFilters.map((item) => ({ text: item || '-', value: item })),
      onFilter: (value, record) => record.username === value,
      render: (value) => value || <div className={classes.noValueContainer}>-</div>,
    },
    {
      title: 'Created date',
      key: 'created_at',
      dataIndex: 'created_at',
      render: (value) => DateTime.fromISO(value).toLocaleString(),
      sorter: (a, b) => DateTime.fromISO(a.created_at) - DateTime.fromISO(b.created_at),
    },
    {
      key: 'delete',
      render: (_, row) =>
        !row.hasMinted ? (
          <div
            className={classes.bin}
            onClick={() => {
              setDeleteModal({ open: true, ids: [row.id] });
            }}
          >
            <BinIcon />
          </div>
        ) : null,
    },
  ];

  const loading =
    dataLoading || accountAddisLoading || accountDeleteIsLoading || accountsDeleteIsLoading;

  return (
    <>
      <Grid container className={classes.dataManagementContainer}>
        {loading && (
          <Backdrop open className={classes.backdrop}>
            <CircularProgress color='inherit' />
          </Backdrop>
        )}
        <Grid item container direction='column' spacing={5}>
          <Grid item>
            <div className={classes.header}>Data management</div>
          </Grid>

          <Grid item>
            <div className={classes.subHeader}>Include into Whitelist</div>
            <Grid item container className={classes.featuresContainer}>
              <form className={classes.form} onSubmit={handleSubmit}>
                <Grid container justifyContent='space-between' alignItems='center'>
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item>
                        <TextField
                          variant='outlined'
                          size='small'
                          type='email'
                          label='Email'
                          {...getFieldProps('email')}
                          error={!!touched.email && !!errors.email}
                          helperText={!!touched.email && !!errors.email && errors.email}
                        />
                      </Grid>
                      <Grid item>
                        <TextField
                          variant='outlined'
                          size='small'
                          label='Wallet'
                          {...getFieldProps('wallet')}
                          error={!!touched.wallet && !!errors.wallet}
                          helperText={!!touched.wallet && !!errors.wallet && errors.wallet}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Button
                          onClick={resetForm}
                          variant='outlined'
                          className={classes.defaultBtn}
                        >
                          Clear
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button type='submit' variant='contained' className={classes.actionBtn}>
                          Quick add
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>

          <Grid item>
            <div className={classes.subHeader}>Data range</div>
            <Grid item container className={classes.featuresContainer}>
              <Grid container justifyContent='space-between' alignItems='center'>
                <Grid item>
                  <RangePicker
                    format='DD/MM/YYYY'
                    className={classes.datePicker}
                    size='large'
                    value={dateRange}
                    onChange={(dates) => {
                      if (dates) {
                        setApplyRangeFilter(false);
                        setDateRange(dates);
                      } else clearRangeFilter();
                    }}
                  />
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        onClick={clearRangeFilter}
                        variant='outlined'
                        className={classes.defaultBtn}
                      >
                        Clear
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        disabled={!dateRange}
                        onClick={() => setApplyRangeFilter(true)}
                        variant='contained'
                        className={classes.actionBtn}
                      >
                        Search
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container>
            <Grid item xs className={classes.tabsContainer}>
              <Tabs
                tabBarExtraContent={
                  !!selectedRowKeys.length && (
                    <div className={classes.entriesInfo}>
                      Selected entries: {selectedRowKeys.length}&nbsp;&nbsp;
                      <Button
                        onClick={() => {
                          setDeleteModal({
                            open: true,
                            ids: selectedRowKeys.map(
                              (key) => dataSource.find((item) => item.key === key).id,
                            ),
                          });
                        }}
                        variant='contained'
                        className={classes.deleteBtn}
                      >
                        Delete
                      </Button>
                    </div>
                  )
                }
                activeKey={activeTab}
                onChange={(value) => {
                  setSelectedRowKeys([]);
                  setActiveTab(value);
                }}
                items={tabTypes.map(({ key, renderLabel, renderChildren }) => ({
                  key,
                  label: renderLabel(dataSource, classes),
                  children: renderChildren(columns, dataSource, rowSelection, classes),
                }))}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Modal
        open={deleteModal.open}
        maskClosable={false}
        onCancel={() => setDeleteModal(initialDeleteModalState)}
        footer={
          <Grid container justifyContent='flex-end' alignItems='center' spacing={1}>
            <Grid item>
              <Button
                variant='outlined'
                className={classes.defaultBtn}
                onClick={() => setDeleteModal(initialDeleteModalState)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                className={classes.deleteBtn}
                onClick={() => {
                  if (deleteModal.ids.length === 1) onDeleteWhitelistedAccount(deleteModal.ids[0]);
                  else onDeleteWhitelistedAccounts(deleteModal.ids);
                }}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        }
      >
        <div className={classes.deleteText}>
          Are you sure that you want to delete the selected record
          {Array.isArray(deleteModal.ids) && deleteModal.ids.length > 1 ? 's' : ''}?
        </div>
      </Modal>
    </>
  );
};

export default DataManagement;
