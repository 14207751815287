import { createTheme } from '@material-ui/core/styles';

const theme = {
  //custom colors
  jet: '#333333',
  isabelline: '#F1F0EB',
  cafeAuLait: '#AF846B',
  grullo: '#B4A08D',
  satinSheetGold: '#C3A240',
  satinSheetGoldLight: '#ffd352',
  rawUmber: '#6D614A',
  copperRed: '#BB6748',

  orange: '#B96A4D',
  lightOrange: '#e3d4ca',
  fadedOrange: '#f1f0eb40',
  orangeTextHighlight: '#ffc069',

  brown: '#4B3F29',
  lightBrown: '#6d5c3c',

  vinesTabItem: '#42526edb',
  vinesTabItemSelected: '#253858',

  checkCircleSuccess: '#0e8074',
  checkCircleError: '#D14343',

  muiError: '#f44336',

  greyHeader: '#999896',

  warningMintYellow: '#FFB020',

  boxBackgroundColor: '#f1f0eb',
  boxShadow:
    '0px 4px 6px -2px rgba(100, 116, 139, 0.05), 0px 10px 15px -3px rgba(100, 116, 139, 0.12)',

  //common
  common: {
    black: '#000',
    white: '#fff',
    fadedBlack: '#00000080',
    fadedWhite: '#FFFFFFB2',
    lightBlack: '#000000b3',
  },
  background: {
    paper: '#fff',
    default: '#fafafa',
  },
  primary: {
    light: '#7986cb',
    main: '#00B0CA',
    dark: '#005577',
    contrastText: '#fff',
  },
  secondary: {
    light: '#ff4081',
    main: '#E60000',
    dark: '#990000',
    contrastText: '#fff',
  },
  error: {
    light: '#e57373',
    main: '#e60000',
    dark: '#d32f2f',
    contrastText: '#fff',
  },
  success: {
    light: '#81c784',
    main: '#009900',
    dark: '#388e3c',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  info: {
    light: '#64b5f6',
    main: '#007C92',
    dark: '#1976d2',
    contrastText: '#fff',
  },
  warning: {
    light: '#ffb74d',
    main: '#eb9700',
    dark: '#f57c00',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  grey: {
    50: '#fafafa',
    100: '#f4f4f4',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    750: '#4A4A4A',
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#333333',
    A700: '#666666',
  },
  text: {
    primary: '#333333',
    secondary: '#666666',
    disabled: 'rgba(51, 51, 51, 0.38)',
    hint: 'rgba(51, 51, 51, 0.38)',
  },
};

export default createTheme(theme);
