import { number, string, object } from 'prop-types';
import common from '@material-ui/core/colors/common';

const BinIcon = ({
  className = '',
  fillColor = common.black,
  height = 20,
  style = {},
  width = 20,
}) => (
  <svg style={style} className={className} height={height} width={width} viewBox='0 0 20 20'>
    <g clipPath='url(#clip0_27_91)'>
      <path
        d='M17.5 3.33333H14.9166C14.7232 2.39284 14.2115 1.54779 13.4677 0.940598C12.7239 0.333408 11.7935 0.0012121 10.8333 0L9.16663 0C8.20646 0.0012121 7.27606 0.333408 6.53225 0.940598C5.78844 1.54779 5.27671 2.39284 5.08329 3.33333H2.49996C2.27895 3.33333 2.06698 3.42113 1.9107 3.57741C1.75442 3.73369 1.66663 3.94565 1.66663 4.16667C1.66663 4.38768 1.75442 4.59964 1.9107 4.75592C2.06698 4.9122 2.27895 5 2.49996 5H3.33329V15.8333C3.33462 16.938 3.77403 17.997 4.55514 18.7782C5.33626 19.5593 6.3953 19.9987 7.49996 20H12.5C13.6046 19.9987 14.6637 19.5593 15.4448 18.7782C16.2259 17.997 16.6653 16.938 16.6666 15.8333V5H17.5C17.721 5 17.9329 4.9122 18.0892 4.75592C18.2455 4.59964 18.3333 4.38768 18.3333 4.16667C18.3333 3.94565 18.2455 3.73369 18.0892 3.57741C17.9329 3.42113 17.721 3.33333 17.5 3.33333ZM9.16663 1.66667H10.8333C11.3502 1.6673 11.8542 1.82781 12.2763 2.1262C12.6984 2.42459 13.0178 2.84624 13.1908 3.33333H6.80913C6.98211 2.84624 7.30154 2.42459 7.72361 2.1262C8.14569 1.82781 8.64973 1.6673 9.16663 1.66667ZM15 15.8333C15 16.4964 14.7366 17.1323 14.2677 17.6011C13.7989 18.0699 13.163 18.3333 12.5 18.3333H7.49996C6.83692 18.3333 6.20103 18.0699 5.73219 17.6011C5.26335 17.1323 4.99996 16.4964 4.99996 15.8333V5H15V15.8333Z'
        fill={fillColor}
      />
      <path
        d='M8.33333 14.9995C8.55434 14.9995 8.76631 14.9117 8.92259 14.7554C9.07887 14.5991 9.16666 14.3872 9.16666 14.1661V9.16616C9.16666 8.94514 9.07887 8.73318 8.92259 8.5769C8.76631 8.42062 8.55434 8.33282 8.33333 8.33282C8.11232 8.33282 7.90036 8.42062 7.74408 8.5769C7.5878 8.73318 7.5 8.94514 7.5 9.16616V14.1661C7.5 14.3872 7.5878 14.5991 7.74408 14.7554C7.90036 14.9117 8.11232 14.9995 8.33333 14.9995Z'
        fill={fillColor}
      />
      <path
        d='M11.6666 14.9995C11.8876 14.9995 12.0996 14.9117 12.2558 14.7554C12.4121 14.5991 12.4999 14.3872 12.4999 14.1661V9.16616C12.4999 8.94514 12.4121 8.73318 12.2558 8.5769C12.0996 8.42062 11.8876 8.33282 11.6666 8.33282C11.4456 8.33282 11.2336 8.42062 11.0773 8.5769C10.921 8.73318 10.8333 8.94514 10.8333 9.16616V14.1661C10.8333 14.3872 10.921 14.5991 11.0773 14.7554C11.2336 14.9117 11.4456 14.9995 11.6666 14.9995Z'
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id='clip0_27_91'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

BinIcon.propTypes = {
  className: string,
  fillColor: string,
  height: number,
  style: object,
  width: number,
};

export default BinIcon;
