import { useState, useCallback } from 'react';
import { useMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from 'antd';
import Configuration from './Configuration';
import { SettingsIcon } from 'components/Icons';

const useStyles = makeStyles((theme) => ({
  toggler: {
    cursor: 'pointer',
    lineHeight: 0,
    position: 'fixed',
    bottom: 55,
    right: 0,
    zIndex: 1301,
    background: theme.grey[300],
    padding: theme.spacing(1),
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    borderRight: 'none',
    transition: 'all .3s ease-out',
    '&.open': { boxShadow: 'none', right: 420 },
  },
}));

const Settings = () => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = useCallback(() => {
    setDrawerOpen((prev) => !prev);
  }, []);

  // Should be visible only on home route
  const toHomeRoute = useMatch('/home');

  return toHomeRoute ? (
    <>
      <div className={`${classes.toggler} ${drawerOpen ? 'open' : ''}`} onClick={toggleDrawer}>
        <SettingsIcon />
      </div>
      <Drawer
        title='Experiments'
        placement='right'
        onClose={toggleDrawer}
        open={drawerOpen}
        width={420}
      >
        <Configuration />
      </Drawer>
    </>
  ) : null;
};

export default Settings;
