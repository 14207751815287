import { methods, fetchApi } from 'utils/apiConfig';
const { POST } = methods;

const login = (creds) =>
  fetchApi('/auth/login', POST, {
    data: creds,
    headers: {
      [process.env.REACT_APP_LOGIN_HEADER_NAME]: process.env.REACT_APP_LOGIN_HEADER_VALUE,
    },
  });

export { login };
