import axios from 'axios';
import CustomError from './customError';

const methods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};

const defaultResponseMapper = (promise) => promise.then((res) => res.data);

//fetch function to be used in every api call
const fetchApi = (url = '/', method = methods.GET, requestProps = {}, useDefaultMapper = true) => {
  const request = axios({ method, url, ...requestProps }).catch((er) =>
    Promise.reject(new CustomError(er)),
  );

  return useDefaultMapper ? defaultResponseMapper(request) : request;
};

// axios configuration on initialization
const initApiConfiguration = () => {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  axios.defaults.timeout = 10000;
};

// axios helper functions
const setApiHeaders = (headers = {}) => {
  Object.keys(headers).forEach((key) => (axios.defaults.headers.common[key] = headers[key]));
};

const setTokenOnApiHeaders = (token) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const removeTokenFromApiHeaders = () => {
  delete axios.defaults.headers.common['Authorization'];
};

// localStorage helper functions
const getTokenFromStorage = () => localStorage.getItem('token');

const setTokenOnStorage = (token) => {
  localStorage.setItem('token', token);
};

const removeTokenFromStorage = () => {
  localStorage.removeItem('token');
};

export {
  initApiConfiguration,
  methods,
  fetchApi,
  getTokenFromStorage,
  setApiHeaders,
  setTokenOnApiHeaders,
  setTokenOnStorage,
  removeTokenFromApiHeaders,
  removeTokenFromStorage,
};
