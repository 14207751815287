import { number, string, object } from 'prop-types';
import common from '@material-ui/core/colors/common';

const StatusBulletIcon = ({
  className = '',
  fillColor = common.black,
  height = 25,
  strokeColor = common.white,
  style = {},
  width = 25,
}) => (
  <svg
    style={style}
    className={className}
    height={height}
    viewBox='0 0 20 18'
    width={width}
    fill={fillColor}
  >
    <g filter='url(#filter0_d_1716_46621)'>
      <rect x='5.5' y='4' width='10' height='10' rx='5' />
      <rect x='5.5' y='4' width='10' height='10' rx='5' stroke={strokeColor} />
    </g>
    <defs>
      <filter
        id='filter0_d_1716_46621'
        x='0'
        y='0.5'
        width='21'
        height='21'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='2' />
        <feGaussianBlur stdDeviation='2.5' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0 0.301961 0 0 0 0.03 0'
        />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1716_46621' />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_1716_46621'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
);

StatusBulletIcon.propTypes = {
  className: string,
  fillColor: string,
  height: number,
  strokeColor: string,
  style: object,
  width: number,
};

export default StatusBulletIcon;
