import { number, string, object } from 'prop-types';
import common from '@material-ui/core/colors/common';

const UserProfileIcon = ({
  className = '',
  fillColor = 'none',
  strokeColor = common.black,
  height = 25,
  style = {},
  width = 25,
  ...restProps
}) => (
  <svg
    style={style}
    className={className}
    height={height}
    viewBox='0 0 24 24'
    width={width}
    fill={fillColor}
    {...restProps}
  >
    <path
      d='M22.5 12C22.5 13.3789 22.2284 14.7443 21.7007 16.0182C21.2865 17.0182 20.7208 17.9465 20.0251 18.7711C19.8345 18.9969 19.6342 19.215 19.4246 19.4246C18.4496 20.3996 17.2921 21.1731 16.0182 21.7007C14.7443 22.2284 13.3789 22.5 12 22.5C10.6211 22.5 9.25574 22.2284 7.98182 21.7007C6.70791 21.1731 5.55039 20.3996 4.57538 19.4246C4.36576 19.215 4.16545 18.9969 3.97491 18.7711C3.27916 17.9465 2.71349 17.0182 2.29926 16.0182C1.77159 14.7443 1.5 13.3789 1.5 12C1.5 9.21523 2.60625 6.54451 4.57538 4.57538C6.54451 2.60625 9.21523 1.5 12 1.5C14.7848 1.5 17.4555 2.60625 19.4246 4.57538C21.3938 6.54451 22.5 9.21523 22.5 12Z'
      fill='#F1F0EB'
    />
    <path
      d='M3.9745 18.7713C6.42265 17.3876 9.18784 16.6624 12 16.6667C14.9167 16.6667 17.6548 17.4308 20.0255 18.7713M15.5 9.66667C15.5 10.5949 15.1313 11.4852 14.4749 12.1415C13.8185 12.7979 12.9283 13.1667 12 13.1667C11.0717 13.1667 10.1815 12.7979 9.52513 12.1415C8.86875 11.4852 8.5 10.5949 8.5 9.66667C8.5 8.73841 8.86875 7.84817 9.52513 7.19179C10.1815 6.53541 11.0717 6.16667 12 6.16667C12.9283 6.16667 13.8185 6.53541 14.4749 7.19179C15.1313 7.84817 15.5 8.73841 15.5 9.66667ZM22.5 12C22.5 13.3789 22.2284 14.7443 21.7007 16.0182C21.1731 17.2921 20.3996 18.4496 19.4246 19.4246C18.4496 20.3996 17.2921 21.1731 16.0182 21.7007C14.7443 22.2284 13.3789 22.5 12 22.5C10.6211 22.5 9.25574 22.2284 7.98182 21.7007C6.70791 21.1731 5.55039 20.3996 4.57538 19.4246C3.60036 18.4496 2.82694 17.2921 2.29926 16.0182C1.77159 14.7443 1.5 13.3789 1.5 12C1.5 9.21523 2.60625 6.54451 4.57538 4.57538C6.54451 2.60625 9.21523 1.5 12 1.5C14.7848 1.5 17.4555 2.60625 19.4246 4.57538C21.3938 6.54451 22.5 9.21523 22.5 12Z'
      stroke={strokeColor}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

UserProfileIcon.propTypes = {
  className: string,
  fillColor: string,
  strokeColor: string,
  height: number,
  style: object,
  width: number,
};

export default UserProfileIcon;
