import { useEffect, useState, useCallback, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { ethers } from 'ethers';
import { DateTime } from 'luxon';
import { useAlert } from 'react-alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { AppDataContext } from 'Context';
import {
  queryWeb3Types,
  generateSignature,
  fetchWhitelistOptions,
  fetchIsWhiteListed,
  saveTransactionDetails,
} from 'store/web3';
import { walletProviderTypes } from 'config/wallets';
import { CloseIcon, ExclamationCircleIcon } from 'components/Icons';
import RemainingTimeGenerator from './RemainingTimeGenerator';
import { generateMinimizedAddress, hexToDecimal, useWeb3Data } from './utils';
import useMediaQueries from 'hooks/mediaQueries';
import styles from './styles';
import monkImg from 'assets/images/question-monk.png';

const mintingDisabled = process.env.REACT_APP_MINT_DISABLED === 'true';

const initialStateData = {
  address: null,
  contractData: null,
  error: null,
  providersPrompt: false,
  transactionData: null,
  disableMint: false,
  whitelistEnabled: false,
};

const useStyles = makeStyles(styles);

const Wallet = () => {
  const { onSetAppData, onResetAppData } = useContext(AppDataContext);
  const classes = useStyles();
  const {
    error: { dark: errorDark },
    grey: { [900]: darkGrey },
    warningMintYellow,
  } = useTheme();
  const { smMatch } = useMediaQueries();
  const alert = useAlert();

  const queryClient = useQueryClient();

  const [walletStatus, setWalletStatus] = useState(initialStateData);

  const {
    connect,
    connectors,
    walletConnectionData,
    walletConnectionIsLoading,
    walletConnectionError,
    pendingConnector,
    disconnect,
    balance,
    signer,
    contract,
  } = useWeb3Data(walletStatus);

  const onWalletReset = useCallback(() => {
    disconnect();
    setWalletStatus({ ...initialStateData });
    queryClient.invalidateQueries({ queryKey: [queryWeb3Types.isWhitelisted] });
  }, []);

  // check if given wallet address has already minted - consume contract info
  const onCheckForMintedAddress = useCallback(async () => {
    try {
      const mintedByAddress = await contract.mintedByAddress(walletStatus.address);
      const symbol = await contract.symbol();

      if (hexToDecimal(mintedByAddress._hex) === 0) {
        const maxSupply = await contract.maxSupply();
        const totalSupply = await contract.totalSupply();

        setWalletStatus((prev) => ({
          ...prev,
          providersPrompt: false,
          contractData: {
            balance: parseFloat(ethers.utils.formatEther(balance.value._hex)).toFixed(4),
            maxSupply: hexToDecimal(maxSupply._hex),
            totalSupply: hexToDecimal(totalSupply._hex),
            symbol,
          },
        }));
      } else
        setWalletStatus((prev) => ({
          ...prev,
          error: {
            header: 'You have already minted',
            message: `This wallet ${generateMinimizedAddress(
              walletStatus.address,
            )} has already minted. You cannot mint anymore ${symbol} NFTs with this wallet.`,
            danger: false,
          },
        }));
    } catch (err) {
      setWalletStatus((prev) => ({
        ...prev,
        error: {
          header: 'Error on wallet connection',
          message:
            'We were unable to connect your wallet to network. Please check your wallet extension settings (wrong network selected, invalid address etc.) and try again.',
          danger: true,
        },
      }));
    }
  }, [signer, balance, walletStatus.address]);

  // step 5 (send transaction hash to backend)
  const { mutate: onSaveTransactionDetails } = useMutation({
    mutationFn: saveTransactionDetails,
  });

  // step 4 (mint)
  const mint = useCallback(
    async (signatureData) => {
      const { metadataUri, signature } = signatureData;
      try {
        const response = await contract.mint(metadataUri, signature);
        setWalletStatus((prev) => ({
          ...prev,
          transactionData: response,
        }));
        onSaveTransactionDetails(response.hash);
      } catch (err) {
        const errorMessage = (err?.message || '').match(/reason="([\w:.\s]+)"/)
          ? err.message.match(/reason="([\w:.\s]+)"/)[1]
          : (err?.message || '').match(/.*(user)?.*rejected.*transaction/i)
          ? 'User rejected transaction'
          : 'Generic error';

        setWalletStatus((prev) => ({
          ...prev,
          error: {
            header: 'Error on minting process',
            message: errorMessage,
            danger: true,
          },
        }));
      }
    },
    [signer],
  );

  // step 3 (get signature data from backend)
  const { mutate: onGenerateSignature, isLoading: generateSignatureIsLoading } = useMutation({
    mutationFn: generateSignature,
    retry: false,
    onSuccess: (data) => {
      mint(data);
    },
    onError: (err) => {
      setWalletStatus((prev) => ({
        ...prev,
        error: {
          header: 'Error on generating signature',
          message: err?.message || 'Generic error',
          danger: true,
        },
      }));
    },
  });

  // step 1a  (initial fetch of whitelist options)
  const {
    data: whitelistOptions,
    isFetching: whitelistOptionsPending,
    whitelistOptionsError,
  } = useQuery({
    queryKey: [queryWeb3Types.whitelistOptions],
    queryFn: fetchWhitelistOptions,
    onError: (err) => {
      alert.error(
        <div>
          <strong>An error occured while retrieving whitelist options</strong>
          <div>{err.message}</div>
        </div>,
      );
    },
  });

  // step 1b (display wallet providers list)
  //* Check for disabled minting process
  const onWalletConnect = useCallback(() => {
    setWalletStatus((prev) => {
      const data = mintingDisabled ? { disableMint: true } : { providersPrompt: true };
      return { ...prev, ...data };
    });
  }, []);

  // step 1c (listener for wallet connection procedure)
  // * Check if whitelist procedure is enabled (globally or with end time)
  useEffect(() => {
    if (walletConnectionData) {
      const { mintingWithWhitelistEnabled, mintingWithWhitelistAndTimeEnabled, mintingEndTime } =
        whitelistOptions;

      const whitelistEnabled =
        mintingWithWhitelistEnabled ||
        (mintingWithWhitelistAndTimeEnabled && DateTime.now() < DateTime.fromISO(mintingEndTime));

      setWalletStatus((prev) => ({
        ...prev,
        address: walletConnectionData.account,
        whitelistEnabled,
      }));
    } else if (walletConnectionError) {
      const errorMessage = walletConnectionError?.message || 'Generic error';
      setWalletStatus((prev) => ({
        ...prev,
        error: {
          header: 'Cound Not Connect Wallet',
          message: errorMessage,
          danger: true,
          returnBtnText: 'Got it',
        },
      }));
    }
  }, [walletConnectionData, walletConnectionError]);

  // step 2 (whitelist disabled) skip whitelist check and proceed to check if given address has already minted
  useEffect(() => {
    if (walletStatus.address && !walletStatus.whitelistEnabled && signer && balance)
      onCheckForMintedAddress();
  }, [walletStatus.address, walletStatus.whitelistEnabled, signer, balance]);

  // step 2 (whitelist enabled) check if given wallet address is whitelisted and hasn't minted already
  useQuery({
    queryKey: [queryWeb3Types.isWhitelisted, walletStatus.address],
    queryFn: fetchIsWhiteListed,
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!walletStatus.address && !!walletStatus.whitelistEnabled && !!signer && !!balance,
    onSuccess: onCheckForMintedAddress,
    onError: (err) => {
      const errorMessage = err?.message || 'Generic error';
      if (errorMessage.match(/not.*whitelisted/i))
        setWalletStatus((prev) => ({
          ...prev,
          error: {
            header: 'Not on whitelist',
            message: `This wallet ${generateMinimizedAddress(
              walletStatus.address,
            )} is not on the whitelist. Please try again during public minting (subject to availability).`,
            danger: false,
          },
        }));
      else
        setWalletStatus((prev) => ({
          ...prev,
          error: {
            header: 'An error occured',
            message: errorMessage,
            danger: true,
          },
        }));
    },
  });

  // wallet connection & data on context reset - query cache clearance on unmount
  useEffect(
    () => () => {
      disconnect();
      onResetAppData('walletData');
      queryClient.invalidateQueries({ queryKey: [queryWeb3Types.isWhitelisted] });
      queryClient.invalidateQueries({ queryKey: [queryWeb3Types.whitelistOptions] });
    },
    [],
  );

  // propagate data to context
  useEffect(() => {
    const hideHeaderOnMint =
      !!walletStatus.error || !!walletStatus.providersPrompt || !!walletStatus.disableMint;
    onSetAppData(
      { ...walletStatus, hideHeaderOnMint, onWalletReset, onWalletConnect },
      'walletData',
      true,
    );
  }, [walletStatus]);

  const {
    address,
    disableMint,
    providersPrompt,
    whitelistEnabled,
    error,
    contractData,
    transactionData,
  } = walletStatus;

  return (
    <>
      {whitelistOptionsPending && (
        <Backdrop open className={classes.backdrop}>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
      <Grid
        container
        alignItems='center'
        justifyContent='center'
        className={`${classes.walletContainer} ${
          error || providersPrompt || disableMint ? 'black' : ''
        }`}
      >
        <Grid
          item
          container
          direction='column'
          alignItems='center'
          className={`${classes.boxContainer} ${
            error || providersPrompt || disableMint ? 'black' : ''
          }`}
          spacing={smMatch ? 4 : 6}
        >
          {error ? (
            <Grid item container direction='column' alignItems='center' spacing={6}>
              <Grid item container justifyContent='space-between' alignItems='center'>
                <Grid item>
                  <div className={classes.mediumHeader}>{error.header}</div>
                </Grid>
                {!smMatch && (
                  <Grid item className={classes.marginRight} onClick={onWalletReset}>
                    <CloseIcon fillColor={darkGrey} className={classes.pointer} />
                  </Grid>
                )}
              </Grid>
              <Grid item container>
                <Grid item xs={1} classes={{ item: classes.customXsGrid }}>
                  <ExclamationCircleIcon
                    className={classes.exclamationIcon}
                    fillColor={error.danger ? errorDark : warningMintYellow}
                  />
                </Grid>
                <Grid item xs>
                  <div className={`${classes.mediumText} spaceLeft`}>{error.message}</div>
                </Grid>
              </Grid>
              <Grid item container justifyContent='flex-end'>
                <Grid item className={classes.marginRight}>
                  <div
                    className={`${classes.mediumText} ${classes.pointer}`}
                    onClick={onWalletReset}
                  >
                    {error?.returnBtnText || 'Disconnect Wallet'}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          ) : disableMint ? (
            <Grid item container direction='column' alignItems='center' spacing={6}>
              <Grid item container justifyContent='space-between' alignItems='center'>
                <Grid item>
                  <div className={classes.mediumHeader}>Can't proceed</div>
                </Grid>
                {!smMatch && (
                  <Grid item className={classes.marginRight} onClick={onWalletReset}>
                    <CloseIcon fillColor={darkGrey} className={classes.pointer} />
                  </Grid>
                )}
              </Grid>
              <Grid item container>
                <Grid item xs={1} classes={{ item: classes.customXsGrid }}>
                  <ExclamationCircleIcon
                    className={classes.exclamationIcon}
                    fillColor={warningMintYellow}
                  />
                </Grid>
                <Grid item xs>
                  <div className={`${classes.mediumText} spaceLeft`}>
                    Minting is no longer enabled.
                  </div>
                </Grid>
              </Grid>
              <Grid item container justifyContent='flex-end'>
                <Grid item className={classes.marginRight}>
                  <div
                    className={`${classes.mediumText} ${classes.pointer}`}
                    onClick={onWalletReset}
                  >
                    Got it
                  </div>
                </Grid>
              </Grid>
            </Grid>
          ) : providersPrompt ? (
            <Grid item container direction='column' alignItems='center' spacing={smMatch ? 3 : 5}>
              <Grid item container justifyContent='space-between' alignItems='center'>
                <Grid item>
                  <div className={classes.mediumHeader}>Connect Wallet</div>
                </Grid>
                <Grid item className={classes.marginRight} onClick={onWalletReset}>
                  <CloseIcon fillColor={darkGrey} className={classes.pointer} />
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item>
                  <div className={classes.mediumText}>Choose your preferred wallet.</div>
                </Grid>
                <Grid item container direction='column' spacing={2}>
                  {connectors.map((connector) => {
                    const { label, imgSrc } = walletProviderTypes[connector.id];
                    const connectorSelected =
                      walletConnectionIsLoading && pendingConnector?.id === connector.id;
                    return (
                      <Grid item key={label}>
                        <Grid
                          container
                          justifyContent='space-between'
                          alignItems='center'
                          classes={{
                            root: `${classes.providerContainer} ${
                              connectorSelected ? 'selected' : ''
                            }`,
                          }}
                          onClick={walletConnectionIsLoading ? null : () => connect({ connector })}
                        >
                          <Grid item className={classes.providerName}>
                            {label}
                          </Grid>
                          <Grid item>
                            <img className={classes.walletImg} src={imgSrc} />
                          </Grid>
                          {connectorSelected && (
                            <div className={classes.circularProgressContainer}>
                              <CircularProgress size={30} classes={{ root: classes.circular }} />
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          ) : !address ? (
            <Grid item container direction='column' alignItems='center' spacing={5}>
              <Grid item>
                <div className={classes.mediumHeader}>Connect Wallet</div>
              </Grid>
              <Grid item container>
                <Button
                  disabled={!!whitelistOptionsError}
                  fullWidth
                  size='large'
                  className={classes.button}
                  onClick={onWalletConnect}
                >
                  Connect
                </Button>
              </Grid>
            </Grid>
          ) : !!transactionData ? (
            <Grid item container direction='column' alignItems='center' spacing={6}>
              <Grid item>
                <div className={classes.bigHeader}>Congratulations!</div>
              </Grid>
              <Grid item container>
                <Grid item xs>
                  <div className={`${classes.mediumText} center padding`}>
                    You successfully minted one {contractData.symbol} NFT. Please check your wallet
                    to see your artwork.
                  </div>
                </Grid>
              </Grid>
              <Grid item>
                <img className={classes.monkImg} src={monkImg} alt='' />
              </Grid>
              <Grid item container justifyContent='flex-end'>
                <Grid item className={classes.marginRight}>
                  <div
                    className={`${classes.mediumText} ${classes.pointer}`}
                    onClick={onWalletReset}
                  >
                    Disconnect Wallet
                  </div>
                </Grid>
              </Grid>
            </Grid>
          ) : !!contractData ? (
            <>
              <Grid item container direction='column' alignItems='center' spacing={2}>
                {whitelistEnabled ? (
                  <>
                    <Grid item>
                      <div className={classes.bigHeader}>You are whitelisted!</div>
                    </Grid>
                    {whitelistOptions.mintingWithWhitelistAndTimeEnabled && (
                      <Grid item>
                        <RemainingTimeGenerator
                          prependedTextBeforeEndTime={<div>Private mint ends in: </div>}
                          textAfterEndTime={<div>Private mint period ended</div>}
                          numberClassName={classes.remainingTimeDigit}
                          charClassName={classes.remainingTimeChar}
                          time={whitelistOptions.mintingEndTime}
                        />
                      </Grid>
                    )}
                  </>
                ) : (
                  <Grid item>
                    <div className={classes.bigHeader}>Public mint is live</div>
                  </Grid>
                )}
              </Grid>
              <Grid item container spacing={smMatch ? 2 : 3}>
                <Grid item container justifyContent='center' alignItems='center' spacing={1}>
                  <Grid item>
                    <div className={classes.countInfo}>Collection:</div>
                  </Grid>
                  <Grid item>
                    <div className={classes.countValue}>{contractData.symbol}</div>
                  </Grid>
                </Grid>

                <Grid item container justifyContent='center' spacing={3}>
                  <Grid item>
                    <Grid item container alignItems='center' spacing={1}>
                      <Grid item>
                        <div className={classes.countInfo}>Price:</div>
                      </Grid>
                      <Grid item>
                        <div className={classes.countValue}>Free</div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid item container alignItems='center' spacing={1}>
                      <Grid item>
                        <div className={classes.countInfo}>Remaining:</div>
                      </Grid>
                      <Grid item>
                        <div className={classes.countValue}>
                          {contractData.maxSupply - contractData.totalSupply}/
                          {contractData.maxSupply}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item container justifyContent='center' spacing={3}>
                  <Grid item>
                    <Grid item container alignItems='center' spacing={1}>
                      <Grid item>
                        <div className={classes.countInfo}>Wallet:</div>
                      </Grid>
                      <Grid item>
                        <div className={classes.countValue}>
                          {generateMinimizedAddress(address)}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid item container alignItems='center' spacing={1}>
                      <Grid item>
                        <div className={classes.countInfo}>Balance:</div>
                      </Grid>
                      <Grid item>
                        <div className={classes.countValue}>{contractData.balance} Eth</div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <img className={classes.monkImg} src={monkImg} alt='' />
              </Grid>

              <Grid item container>
                <Button
                  disabled={
                    generateSignatureIsLoading ||
                    contractData.totalSupply === contractData.maxSupply
                  }
                  fullWidth
                  size={smMatch ? 'medium' : 'large'}
                  className={classes.button}
                  onClick={() => onGenerateSignature(address)}
                >
                  {generateSignatureIsLoading ? (
                    <CircularProgress size={30} classes={{ root: classes.circular }} />
                  ) : (
                    'Mint'
                  )}
                </Button>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};

export default Wallet;
