import { DateTime } from 'luxon';

const defaultOpts = {
  toArray: false,
  oneChar: true,
  displayTheBiggestOnly: false,
  splitDateTo: ['months', 'days', 'hours', 'years', 'minutes', 'seconds'],
};

/**
 * @typedef {Object} Options
 * @param {Boolean} toArray - Indicator for string or Array<string> result
 * @param {Boolean} oneChar - Indicator for cutting the trailing chars (e.g. days or d)
 * @param {Boolean} displayTheBiggestOnly - Indicator for display only the biggest from results and ignoring the rest
 * @param {Array} splitDateTo - Indicator for splitting date into given argmunents
 */
/**
 * Helper function that takes an ISO date (as string)
 * and returns a deconstructed time expression,
 * calculating the remaining time, starting from today, until given time.
 * e.g. "2 months 1 day 3 hours"
 * If current date is bigger that given date, it returns null
 * @param {String} endDate - The given date
 * @param {Options} opts - Options for transforming
 * @returns {String|Array<String>}
 */
const generateRemainingTime = (endDate, opts = defaultOpts) => {
  const { toArray, oneChar, splitDateTo } = opts;

  const selectedDate = DateTime.fromISO(endDate);
  const currentDate = DateTime.now();

  if (currentDate > selectedDate) return null;

  const calcTime = selectedDate
    .diff(currentDate, splitDateTo || defaultOpts.splitDateTo)
    .toObject();

  return toArray
    ? Object.keys(calcTime).reduce((arr, key) => {
        const value = calcTime[key];
        if (value) arr.push(Math.round(value), oneChar ? key[0] : key);
        return arr;
      }, [])
    : Object.keys(calcTime).reduce((str, key) => {
        const value = calcTime[key];
        if (value) str += `${Math.round(value)}${oneChar ? key[0] : key} `;
        return str;
      }, '');
};

/**
 * Helper function that takes an ISO date (as string)
 * and returns a deconstructed time expression,
 * calculating the time that passed since today.
 * e.g. "2 months 1 day 3 hours"
 * If given date is bigger that current date, it returns null
 * @param {String} prevDate - The given date
 * @param {Options} opts - Options for transforming
 * @returns {String|Array<String>}
 */
const generatePastTime = (prevDate, opts = defaultOpts) => {
  const { toArray, oneChar, splitDateTo, displayTheBiggestOnly } = opts;

  const previousDate = DateTime.fromISO(prevDate);
  const currentDate = DateTime.now();

  if (currentDate < previousDate) return null;

  const calcTime = currentDate
    .diff(previousDate, splitDateTo || defaultOpts.splitDateTo)
    .toObject();

  return toArray
    ? Object.keys(calcTime).reduce((arr, key) => {
        if (displayTheBiggestOnly && arr.length) return arr;

        const value = calcTime[key];
        if (value) arr.push(Math.round(value), oneChar ? key[0] : key);
        return arr;
      }, [])
    : Object.keys(calcTime).reduce((str, key) => {
        if (displayTheBiggestOnly && str) return str;

        const value = calcTime[key];
        if (value) str += `${Math.round(value)} ${oneChar ? key[0] : key} `;
        return str;
      }, '');
};

export { generateRemainingTime, generatePastTime };
