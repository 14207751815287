import { methods, fetchApi } from 'utils/apiConfig';
const { GET, PATCH } = methods;

const querySettingsTypes = {
  settings: 'settings',
};

const fetchSettings = ({ signal }) =>
  fetchApi('/userNotifications/getUserNotificationSettings', GET, { signal });

const updateSetting = (data) =>
  fetchApi('/userNotifications/updateUserNotificationSettings', PATCH, { data });

export { querySettingsTypes, fetchSettings, updateSetting };
