import { number, string, object } from 'prop-types';
import common from '@material-ui/core/colors/common';

const BottleIcon = ({
  className = '',
  fillColor = common.black,
  height = 25,
  style = {},
  width = 25,
}) => (
  <svg style={style} className={className} height={height} viewBox='0 0 10 30' width={width}>
    <path
      d='M8.30031 9.25697L7.40818 8.36483C7.04783 8.00442 6.84934 7.5253 6.84934 7.01566V2.41532C7.13695 2.16229 7.319 1.7919 7.319 1.37965C7.319 0.618904 6.7001 0 5.93936 0H4.06069C3.29994 0 2.68104 0.618904 2.68104 1.37965C2.68104 1.79196 2.86309 2.16235 3.15071 2.41532V7.01566C3.15071 7.5253 2.95221 8.00442 2.59186 8.36483L1.69973 9.25697C0.818221 10.1385 0.332703 11.3105 0.332703 12.5573V28.6204C0.332703 29.3811 0.951607 30 1.71235 30H8.28769C9.04844 30 9.66734 29.3811 9.66734 28.6204V12.5573C9.66734 11.3105 9.18182 10.1385 8.30031 9.25697ZM4.06069 0.880626H5.93936C6.21452 0.880626 6.43838 1.10448 6.43838 1.37965C6.43838 1.65481 6.21452 1.87867 5.93936 1.87867H4.06069C3.78552 1.87867 3.56167 1.65481 3.56167 1.37965C3.56167 1.10448 3.78552 0.880626 4.06069 0.880626ZM1.21333 19.1977H2.18202C2.42519 19.1977 2.62233 19.0005 2.62233 18.7573C2.62233 18.5142 2.42519 18.317 2.18202 18.317H1.21333V17.319H3.12135C3.36452 17.319 3.56167 17.1218 3.56167 16.8787C3.56167 16.6355 3.36452 16.4384 3.12135 16.4384H1.21333V14.9706H4.06069C4.33585 14.9706 4.55971 15.1945 4.55971 15.4697V22.9843C4.55971 23.2595 4.33585 23.4834 4.06069 23.4834H1.21333V19.1977ZM8.78671 28.6204C8.78671 28.8955 8.56286 29.1194 8.28769 29.1194H1.71235C1.43718 29.1194 1.21333 28.8955 1.21333 28.6204V24.364H4.06069C4.82143 24.364 5.44033 23.7451 5.44033 22.9843V15.4697C5.44033 14.7089 4.82143 14.09 4.06069 14.09H1.21333V12.5573C1.21333 11.5458 1.6072 10.5949 2.32245 9.87969L3.21458 8.98755C3.74131 8.46082 4.03139 7.76055 4.03139 7.01571V2.75853C4.0412 2.75877 4.05088 2.7593 4.06075 2.7593H5.93942C5.94928 2.7593 5.95897 2.75877 5.96877 2.75853V7.01566C5.96877 7.76055 6.25885 8.46082 6.78558 8.9875L7.67771 9.87963C8.39284 10.5949 8.78671 11.5458 8.78671 12.5573V28.6204Z'
      fill={fillColor}
    />
  </svg>
);

BottleIcon.propTypes = {
  className: string,
  fillColor: string,
  height: number,
  style: object,
  width: number,
};

export default BottleIcon;
