import { useMemo, useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import { useAlert } from 'react-alert';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Grid from '@material-ui/core/Grid';
import { Collapse } from '@material-ui/core';
import { Input, Table, Tabs, Modal } from 'antd';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Highlighter from 'react-highlight-words';
import { BinIcon, SearchTorchIcon, UploadIcon } from 'components/Icons';
import {
  queryUploadTypes,
  fetchUploadedFiles,
  deleteUploadedFile,
  deleteUploadedFiles,
  uploadFile,
} from 'store/admin/uploads';
import { commonBtnStyles, containedOrangeButtonStyles } from 'assets/styles/common';

const initialDeleteModalState = { open: false, ids: null };

const useStyles = makeStyles((theme) => ({
  uploadContainer: {
    height: 'inherit',
    padding: `${theme.spacing(10)}px ${theme.spacing(15)}px 0 ${theme.spacing(6)}px`,
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: theme.common.white },
  header: {
    fontSize: theme.spacing(3.2),
  },
  commonBtn: commonBtnStyles,
  uploadBtn: containedOrangeButtonStyles(theme),
  searchContainer: {
    padding: theme.spacing(1),
  },
  searchInput: {
    marginBottom: 8,
    display: 'block',
  },
  input: {
    display: 'none',
  },
  uploadIcon: {
    margin: `0 ${theme.spacing(1.5)}px ${theme.spacing(0.5)}px 0`,
    '&.disabled': {
      opacity: 0.3,
    },
  },
  uploadContent: {
    backgroundColor: theme.common.white,
    padding: `${theme.spacing(4)}px ${theme.spacing(3)}px`,
  },
  uploadContentChooseBtn: {
    ...commonBtnStyles,
    backgroundColor: theme.grey[100],
  },
  uploadContentSubmitBtn: {
    ...commonBtnStyles,
    border: `1px solid ${theme.orange}`,
  },
  highlightText: {
    backgroundColor: theme.orangeTextHighlight,
    padding: 0,
  },
  bin: { cursor: 'pointer' },
  deleteText: { padding: theme.spacing(2), fontSize: '1.1rem' },
  deleteBtn: {
    ...commonBtnStyles,
    backgroundColor: theme.error.dark,
    color: theme.common.white,
    '&:hover': {
      opacity: 0.8,
      backgroundColor: theme.error.dark,
    },
  },
  entriesInfo: {
    fontSize: theme.spacing(2.3),
    minHeight: theme.spacing(5),
    paddingBottom: theme.spacing(0.5),
    marginLeft: theme.spacing(3),
  },
  table: { width: 'auto' },
  count: {
    backgroundColor: theme.lightOrange,
    padding: `0px ${theme.spacing(1.1)}px`,
    borderRadius: '40%',
  },
  tabsContainer: {
    '& .ant-tabs-tab-btn': {
      color: theme.vinesTabItem,
    },
    '& .ant-tabs-tab-btn:hover': {
      color: theme.orange,
    },
    '& .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn': {
      color: theme.orange,
    },
    '& .ant-tabs-ink-bar': {
      background: theme.orange,
    },
    '& .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav': {
      display: 'flex',
      justifyContent: 'space-between',
      width: 'inherit',
    },
  },
}));

const tabTypes = [
  {
    key: 'all',
    renderLabel: (dataSource, classes) => (
      <Grid container spacing={1}>
        <Grid item>
          <div>All</div>
        </Grid>
        <Grid item>
          <div className={classes.count}>{dataSource.length}</div>
        </Grid>
      </Grid>
    ),
    renderChildren: (columns, dataSource, rowSelection, classes) => (
      <Table
        className={classes.table}
        columns={columns}
        dataSource={dataSource}
        rowSelection={rowSelection}
        scroll={{ x: '100px' }}
      />
    ),
  },
];

const Uploads = () => {
  const classes = useStyles();
  const {
    common: { white },
    primary: { main: primaryMain },
  } = useTheme();
  const alert = useAlert();
  const queryClient = useQueryClient();

  const [showUploadContent, setShowUploadContent] = useState(false);
  const [file, setFile] = useState(null);
  const fileRef = useRef(null);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [deleteModal, setDeleteModal] = useState(initialDeleteModalState);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const clearFileInput = useCallback(() => {
    fileRef.current.value = '';
  }, []);

  const handleDeleteSuccess = useCallback((singular = true) => {
    alert.success(
      <div>
        <strong>Record{singular ? '' : 's'} successfully deleted</strong>
      </div>,
    );
    setDeleteModal(initialDeleteModalState);
    setSelectedRowKeys([]);
    queryClient.invalidateQueries({ queryKey: [queryUploadTypes.uploadedFiles] });
  }, []);

  const handleDeleteError = useCallback((err, singular = true) => {
    alert.error(
      <div>
        <strong>An error occured while deleting the record{singular ? '' : 's'}</strong>
        <div>{err.message}</div>
      </div>,
    );
    setDeleteModal(initialDeleteModalState);
  }, []);

  const { mutate: onDeleteUploadedFile, isLoading: fileDeleteIsLoading } = useMutation({
    mutationFn: deleteUploadedFile,
    onSuccess: () => handleDeleteSuccess(),
    onError: (err) => handleDeleteError(err),
  });

  const { mutate: onDeleteUploadedFiles, isLoading: filesDeleteIsLoading } = useMutation({
    mutationFn: deleteUploadedFiles,
    onSuccess: () => handleDeleteSuccess(false),
    onError: (err) => handleDeleteError(err, false),
  });

  const { mutate: onUploadFile, isLoading: fileUploadIsLoading } = useMutation({
    mutationFn: uploadFile,
    onSuccess: () => {
      alert.success(
        <div>
          <strong>Record was created successfully</strong>
        </div>,
      );
      clearFileInput();
      setShowUploadContent(false);
      setFile(null);
      queryClient.invalidateQueries({ queryKey: [queryUploadTypes.uploadedFiles] });
    },
    onError: (err) => {
      alert.error(
        <div>
          <strong>An error occured while uploading the record</strong>
          <div>{err.message}</div>
        </div>,
      );
    },
  });

  const { data, isFetching: dataLoading } = useQuery({
    queryKey: [queryUploadTypes.uploadedFiles],
    queryFn: fetchUploadedFiles,
    onError: (err) => {
      alert.error(
        <div>
          <strong>An error occured while retrieving records</strong>
          <div>{err.message}</div>
        </div>,
      );
    },
  });

  useEffect(
    () => () => {
      queryClient.invalidateQueries({ queryKey: [queryUploadTypes.uploadedFiles] });
    },
    [],
  );

  const { dataSource, adminUsernameFilters } = useMemo(() => {
    if (!data) return { dataSource: [], adminUsernameFilters: [] };

    const { dataSource, adminUsernameFilters } = data.reduce(
      (obj, item, index) => {
        obj.dataSource.push({ ...item, key: index, index: index + 1 });
        obj.adminUsernameFilters.add(item.username);
        return obj;
      },
      {
        dataSource: [],
        adminUsernameFilters: new Set(),
      },
    );

    return { dataSource, adminUsernameFilters: Array.from(adminUsernameFilters) };
  }, [data]);

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  });

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
    setSearchText('');
  });

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div className={classes.searchContainer} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          className={classes.searchInput}
        />
        <Grid container spacing={1}>
          <Grid item>
            <Button
              className={classes.commonBtn}
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              variant='contained'
              color='primary'
              size='small'
            >
              Search
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.commonBtn}
              onClick={() => clearFilters && handleReset(clearFilters)}
              variant='outlined'
              size='small'
            >
              Reset
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.commonBtn}
              color='primary'
              size='small'
              onClick={() => {
                confirm();
              }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </div>
    ),
    filterIcon: (filtered) => <SearchTorchIcon fillColor={filtered ? primaryMain : undefined} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightClassName={classes.highlightText}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const onSelectChange = useCallback((selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  }, []);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: '#',
      key: 'index',
      dataIndex: 'index',
      sorter: (a, b) => a.index - b.index,
    },
    {
      title: 'File name',
      dataIndex: 'originalname',
      key: 'originalname',
      width: '30%',
      ...getColumnSearchProps('originalname', 'File name'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '40%',
      ...getColumnSearchProps('status', 'Status'),
    },
    {
      title: 'Admin account',
      dataIndex: 'username',
      key: 'username',
      filters: adminUsernameFilters.map((item) => ({ text: item, value: item })),
      onFilter: (value, record) => record.username === value,
    },
    {
      title: 'Created date',
      key: 'created_at',
      dataIndex: 'created_at',
      render: (value) => DateTime.fromISO(value).toLocaleString(),
      sorter: (a, b) => DateTime.fromISO(a.created_at) - DateTime.fromISO(b.created_at),
    },
    {
      key: 'delete',
      render: (_, row) => (
        <div
          className={classes.bin}
          onClick={() => {
            setDeleteModal({ open: true, ids: [row.id] });
          }}
        >
          <BinIcon />
        </div>
      ),
    },
  ];

  const handleFileChange = useCallback((e) => {
    if (e.target.files) setFile(e.target.files[0]);
  }, []);

  const handleuploadFile = useCallback(() => {
    let formData = new FormData();
    formData.append('CSVs', file);
    onUploadFile(formData);
  }, [file]);

  const loading = dataLoading || fileDeleteIsLoading || filesDeleteIsLoading || fileUploadIsLoading;

  return (
    <>
      <Grid container className={classes.uploadContainer}>
        {loading && (
          <Backdrop open className={classes.backdrop}>
            <CircularProgress color='inherit' />
          </Backdrop>
        )}
        <Grid item container direction='column' spacing={4}>
          <Grid item container justifyContent='space-between'>
            <Grid item>
              <div className={classes.header}>Uploads</div>
            </Grid>
            <Grid item>
              <Button
                onClick={() => setShowUploadContent((prev) => !prev)}
                className={classes.uploadBtn}
                variant='contained'
                size='large'
              >
                <UploadIcon className={classes.uploadIcon} fillColor={white} />
                <div>Upload new file</div>
              </Button>
            </Grid>
          </Grid>

          <Grid item>
            <Collapse in={showUploadContent}>
              <Grid container direction='column' className={classes.uploadContent}>
                <Grid item container justifyContent='space-between'>
                  <Grid item>
                    <Grid container spacing={4} alignItems='center'>
                      <Grid item>
                        <input
                          ref={fileRef}
                          accept='.csv'
                          className={classes.input}
                          id='contained-button-file'
                          multiple
                          type='file'
                          onChange={handleFileChange}
                        />
                        <label htmlFor='contained-button-file'>
                          <Button
                            variant='outlined'
                            component='span'
                            className={classes.uploadContentChooseBtn}
                          >
                            Choose file
                          </Button>
                        </label>
                      </Grid>
                      <Grid item>{file ? file.name : null}</Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Button
                          onClick={() => {
                            clearFileInput();
                            setFile(null);
                          }}
                          variant='outlined'
                          className={classes.commonBtn}
                        >
                          Clear
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          disabled={!file}
                          onClick={handleuploadFile}
                          variant='outlined'
                          className={classes.uploadContentSubmitBtn}
                        >
                          <UploadIcon
                            className={`${classes.uploadIcon} ${!file ? 'disabled' : ''}`}
                          />
                          <div>Upload</div>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>

          <Grid item container>
            <Grid item xs className={classes.tabsContainer}>
              <Tabs
                tabBarExtraContent={
                  !!selectedRowKeys.length && (
                    <div className={classes.entriesInfo}>
                      Selected entries: {selectedRowKeys.length}&nbsp;&nbsp;
                      <Button
                        onClick={() => {
                          setDeleteModal({
                            open: true,
                            ids: selectedRowKeys.map(
                              (key) => dataSource.find((_, index) => key === index).id,
                            ),
                          });
                        }}
                        variant='contained'
                        className={classes.deleteBtn}
                      >
                        Delete
                      </Button>
                    </div>
                  )
                }
                activeKey={tabTypes[0].key}
                items={tabTypes.map(({ key, renderLabel, renderChildren }) => ({
                  key,
                  label: renderLabel(dataSource, classes),
                  children: renderChildren(columns, dataSource, rowSelection, classes),
                }))}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={deleteModal.open}
        maskClosable={false}
        onCancel={() => setDeleteModal(initialDeleteModalState)}
        footer={
          <Grid container justifyContent='flex-end' alignItems='center' spacing={1}>
            <Grid item>
              <Button
                variant='outlined'
                className={classes.commonBtn}
                onClick={() => setDeleteModal(initialDeleteModalState)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                className={classes.deleteBtn}
                onClick={() => {
                  if (deleteModal.ids.length === 1) onDeleteUploadedFile(deleteModal.ids[0]);
                  else onDeleteUploadedFiles(deleteModal.ids);
                }}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        }
      >
        <div className={classes.deleteText}>
          Are you sure that you want to delete the selected record
          {Array.isArray(deleteModal.ids) && deleteModal.ids.length > 1 ? 's' : ''}?
        </div>
      </Modal>
    </>
  );
};

export default Uploads;
