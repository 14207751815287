import { useConnect, useDisconnect, useBalance, useSigner, useContract } from 'wagmi';
import abi from 'contracts/abi.json';

// helper hook that encapsulates and exposes all web3 related data
const useWeb3Data = (walletStatus) => {
  const {
    connect,
    connectors,
    error: walletConnectionError,
    isLoading: walletConnectionIsLoading,
    data: walletConnectionData,
    pendingConnector,
  } = useConnect();
  const { disconnect } = useDisconnect();
  const { data: balance } = useBalance({
    address: walletStatus.address,
  });
  const { data: signer } = useSigner();
  const contract = useContract({
    address: process.env.REACT_APP_CONTRACT_ADDRESS,
    abi: abi,
    signerOrProvider: signer,
  });

  return {
    connect,
    connectors,
    walletConnectionData,
    walletConnectionIsLoading,
    walletConnectionError,
    pendingConnector,
    disconnect,
    balance,
    signer,
    contract,
  };
};

const hexToDecimal = (hex) => parseInt(hex, 16);

const generateMinimizedAddress = (address) => `${address.slice(0, 4)}...${address.slice(-4)}`;

export { useWeb3Data, generateMinimizedAddress, hexToDecimal };
