import { methods, fetchApi } from 'utils/apiConfig';
const { GET, POST } = methods;

const queryPollTypes = {
  polls: 'polls',
  poll: 'poll',
  votes: 'votes',
  questionResultsPerPoll: 'questionResultsPerPoll',
};

const mutationPollTypes = {
  addVote: 'addVote',
};

const fetchPolls = ({ signal }) => fetchApi('/polls', GET, { params: { templateNo: 3 }, signal });

const fetchPoll = ({ signal, queryKey: [_, pollId] }) =>
  fetchApi(`/polls/${pollId}?templateNo=3`, GET, { signal });

const createPoll = (data) => fetchApi('/polls/createPollWithQuestions', POST, { data });

const fetchVotes = ({ signal }) =>
  fetchApi('/poll-votes', GET, { params: { templateNo: 2 }, signal });

const fetchQuestionsResultsPerPoll = ({ signal, queryKey: [_, pollId] }) =>
  fetchApi(`/poll-votes/GetQuestionsResults/${pollId}`, GET, { signal });

const addVote = (requestParams) => fetchApi('/poll-votes/addVote', POST, { data: requestParams });

export {
  queryPollTypes,
  mutationPollTypes,
  createPoll,
  fetchPolls,
  fetchPoll,
  fetchVotes,
  fetchQuestionsResultsPerPoll,
  addVote,
};
