import { makeStyles } from '@material-ui/core/styles';
import { Link, NavLink, Outlet } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import winegrowLogo from 'assets/images/winegrow-g-logo.png';

const useStyles = makeStyles((theme) => ({
  adminContainer: {
    height: 'inherit',
  },
  sideBar: {
    backgroundColor: theme.orange,
    paddingTop: theme.spacing(8),
  },
  link: {
    width: 'inherit',
    padding: `${theme.spacing(1.8)}px 0 ${theme.spacing(1.8)}px ${theme.spacing(4)}px`,
    fontSize: theme.spacing(2.2),
    color: theme.common.white,
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      color: theme.common.white,
      opacity: 0.7,
    },
    '&.active': {
      fontWeight: 'bold',
      backgroundColor: theme.fadedOrange,
    },
  },
  content: {
    backgroundColor: theme.isabelline,
    overflowY: 'hidden',
  },
}));

const Admin = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.adminContainer}>
      <Grid item md={2} sm={3} xs={4} className={classes.sideBar}>
        <Grid container direction='column' spacing={5} alignItems='center'>
          <Grid item>
            <Link to='/home'>
              <img src={winegrowLogo} alt='winegrow-logo' />
            </Link>
          </Grid>
          <Grid item container>
            <Grid item container>
              <NavLink to='/admin/uploads' className={classes.link}>
                Uploads
              </NavLink>
            </Grid>
            <Grid item container>
              <NavLink to='/admin/data-management' className={classes.link}>
                Data Management
              </NavLink>
            </Grid>
            <Grid item container>
              <NavLink to='/admin/polls' className={classes.link}>
                Polls
              </NavLink>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={10} sm={9} xs={8} className={classes.content}>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default Admin;
