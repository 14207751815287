import { useState, useCallback } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAlert } from 'react-alert';
import { useMutation } from '@tanstack/react-query';
import { Link, useNavigate } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Collapse } from '@material-ui/core';
import { Divider } from 'antd';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import PasswordMatcher from 'components/PasswordMatcher';
import { LogoIcon } from 'components/Icons';
import { register } from 'store/auth/register';

const passwordValidationProps = [
  {
    text: 'An English uppercase character (A-Z)',
    type: 'matches',
    rule: /^.*[A-Z].*$/,
    errorMessage: 'An uppercase character is required',
  },
  {
    text: 'An English lowercase character (a-z)',
    type: 'matches',
    rule: /^.*[a-z].*$/,
    errorMessage: 'A lowercase character is required',
  },
  {
    text: 'A number (0-9) and a symbol (such as !, #, or %)',
    type: 'matches',
    rule: /^(?=.*[0-9])(?=.*[!@#$%^&*()_+-])[0-9a-zA-Z!@#$%^&*()_+-]+$/,
    errorMessage: 'A number and a symbol are required',
  },
  {
    text: 'Ten or more characters total',
    type: 'matches',
    rule: /^.{10,}$/,
    errorMessage: 'Ten or more characters are required',
  },
];

const useStyles = makeStyles((theme) => ({
  registerContainer: {
    height: 'inherit',
  },
  boxContainer: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '60%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '70%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '85%',
    },
    padding: theme.spacing(4),
    backgroundColor: theme.boxBackgroundColor,
    boxShadow: theme.boxShadow,
    borderRadius: 8,
  },
  registerText: {
    fontSize: '1.8rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.7rem',
    },
  },
  formElement: { width: '100%' },
  textField: {
    '& label.Mui-focused': {
      color: theme.brown,
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: theme.grey[600],
      },
      '&.Mui-error:hover fieldset': {
        borderColor: theme.muiError,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.brown,
      },
      '&.Mui-focused.Mui-error fieldset': {
        borderColor: theme.muiError,
      },
    },
    '& legend span': {
      padding: 10,
    },
  },
  button: {
    textTransform: 'none',
    borderRadius: 25,
    height: theme.spacing(6.5),
    fontWeight: 'bold',
    fontSize: '1.3rem',
    backgroundColor: theme.brown,
    color: theme.common.white,
    '&:hover': {
      color: theme.common.white,
      backgroundColor: theme.lightBrown,
      borderColor: theme.lightBrown,
    },
  },
  circular: { color: theme.common.white },
  registerErrorMessage: {
    paddingTop: theme.spacing(1.5),
    fontSize: '1rem',
    color: theme.muiError,
  },
  divider: { margin: 0 },
  link: {
    color: theme.brown,
    borderBottom: `1px solid ${theme.brown}`,
    fontSize: '1.1rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '.9rem',
    },
    '&:hover': {
      color: theme.lightBrown,
    },
  },
}));

const Register = () => {
  const [showPasswordMatcher, setShowPasswordMatcher] = useState(false);
  const classes = useStyles();
  const {
    common: { black },
  } = useTheme();
  const alert = useAlert();
  const navigate = useNavigate();

  const { error, isLoading, mutate, reset } = useMutation({
    mutationFn: register,
    onSuccess: () => {
      alert.success(
        <div>
          <strong>
            User was created successfully.
            <br />A verification link has been sent to given email
          </strong>
        </div>,
      );
      navigate('/auth/login');
    },
  });

  const { handleSubmit, handleChange, handleBlur, getFieldProps, errors, values, touched } =
    useFormik({
      initialValues: {
        username: '',
        email: '',
        password: '',
        first_name: '',
        last_name: '',
      },
      validationSchema: Yup.object({
        username: Yup.string().required('Username is required'),
        email: Yup.string()
          .email('Please enter a valid email address')
          .required('Email address is required'),
        password: passwordValidationProps.reduce((obj, { type, rule, message }) => {
          obj = obj[type](rule, message);
          return obj;
        }, Yup.string().required('Password is required')),

        first_name: Yup.string().required('First name is required'),
        last_name: Yup.string().required('Last name is required'),
      }),
      onSubmit: (data) => {
        mutate(data);
      },
    });

  const handleFieldChange = useCallback(
    (e) => {
      if (error) reset();
      handleChange(e);
    },
    [error, handleChange],
  );

  const handleFieldBlur = useCallback(
    (e, cb) => {
      e.target.value = e.target.value.trim();
      handleFieldChange(e);
      if (cb) cb();
      handleBlur(e);
    },
    [handleFieldChange, handleBlur],
  );

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      className={classes.registerContainer}
    >
      <Grid item className={classes.boxContainer}>
        <Grid container direction='column' alignItems='center' spacing={3}>
          <Grid item>
            <LogoIcon width={160} height={40} fillColor={black} />
          </Grid>
          <Grid item container direction='column' alignItems='center' spacing={4}>
            <Grid item>
              <div className={classes.registerText}>Create Account</div>
            </Grid>
            <Grid item container>
              <form className={classes.formElement} onSubmit={handleSubmit}>
                <Grid container direction='column' spacing={3}>
                  <Grid item>
                    <TextField
                      label='Username'
                      variant='outlined'
                      fullWidth
                      classes={{ root: classes.textField }}
                      error={!!errors.username && !!touched.username}
                      helperText={errors.username && touched.username && errors.username}
                      {...getFieldProps('username')}
                      onChange={handleFieldChange}
                      onBlur={handleFieldBlur}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label='First name'
                      variant='outlined'
                      fullWidth
                      classes={{ root: classes.textField }}
                      error={!!errors.first_name && !!touched.first_name}
                      helperText={errors.first_name && touched.first_name && errors.first_name}
                      {...getFieldProps('first_name')}
                      onChange={handleFieldChange}
                      onBlur={handleFieldBlur}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label='Last name'
                      variant='outlined'
                      fullWidth
                      classes={{ root: classes.textField }}
                      error={!!errors.last_name && !!touched.last_name}
                      helperText={errors.last_name && touched.last_name && errors.last_name}
                      {...getFieldProps('last_name')}
                      onChange={handleFieldChange}
                      onBlur={handleFieldBlur}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label='Email address'
                      variant='outlined'
                      fullWidth
                      classes={{ root: classes.textField }}
                      error={!!errors.email && !!touched.email}
                      helperText={errors.email && touched.email && errors.email}
                      {...getFieldProps('email')}
                      onChange={handleFieldChange}
                      onBlur={handleFieldBlur}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label='Password'
                      variant='outlined'
                      type='password'
                      fullWidth
                      classes={{ root: classes.textField }}
                      error={!!errors.password && !!touched.password}
                      {...getFieldProps('password')}
                      onChange={(e) => {
                        e.target.value = e.target.value.trim();
                        handleFieldChange(e);
                      }}
                      onBlur={(e) => {
                        handleFieldBlur(e, () => setShowPasswordMatcher(false));
                      }}
                      onFocus={() => setShowPasswordMatcher(true)}
                    />
                  </Grid>
                  <Collapse in={showPasswordMatcher}>
                    <Grid item>
                      <PasswordMatcher
                        value={values.password}
                        validationProps={passwordValidationProps}
                      />
                    </Grid>
                  </Collapse>
                  <Grid item>
                    <Button
                      disabled={isLoading}
                      type='submit'
                      fullWidth
                      size='large'
                      classes={{ root: classes.button }}
                    >
                      {isLoading ? (
                        <CircularProgress size={30} classes={{ root: classes.circular }} />
                      ) : (
                        'Create'
                      )}
                    </Button>
                    {error && <div className={classes.registerErrorMessage}>{error.message}</div>}
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item container>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
          <Grid item container justifyContent='center' spacing={2}>
            <Grid item>
              <Link to='/auth/login' className={classes.link}>
                Log in
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Register;
