import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// hook that resolves screen breakpoints
const useMediaQueries = () => {
  const { breakpoints } = useTheme();
  const smMatch = useMediaQuery(breakpoints.down('sm'));
  const xsMatch = useMediaQuery(breakpoints.down('xs'));

  return { smMatch, xsMatch };
};

export default useMediaQueries;
