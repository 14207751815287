import { number, string, object } from 'prop-types';
import common from '@material-ui/core/colors/common';

const DiscordIcon = ({
  className = '',
  fillColor = common.black,
  height = 16,
  style = {},
  width = 22,
}) => (
  <svg
    style={style}
    className={className}
    height={height}
    width={width}
    viewBox='0 0 22 16'
    fill='none'
  >
    <path
      d='M18.2562 1.35569C20.6257 4.83962 21.796 8.76941 21.3584 13.2935C21.3566 13.3126 21.3467 13.3302 21.331 13.3417C19.5366 14.6594 17.798 15.459 16.0838 15.9893C16.0705 15.9934 16.0562 15.9931 16.043 15.9887C16.0298 15.9842 16.0183 15.9757 16.0101 15.9644C15.6141 15.4134 15.2543 14.8325 14.939 14.2224C14.9209 14.1865 14.9375 14.1432 14.9747 14.1291C15.5462 13.9137 16.0896 13.6557 16.6123 13.3501C16.6535 13.326 16.6561 13.267 16.6181 13.2387C16.5071 13.1563 16.3972 13.0698 16.2921 12.9832C16.2724 12.9672 16.2459 12.9641 16.2236 12.9748C12.8302 14.5419 9.11278 14.5419 5.6792 12.9748C5.6569 12.9649 5.63042 12.9683 5.61128 12.984C5.50638 13.0706 5.39622 13.1563 5.28632 13.2387C5.24831 13.267 5.25145 13.326 5.29288 13.3501C5.81559 13.6499 6.35899 13.9137 6.92968 14.1301C6.96667 14.1443 6.98425 14.1865 6.96589 14.2224C6.65746 14.8332 6.29762 15.4142 5.89426 15.9652C5.8767 15.9875 5.84784 15.9977 5.82055 15.9893C4.11452 15.459 2.37596 14.6594 0.581538 13.3417C0.566588 13.3302 0.555835 13.3118 0.554262 13.2927C0.188662 9.37944 0.933759 5.41714 3.65372 1.3549C3.66029 1.34415 3.67023 1.33576 3.68178 1.33078C5.02012 0.716551 6.45393 0.264668 7.95253 0.00659874C7.97979 0.0024025 8.00708 0.0149912 8.02123 0.0391195C8.2064 0.366951 8.41805 0.787363 8.56125 1.13093C10.1409 0.889646 11.7451 0.889646 13.3578 1.13093C13.501 0.794705 13.7053 0.366951 13.8897 0.0391195C13.8963 0.0271497 13.9064 0.0175602 13.9188 0.0117199C13.9311 0.00587966 13.945 0.00408732 13.9584 0.00659874C15.4578 0.265453 16.8916 0.717337 18.2289 1.33078C18.2407 1.33576 18.2504 1.34415 18.2562 1.35569ZM9.36431 8.79615C9.38082 7.63931 8.53737 6.68204 7.4786 6.68204C6.42849 6.68204 5.59317 7.6309 5.59317 8.79615C5.59317 9.96114 6.44503 10.91 7.4786 10.91C8.52898 10.91 9.36431 9.96114 9.36431 8.79615ZM16.3359 8.79615C16.3524 7.63931 15.5089 6.68204 14.4504 6.68204C13.4 6.68204 12.5647 7.6309 12.5647 8.79615C12.5647 9.96114 13.4166 10.91 14.4504 10.91C15.5089 10.91 16.3359 9.96114 16.3359 8.79615Z'
      fill={fillColor}
    />
  </svg>
);

DiscordIcon.propTypes = {
  className: string,
  fillColor: string,
  height: number,
  style: object,
  width: number,
};

export default DiscordIcon;
