import { useState, useEffect } from 'react';
import { string, element } from 'prop-types';
import { Grid } from '@material-ui/core';
import { generateRemainingTime } from 'utils/formaters';

const RemainingTimeGenerator = ({
  time,
  prependedTextBeforeEndTime,
  textAfterEndTime,
  numberClassName,
  charClassName,
}) => {
  const [remaining, setRemaining] = useState(
    generateRemainingTime(time, { toArray: true, oneChar: false }),
  );

  useEffect(() => {
    const generator = setInterval(() => {
      setRemaining((prev) =>
        !!prev ? generateRemainingTime(time, { toArray: true, oneChar: false }) : null,
      );
    }, [1000]);

    return () => clearInterval(generator);
  }, []);

  return (
    <Grid container justifyContent='center' alignItems='center' spacing={1}>
      {!!remaining ? (
        <>
          {!!prependedTextBeforeEndTime && <Grid item>{prependedTextBeforeEndTime}</Grid>}
          <Grid item>
            <Grid container alignItems='center'>
              {remaining.map((item, idx) => {
                const className = (idx + 2) % 2 === 0 ? numberClassName : charClassName;
                return (
                  <Grid item key={`${item} ${idx}`} className={className}>
                    {item}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </>
      ) : (
        <>{!!textAfterEndTime && <Grid item>{textAfterEndTime}</Grid>}</>
      )}
    </Grid>
  );
};

RemainingTimeGenerator.propTypes = {
  time: string,
  prependedTextBeforeEndTime: element,
  textAfterEndTime: element,
  numberClassName: string,
  charClassName: string,
};

export default RemainingTimeGenerator;
