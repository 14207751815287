import { Navigate, useLocation } from 'react-router-dom';
import useAuth from 'hooks/auth';
import useAppData from 'hooks/context';

// Wrapper for generating admin route
const AdminRoute = ({ children }) => {
  const user = useAuth();

  return user?.isAdmin ? children : <Navigate to='/home' replace />;
};

// Wrapper for generating authenticated route
const AuthRoute = ({ children }) => {
  const user = useAuth();
  const { redirectAfterAuth } = useAppData();
  const { pathname, search } = useLocation();

  const state = redirectAfterAuth ? { redirectUrl: `${pathname}${search}` } : undefined;

  return !!user ? children : <Navigate to='/auth/login' replace state={state} />;
};

// Wrapper for generating public route
const PublicRoute = ({ children }) => {
  const user = useAuth();
  const { state } = useLocation();

  return !!user ? <Navigate to={state?.redirectUrl || '/home'} replace /> : children;
};

export { AdminRoute, AuthRoute, PublicRoute };
