import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Divider } from 'antd';
import ChatRow from './ChatRow';
import {
  PhoneIcon,
  VideoIcon,
  ResizeIcon,
  VerticalDotsIcon,
  AttachmentIcon,
  AttachedImageIcon,
  SendMessageIcon,
  StatusBulletIcon,
} from 'components/Icons';

const useStyles = makeStyles((theme) => ({
  discussionContainer: {
    color: theme.common.white,
    padding: `${theme.spacing(2.5)}px ${theme.spacing(1)}px 0 ${theme.spacing(1)}px`,
  },
  actionButton: {
    cursor: 'pointer',
  },
  statusBulletIcon: { marginRight: theme.spacing(0.6) },
  divider: {
    '& .ant-divider-horizontal': {
      backgroundColor: theme.grey[600],
      margin: 0,
    },
  },
  typeText: {
    fontFamily: 'Roboto',
    fontSize: '0.8rem',
  },
}));

const Discussion = () => {
  const classes = useStyles();
  const {
    satinSheetGold,
    common: { white },
    success: { main },
  } = useTheme();

  return (
    <Grid container direction='column' className={classes.discussionContainer} spacing={2}>
      <Grid item container justifyContent='space-between' alignItems='center'>
        <Grid item>
          <PhoneIcon width={18} height={18} fillColor={white} className={classes.actionButton} />
        </Grid>
        <Grid item>
          <VideoIcon width={18} height={18} fillColor={white} className={classes.actionButton} />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item>
              <StatusBulletIcon
                width={20}
                height={20}
                strokeColor={white}
                fillColor={main}
                className={classes.statusBulletIcon}
              />
            </Grid>
            <Grid item>
              <div>Ina Roberson</div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <ResizeIcon width={18} height={18} fillColor={white} className={classes.actionButton} />
        </Grid>
        <Grid item>
          <VerticalDotsIcon
            width={18}
            height={18}
            fillColor={white}
            className={classes.actionButton}
          />
        </Grid>
      </Grid>

      <Grid item className={classes.divider}>
        <Divider />
      </Grid>

      <Grid item container direction='column' spacing={5}>
        <ChatRow left />
        <ChatRow right />
      </Grid>

      <Grid item className={classes.divider}>
        <Divider />
      </Grid>

      <Grid item container>
        <Grid item xs={2}>
          <AttachmentIcon
            width={20}
            height={20}
            fillColor={white}
            className={classes.actionButton}
          />
        </Grid>
        <Grid item xs={2}>
          <AttachedImageIcon
            width={18}
            height={18}
            fillColor={white}
            className={classes.actionButton}
          />
        </Grid>
        <Grid item xs={7}>
          <div className={classes.typeText}>Type Message</div>
        </Grid>
        <Grid item xs={1}>
          <SendMessageIcon
            width={18}
            height={18}
            fillColor={satinSheetGold}
            className={classes.actionButton}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Discussion;
