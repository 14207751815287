import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAlert } from 'react-alert';
import Grid from '@material-ui/core/Grid';
import Info from './Info';
import Map from './Map';
import Content from './Content';
import { queryWineTypes, fetchWines } from 'store/wines';
import whiteBackground from 'assets/images/background.jpg';

const tabs = [
  { label: 'Vines', key: 'vines' },
  { label: 'Block', key: 'block' },
  { label: 'Vineyard', key: 'vineyard' },
];

const useStyles = makeStyles((theme) => ({
  homeContainer: {
    height: 'inherit',
    background: `url(${whiteBackground})`,
  },
}));

const Home = () => {
  const classes = useStyles();
  const alert = useAlert();
  const queryClient = useQueryClient();

  const [activeTab, setActiveTab] = useState(tabs[0].key);
  const [selectedWine, setSelectedWine] = useState(null);

  const { data: wines } = useQuery({
    queryKey: [queryWineTypes.wines],
    queryFn: fetchWines,
    onError: () => {
      alert.error(
        <div>
          <strong>An error occured while retrieving vines</strong>
        </div>,
      );
    },
  });

  useEffect(
    () => () => {
      queryClient.invalidateQueries({ queryKey: [queryWineTypes.wines] });
    },
    [],
  );

  const xa = (e) => {
    console.log({ e });
  };

  return (
    <Grid
      container
      className={classes.homeContainer}
      // onScroll={(e) => {
      //   setSelectedWine(null);
      // }}
    >
      <Grid item sm={3}>
        <Info activeTab={activeTab} setActiveTab={setActiveTab} selectedWine={selectedWine} />
      </Grid>
      <Grid item sm={6}>
        <Map
          activeTab={activeTab}
          wines={wines}
          selectedWine={selectedWine}
          setSelectedWine={setSelectedWine}
        />
      </Grid>
      <Grid item sm={3}>
        <Content />
      </Grid>
    </Grid>
  );
};

export default Home;
