import { useMemo, useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Input, Table, Tabs } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchTorchIcon, PaperAddIcon } from 'components/Icons';
import { queryPollTypes, fetchPolls } from 'store/polls';
import { commonBtnStyles, containedOrangeButtonStyles } from 'assets/styles/common';

const useStyles = makeStyles((theme) => ({
  pollsContainer: {
    height: 'inherit',
    padding: `${theme.spacing(10)}px ${theme.spacing(15)}px 0 ${theme.spacing(6)}px`,
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: theme.common.white },
  header: {
    fontSize: theme.spacing(3.2),
  },
  commonBtn: commonBtnStyles,
  uploadBtn: containedOrangeButtonStyles(theme),
  searchContainer: {
    padding: theme.spacing(1),
  },
  searchInput: {
    marginBottom: 8,
    display: 'block',
  },
  input: {
    display: 'none',
  },
  addPollIcon: {
    margin: `0 ${theme.spacing(1.5)}px ${theme.spacing(0.5)}px 0`,
    '&.disabled': {
      opacity: 0.3,
    },
  },
  highlightText: {
    backgroundColor: theme.orangeTextHighlight,
    padding: 0,
  },
  table: { width: 'auto' },
  count: {
    backgroundColor: theme.lightOrange,
    padding: `0px ${theme.spacing(1.1)}px`,
    borderRadius: '40%',
  },
  tabsContainer: {
    '& .ant-tabs-tab-btn': {
      color: theme.vinesTabItem,
    },
    '& .ant-tabs-tab-btn:hover': {
      color: theme.orange,
    },
    '& .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn': {
      color: theme.orange,
    },
    '& .ant-tabs-ink-bar': {
      background: theme.orange,
    },
    '& .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav': {
      display: 'flex',
      justifyContent: 'space-between',
      width: 'inherit',
    },
  },
}));

const tabTypes = [
  {
    key: 'all',
    renderLabel: (dataSource, classes) => (
      <Grid container spacing={1}>
        <Grid item>
          <div>All</div>
        </Grid>
        <Grid item>
          <div className={classes.count}>{dataSource.length}</div>
        </Grid>
      </Grid>
    ),
    renderChildren: (columns, dataSource, classes) => (
      <Table
        className={classes.table}
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: '100px' }}
      />
    ),
  },
];

const Polls = () => {
  const classes = useStyles();
  const {
    common: { white },
    primary: { main: primaryMain },
  } = useTheme();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const { data: polls, isFetching: pollsLoading } = useQuery({
    queryKey: [queryPollTypes.polls],
    queryFn: fetchPolls,
    onError: (err) => {
      alert.error(
        <div>
          <strong>An error occured while retrieving polls</strong>
          <div>{err.message}</div>
        </div>,
      );
    },
  });

  const { dataSource, categoryFilters } = useMemo(() => {
    if (!polls) return { dataSource: [], categoryFilters: [] };

    const { dataSource, categoryFilters } = polls.reduce(
      (obj, item, index) => {
        obj.dataSource.push({ ...item, key: index, index: index + 1 });
        obj.categoryFilters.add(item.category);

        return obj;
      },
      {
        dataSource: [],
        categoryFilters: new Set(),
      },
    );

    return {
      dataSource,
      categoryFilters: Array.from(categoryFilters),
    };
  }, [polls]);

  const handleSearch = useCallback((selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  });

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
    setSearchText('');
  });

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div className={classes.searchContainer} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          className={classes.searchInput}
        />
        <Grid container spacing={1}>
          <Grid item>
            <Button
              className={classes.commonBtn}
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              variant='contained'
              color='primary'
              size='small'
            >
              Search
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.commonBtn}
              onClick={() => clearFilters && handleReset(clearFilters)}
              variant='outlined'
              size='small'
            >
              Reset
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.commonBtn}
              color='primary'
              size='small'
              onClick={() => {
                confirm();
              }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </div>
    ),
    filterIcon: (filtered) => <SearchTorchIcon fillColor={filtered ? primaryMain : undefined} />,
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightClassName={classes.highlightText}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: '#',
      key: 'index',
      dataIndex: 'index',
      sorter: (a, b) => a.index - b.index,
    },
    {
      title: 'Title/Question',
      dataIndex: 'question_title',
      key: 'question_title',
      width: '30%',
      ...getColumnSearchProps('question_title', 'Title/Question'),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '40%',
    },
    {
      title: 'Created date',
      key: 'created_at',
      dataIndex: 'created_at',
      render: (value) => DateTime.fromISO(value).toLocaleString(),
      sorter: (a, b) => DateTime.fromISO(a.created_at) - DateTime.fromISO(b.created_at),
    },
    {
      title: 'Start date',
      key: 'startsAt',
      dataIndex: 'startsAt',
      render: (value) => DateTime.fromISO(value).toLocaleString(),
      sorter: (a, b) => DateTime.fromISO(a.startsAt) - DateTime.fromISO(b.startsAt),
    },
    {
      title: 'End date',
      key: 'endsAt',
      dataIndex: 'endsAt',
      render: (value) => DateTime.fromISO(value).toLocaleString(),
      sorter: (a, b) => DateTime.fromISO(a.endsAt) - DateTime.fromISO(b.endsAt),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      filters: categoryFilters.map((item) => ({ text: item, value: item })),
      onFilter: (value, record) => record.category === value,
    },
  ];

  useEffect(
    () => () => {
      queryClient.invalidateQueries({ queryKey: [queryPollTypes.polls] });
    },
    [],
  );

  return (
    <Grid container className={classes.pollsContainer}>
      {pollsLoading && (
        <Backdrop open className={classes.backdrop}>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
      <Grid item container direction='column' spacing={4}>
        <Grid item container justifyContent='space-between'>
          <Grid item>
            <div className={classes.header}>Polls</div>
          </Grid>
          <Grid item>
            <Button
              onClick={() => navigate('/admin/polls/create')}
              className={classes.uploadBtn}
              variant='contained'
              size='large'
            >
              <PaperAddIcon className={classes.addPollIcon} fillColor={white} />
              <div>Add new Poll</div>
            </Button>
          </Grid>
        </Grid>

        <Grid item container>
          <Grid item xs className={classes.tabsContainer}>
            <Tabs
              activeKey={tabTypes[0].key}
              items={tabTypes.map(({ key, renderLabel, renderChildren }) => ({
                key,
                label: renderLabel(dataSource, classes),
                children: renderChildren(columns, dataSource, classes),
              }))}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Polls;
