import { Tabs } from 'antd';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TabVines from './Tabs/Vines';
import TabBlock from './Tabs/Block';
import TabVineyard from './Tabs/Vineyard';

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    padding: theme.spacing(4),
    position: 'relative',
    zIndex: 1,
  },
  header: { fontFamily: 'Alegreya-Medium', fontSize: '2.5rem' },
  infoTabsContainer: {
    '& .ant-tabs-tab-btn': {
      color: theme.vinesTabItem,
    },
    '& .ant-tabs-tab-btn:hover': {
      color: theme.vinesTabItemSelected,
    },
    '& .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn': {
      color: theme.vinesTabItemSelected,
    },
    '& .ant-tabs-ink-bar': {
      background: theme.satinSheetGold,
    },
    '& .ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav': {
      width: 'fit-content',
    },
  },
}));

const Info = ({ activeTab, setActiveTab, selectedWine }) => {
  const classes = useStyles();

  const tabs = [
    { label: 'Vines', key: 'vines', children: <TabVines wine={selectedWine} /> },
    { label: 'Block', key: 'block', children: <TabBlock wine={selectedWine} /> },
    { label: 'Vineyard', key: 'vineyard', children: <TabVineyard wine={selectedWine} /> },
  ];

  return (
    <Grid container direction='column' className={classes.infoContainer} spacing={2}>
      <Grid item>
        <div className={classes.header}>My Dashboard</div>
      </Grid>
      <Grid item className={classes.infoTabsContainer}>
        <Tabs
          defaultActiveKey={tabs[0].key}
          items={tabs}
          onChange={(activeKey) => {
            setActiveTab(activeKey);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Info;
