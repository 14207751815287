import { number, string, object } from 'prop-types';
import common from '@material-ui/core/colors/common';

const CheckIcon = ({
  className = '',
  fillColor = common.black,
  height = 25,
  style = {},
  width = 25,
}) => (
  <svg style={style} className={className} height={height} width={width} viewBox='0 0 13 13'>
    <g transform='translate(0, 0)'>
      <path
        d='M4,11a1,1,0,0,1-.707-.293l-3-3A1,1,0,0,1,1.707,6.293l2.2,2.2L10.21.386a1,1,0,0,1,1.58,1.228l-7,9A1,1,0,0,1,4.062,11Z'
        fill={fillColor}
      ></path>
    </g>
  </svg>
);

CheckIcon.propTypes = {
  className: string,
  fillColor: string,
  height: number,
  style: object,
  width: number,
};

export default CheckIcon;
