import { methods, fetchApi } from 'utils/apiConfig';
const { GET } = methods;

const queryWineTypes = {
  wines: 'wines',
};

const fetchWines = ({ signal }) =>
  fetchApi('/vine-user-transactions/getVines/user', GET, { signal });

export { queryWineTypes, fetchWines };
