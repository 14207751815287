import { number, string, object } from 'prop-types';
import common from '@material-ui/core/colors/common';

const NotificationIcon = ({
  className = '',
  fillColor = 'none',
  strokeColor = common.black,
  height = 25,
  style = {},
  width = 25,
}) => (
  <svg
    style={style}
    className={className}
    height={height}
    viewBox='0 0 24 24'
    width={width}
    fill={fillColor}
  >
    <path
      d='M14.5001 17.8333H20.3334L18.6942 16.1942C18.4741 15.974 18.2995 15.7126 18.1804 15.425C18.0613 15.1373 18 14.829 18.0001 14.5177V10.8333C18.0003 9.38542 17.5516 7.97306 16.7159 6.7907C15.8801 5.60834 14.6984 4.71412 13.3334 4.23117V3.83333C13.3334 3.21449 13.0876 2.621 12.65 2.18342C12.2124 1.74583 11.6189 1.5 11.0001 1.5C10.3812 1.5 9.78775 1.74583 9.35016 2.18342C8.91258 2.621 8.66675 3.21449 8.66675 3.83333V4.23117C5.94841 5.1925 4.00008 7.786 4.00008 10.8333V14.5188C4.00008 15.1465 3.75041 15.7497 3.30591 16.1942L1.66675 17.8333H7.50008M14.5001 17.8333H7.50008M14.5001 17.8333V19C14.5001 19.9283 14.1313 20.8185 13.475 21.4749C12.8186 22.1313 11.9283 22.5 11.0001 22.5C10.0718 22.5 9.18158 22.1313 8.52521 21.4749C7.86883 20.8185 7.50008 19.9283 7.50008 19V17.8333'
      stroke={strokeColor}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

NotificationIcon.propTypes = {
  className: string,
  fillColor: string,
  strokeColor: string,
  height: number,
  style: object,
  width: number,
};

export default NotificationIcon;
