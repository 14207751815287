import { AdminRoute, AuthRoute, PublicRoute } from 'components/AuthRoutes';
import { useMemo } from 'react';
import { Navigate, Route } from 'react-router-dom';

const resolveRouteSchema = (routeSchema) =>
  routeSchema.map(
    ({
      path,
      element: Element,
      adminRoute,
      authRequired,
      fallback,
      redirectPath,
      routes: subroutes,
      ...restProps
    }) => {
      let Component = fallback ? (
        <Navigate to={redirectPath} replace />
      ) : (
        <Element {...restProps} />
      );

      const AuthWrapper =
        authRequired !== undefined ? (authRequired ? AuthRoute : PublicRoute) : null;

      const AdminWrapper = adminRoute ? AdminRoute : null;

      if (AdminWrapper) Component = <AdminWrapper>{Component}</AdminWrapper>;
      if (AuthWrapper) Component = <AuthWrapper>{Component}</AuthWrapper>;

      return subroutes ? (
        <Route key={path} path={path} element={Component}>
          {resolveRouteSchema(subroutes)}
        </Route>
      ) : (
        <Route key={path} path={path} element={Component} />
      );
    },
  );

// hook that resolves given route schema and generates route components
const useRouteSchema = (routeSchema) => {
  const routes = useMemo(() => resolveRouteSchema(routeSchema), []);

  return routes;
};

export default useRouteSchema;
