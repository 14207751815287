import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  vinesContainer: {
    display: 'flex',
    alignItems: 'end',
  },
  barOneContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  barOne: {
    width: 102,
    height: 289,
    background: '#6D614A',
  },
  barTwoContainer: {
    marginLeft: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  barTwo: {
    width: 102,
    height: 253,
    background: '#BB6748',
  },
}));

const Vines = () => {
  const classes = useStyles();

  return (
    <div className={classes.vinesContainer}>
      <div className={classes.barOneContainer}>
        <div>1,098</div>
        <div className={classes.barOne}></div>
        <div>2020</div>
      </div>
      <div className={classes.barTwoContainer}>
        <div>978</div>
        <div className={classes.barTwo}></div>
        <div>2022</div>
      </div>
    </div>
  );
};

export default Vines;
