import { createClient, configureChains, mainnet, goerli } from 'wagmi';
import { Buffer } from 'buffer';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { infuraProvider } from 'wagmi/providers/infura';
import { publicProvider } from 'wagmi/providers/public';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import metamaskImg from 'assets/images/metamask.png';
import coinbaseImg from 'assets/images/coinbase.png';
import walletConnectImg from 'assets/images/wallet-connect.png';

/**
 * In order to add a new wallet provider to app:
 * 1. Pass it's wallet connector (from wagmi docs or custom) to connectors array (wagminClient).
 * 2. Generate a new object with metadata and add it to walletProviderTypes, in order to be used on ui.
 */

// initialize wagmi client (used for accessing web3)
const wagmiClientInitialization = () => {
  //workaround for buffer deficiency on wagmi library (possible fix on wagmi library upgrade)
  window.Buffer = Buffer;

  // client's storage clearance before initialization
  // removes any pre-connected connector (wallet connection fix)
  Object.keys(localStorage).forEach((key) => {
    if (key.includes('wagmi')) localStorage.removeItem(key);
  });

  const chains = process.env.REACT_APP_ENVIRONMENT === 'production' ? [mainnet] : [goerli];

  const { provider } = configureChains(
    chains,
    [
      alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_PROVIDER_API_KEY, priority: 0 }),
      infuraProvider({ apiKey: process.env.REACT_APP_INFURA_PROVIDER_API_KEY, priority: 0 }),
      publicProvider({ priority: 0 }),
    ],
    { targetQuorum: 1, minQuorum: 1, stallTimeout: 1000 },
  );

  const wagmiClient = createClient({
    autoConnect: true,
    connectors: [
      new MetaMaskConnector({ chains }),
      new CoinbaseWalletConnector({
        chains,
      }),
      new WalletConnectConnector({
        chains,
        options: {
          qrcode: true,
        },
      }),
    ],
    provider,
  });

  return wagmiClient;
};

// wallet providers metadata
const walletProviderTypes = {
  coinbaseWallet: {
    id: 'coinbaseWallet',
    label: 'Coinbase Wallet',
    imgSrc: coinbaseImg,
  },
  metaMask: { id: 'metaMask', label: 'Metamask', imgSrc: metamaskImg },
  walletConnect: {
    id: 'walletConnect',
    label: 'Wallet Connect',
    imgSrc: walletConnectImg,
  },
};

export { wagmiClientInitialization, walletProviderTypes };
