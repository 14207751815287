import { useContext } from 'react';
import { Routes, useMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Header from 'components/Header';
import Settings from 'components/Settings';
import { AppDataContext } from 'Context';
import useRouteSchema from 'hooks/routes';
import routeSchema from 'config/routes';

const useStyles = makeStyles((theme) => ({
  appContainer: {
    height: 'inherit',
    paddingTop: theme.spacing(7),
    '&.noPadding': {
      paddingTop: 0,
    },
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: theme.common.white },
}));

const App = () => {
  const {
    appData: { initialLoad },
  } = useContext(AppDataContext);
  const classes = useStyles();

  const routes = useRouteSchema(routeSchema);

  const toWalletRoute = useMatch('/sotw/mint');
  const toAdminRoutes = useMatch('/admin/*');

  return (
    <div
      className={`${classes.appContainer} ${!!toWalletRoute || !!toAdminRoutes ? 'noPadding' : ''}`}
    >
      {initialLoad ? (
        <Backdrop open className={classes.backdrop}>
          <CircularProgress color='inherit' />
        </Backdrop>
      ) : (
        <>
          <Settings />
          <Header toWalletRoute={!!toWalletRoute} toAdminRoutes={!!toAdminRoutes} />
          <Routes>{routes}</Routes>
        </>
      )}
    </div>
  );
};

export default App;
