import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  outer: { backgroundColor: 'inherit' },
  inner: ({ percent }) => ({
    backgroundColor: theme.grey[750],
    width: `${percent}%`,
    whiteSpace: 'nowrap',
  }),
}));

const ResultBar = ({ children, ...restProps }) => {
  const classes = useStyles(restProps);
  return (
    <div className={classes.outer}>
      <div className={classes.inner}>{children}</div>
    </div>
  );
};

export default ResultBar;
