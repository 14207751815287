import { methods, fetchApi } from 'utils/apiConfig';
const { GET } = methods;

const queryProfileTypes = {
  profile: 'profile',
};

const fetchProfile = ({ signal }) => fetchApi('/auth/profile', GET, { signal });

export { queryProfileTypes, fetchProfile };
