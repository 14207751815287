import { number, string, object } from 'prop-types';
import common from '@material-ui/core/colors/common';

const VerticalDotsIcon = ({
  className = '',
  fillColor = common.black,
  height = 25,
  style = {},
  width = 25,
}) => (
  <svg style={style} className={className} height={height} viewBox='0 0 4 16' width={width}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 12C0.9 12 0 12.9 0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12Z'
      fill={fillColor}
    />
  </svg>
);

VerticalDotsIcon.propTypes = {
  className: string,
  fillColor: string,
  height: number,
  style: object,
  width: number,
};

export default VerticalDotsIcon;
