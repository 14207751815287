import { transitions, positions } from 'react-alert';

const alertTemplateOptions = {
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: '20px',
  transition: transitions.FADE,
};

export { alertTemplateOptions };
