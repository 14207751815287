import { number, string, object } from 'prop-types';
import common from '@material-ui/core/colors/common';

const SearchTorchIcon = ({
  className = '',
  fillColor = common.black,
  height = 14,
  style = {},
  width = 14,
}) => (
  <svg style={style} className={className} height={height} width={width} viewBox='0 0 12 12'>
    <path
      d='M11.3252 10.784L7.84708 7.30585C8.38682 6.60809 8.67878 5.75496 8.67878 4.85764C8.67878 3.78353 8.25958 2.77639 7.50155 2.01701C6.74351 1.25764 5.73369 0.839783 4.66092 0.839783C3.58816 0.839783 2.57833 1.25898 1.8203 2.01701C1.06092 2.77505 0.643066 3.78353 0.643066 4.85764C0.643066 5.93041 1.06226 6.94023 1.8203 7.69826C2.57833 8.45764 3.58682 8.8755 4.66092 8.8755C5.55825 8.8755 6.41003 8.58353 7.1078 8.04514L10.5859 11.5219C10.5961 11.5321 10.6082 11.5402 10.6216 11.5457C10.6349 11.5513 10.6492 11.5541 10.6636 11.5541C10.678 11.5541 10.6923 11.5513 10.7056 11.5457C10.719 11.5402 10.7311 11.5321 10.7413 11.5219L11.3252 10.9393C11.3354 10.9291 11.3435 10.917 11.349 10.9037C11.3546 10.8904 11.3574 10.8761 11.3574 10.8617C11.3574 10.8472 11.3546 10.8329 11.349 10.8196C11.3435 10.8063 11.3354 10.7942 11.3252 10.784ZM6.78235 6.97907C6.2145 7.54559 5.46182 7.85764 4.66092 7.85764C3.86003 7.85764 3.10735 7.54559 2.5395 6.97907C1.97298 6.41121 1.66092 5.65853 1.66092 4.85764C1.66092 4.05675 1.97298 3.30273 2.5395 2.73621C3.10735 2.16969 3.86003 1.85764 4.66092 1.85764C5.46182 1.85764 6.21583 2.16835 6.78235 2.73621C7.34887 3.30407 7.66092 4.05675 7.66092 4.85764C7.66092 5.65853 7.34887 6.41255 6.78235 6.97907Z'
      fill={fillColor}
      fillOpacity='0.25'
    />
  </svg>
);

SearchTorchIcon.propTypes = {
  className: string,
  fillColor: string,
  height: number,
  style: object,
  width: number,
};

export default SearchTorchIcon;
