import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useAlert } from 'react-alert';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { emailVerify } from 'store/auth/emailVerify';

const useStyles = makeStyles((theme) => ({
  emailVerifyContainer: { height: 'inherit' },
  circular: { color: theme.common.white },
}));

const EmailVerify = () => {
  const classes = useStyles();
  const alert = useAlert();
  const navigate = useNavigate();

  const { search } = useLocation();

  const { mutate } = useMutation({
    mutationFn: emailVerify,
    onSuccess: () => {
      alert.success(
        <div>
          <strong>Your email was verified.</strong>
        </div>,
      );
      navigate('/auth/login', { replace: true });
    },
    onError: (err) => {
      alert.error(
        <div>
          <strong>An error occured</strong>
          <br />
          {err.message}
        </div>,
      );
      navigate('/auth/login', { replace: true });
    },
  });

  useEffect(() => {
    if (search) {
      const searchParams = new URLSearchParams(search);
      mutate({ token: searchParams.get('token') });
    }
  }, [search]);

  return (
    <Grid
      container
      className={classes.emailVerifyContainer}
      justifyContent='center'
      alignItems='center'
    >
      <Grid item>
        <CircularProgress size={30} classes={{ root: classes.circular }} />
      </Grid>
    </Grid>
  );
};

export default EmailVerify;
