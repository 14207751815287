import { number, string, object } from 'prop-types';
import common from '@material-ui/core/colors/common';

const VideoIcon = ({
  className = '',
  fillColor = common.black,
  height = 25,
  style = {},
  width = 25,
}) => (
  <svg style={style} className={className} height={height} viewBox='0 0 18 12' width={width}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14 4.5V1C14 0.45 13.55 0 13 0H1C0.45 0 0 0.45 0 1V11C0 11.55 0.45 12 1 12H13C13.55 12 14 11.55 14 11V7.5L16.29 9.79C16.92 10.42 18 9.97 18 9.08V2.91C18 2.02 16.92 1.57 16.29 2.2L14 4.5Z'
      fill={fillColor}
    />
  </svg>
);

VideoIcon.propTypes = {
  className: string,
  fillColor: string,
  height: number,
  style: object,
  width: number,
};

export default VideoIcon;
