import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { DatePicker } from 'antd';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createPoll } from 'store/polls';
import pollCategories from './constants';
import { commonBtnStyles, containedOrangeButtonStyles } from 'assets/styles/common';

const { RangePicker } = DatePicker;

const useStyles = makeStyles((theme) => ({
  pollsContainer: {
    height: 'inherit',
    padding: `${theme.spacing(10)}px ${theme.spacing(15)}px 0 ${theme.spacing(6)}px`,
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: theme.common.white },
  header: {
    fontSize: theme.spacing(3.2),
  },
  form: {
    width: '100%',
  },
  formLabel: {
    fontSize: '1rem',
  },
  commonBtn: commonBtnStyles,
  uploadBtn: containedOrangeButtonStyles(theme),
  textField: {
    '& label.Mui-focused': {
      color: theme.brown,
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.common.white,
      '&:hover fieldset': {
        borderColor: theme.grey[600],
      },
      '&.Mui-error:hover fieldset': {
        borderColor: theme.muiError,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.brown,
      },
      '&.Mui-focused.Mui-error fieldset': {
        borderColor: theme.muiError,
      },
    },
    '& legend span': {
      padding: 5,
    },
  },
  datePicker: {
    width: '100%',
    borderRadius: 4,
    borderColor: theme.grey[400],
    '&.ant-picker:hover': {
      borderColor: theme.common.black,
    },
    '&.ant-picker-focused': {
      boxShadow: 'none',
      borderColor: theme.grey[600],
      '&:hover': {
        borderColor: theme.grey[600],
      },
    },
  },
}));

const CreatePoll = () => {
  const classes = useStyles();
  const alert = useAlert();

  const navigate = useNavigate();

  const { mutate: onCreatePoll, isLoading } = useMutation({
    mutationFn: createPoll,
    onSuccess: () => {
      navigate('/admin/polls');
      alert.success(
        <div>
          <strong>The poll has been created</strong>
        </div>,
      );
    },
    onError: (err) => {
      alert.error(
        <div>
          <strong>An error occured while creating the poll</strong>
          <div>{err.message}</div>
        </div>,
      );
    },
  });

  const { handleSubmit, handleChange, getFieldProps, errors, touched } = useFormik({
    initialValues: {
      category: null,
      date: null,
      content: '',
      description: '',
      answer1: '',
      answer2: '',
    },
    validationSchema: Yup.object({
      category: Yup.number().integer().required().typeError('Category is required'),
      date: Yup.array().required().typeError('Date range is required'),
      content: Yup.string().required('Title is required'),
      description: Yup.string().required('Description is required'),
      answer1: Yup.string().required('Answer No.1 is required'),
      answer2: Yup.string().required('Answer No.2 is required'),
    }),
    onSubmit: ({ category, date, content, description, answer1, answer2 }) => {
      onCreatePoll({
        startsAt: date[0].format(),
        endsAt: date[1].format(),
        category,
        pollQuestions: [
          {
            type: 'YES/NO',
            is_active: true,
            content,
            description,
            pollAnswers: [
              {
                is_active: true,
                content: answer1,
              },
              {
                is_active: true,
                content: answer2,
              },
            ],
          },
        ],
      });
    },
  });

  return (
    <Grid container className={classes.pollsContainer}>
      {isLoading && (
        <Backdrop open className={classes.backdrop}>
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
      <form onSubmit={handleSubmit} className={classes.form}>
        <Grid container direction='column' spacing={8}>
          <Grid item>
            <div className={classes.header}>Add new poll</div>
          </Grid>

          <Grid item container direction='column' spacing={6}>
            <Grid item container spacing={6}>
              <Grid item sm={6}>
                <div className={classes.formLabel}>Category*</div>
                <FormControl
                  variant='outlined'
                  size='small'
                  fullWidth
                  classes={{ root: classes.textField }}
                  error={touched.category && !!errors.category}
                >
                  <Select {...getFieldProps('category')}>
                    {pollCategories.map(({ id, title }) => (
                      <MenuItem key={title} value={id}>
                        {title}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.category && !!errors.category && (
                    <FormHelperText>{errors.category}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <div className={classes.formLabel}>Date range*</div>
                <FormControl
                  variant='outlined'
                  size='small'
                  fullWidth
                  classes={{ root: classes.textField }}
                  error={touched.date && !!errors.date}
                >
                  <RangePicker
                    status={touched.date && !!errors.date ? 'error' : ''}
                    format='DD/MM/YYYY'
                    className={classes.datePicker}
                    size='large'
                    {...getFieldProps('date')}
                    onChange={(value) => {
                      const e = { target: { name: 'date', value } };
                      handleChange(e);
                    }}
                  />
                  {touched.date && !!errors.date && <FormHelperText>{errors.date}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>

            <Grid item>
              <div className={classes.formLabel}>Title/Question*</div>
              <TextField
                size='small'
                variant='outlined'
                fullWidth
                classes={{ root: classes.textField }}
                {...getFieldProps('content')}
                error={touched.content && !!errors.content}
                helperText={touched.content && errors.content}
              />
            </Grid>

            <Grid item>
              <div className={classes.formLabel}>Description*</div>
              <TextField
                size='small'
                multiline
                minRows={4}
                variant='outlined'
                fullWidth
                classes={{ root: classes.textField }}
                {...getFieldProps('description')}
                error={touched.description && !!errors.description}
                helperText={touched.description && errors.description}
              />
            </Grid>

            <Grid item container spacing={6}>
              <Grid item sm={6}>
                <div className={classes.formLabel}>Answer No.1*</div>
                <TextField
                  size='small'
                  variant='outlined'
                  fullWidth
                  classes={{ root: classes.textField }}
                  {...getFieldProps('answer1')}
                  error={touched.answer1 && !!errors.answer1}
                  helperText={touched.answer1 && errors.answer1}
                />
              </Grid>
              <Grid item sm={6}>
                <div className={classes.formLabel}>Answer No.2*</div>
                <TextField
                  size='small'
                  variant='outlined'
                  fullWidth
                  classes={{ root: classes.textField }}
                  {...getFieldProps('answer2')}
                  error={touched.answer2 && !!errors.answer2}
                  helperText={touched.answer2 && errors.answer2}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item container justifyContent='flex-end' spacing={2}>
            <Grid item>
              <Button
                onClick={() => navigate('/admin/polls')}
                className={classes.commonBtn}
                variant='contained'
                size='large'
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button type='submit' className={classes.uploadBtn} variant='contained' size='large'>
                Add
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default CreatePoll;
