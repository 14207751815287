import { useEffect, useMemo, useContext } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAlert } from 'react-alert';
import { Button, Skeleton } from 'antd';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { generateMembersVotedText } from './utils';
import { generateRemainingTime } from 'utils/formaters';
import { LampIcon, CheckIcon } from 'components/Icons';
import ResultBar from 'components/ResultBar';
import avatarImg from 'assets/images/avatar.png';
import { queryPollTypes, addVote, fetchPoll } from 'store/polls';
import { AppDataContext } from 'Context';

const commonStyles = {
  fontWeight: 'bold',
};

const useStyles = makeStyles((theme) => ({
  votingContainer: { color: theme.common.white },
  avatarName: { fontSize: '0.8rem' },
  avatarSurname: { fontSize: '0.9rem', ...commonStyles },
  votingHeader: { fontSize: '0.8rem', ...commonStyles },
  votingQuestion: { fontSize: '1.2rem', ...commonStyles },
  votingDescription: { fontSize: '0.9rem' },
  promptForMoreContainer: {
    cursor: 'pointer',
  },
  promptForMoreMessage: {
    fontSize: '0.8rem',
    color: theme.satinSheetGold,
    borderBottom: `1px solid ${theme.satinSheetGold}`,
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      color: theme.satinSheetGoldLight,
      borderColor: theme.satinSheetGoldLight,
    },
  },
  votingDataContainer: {
    fontSize: '0.8rem',
  },
  avatarSkeleton: {
    '& .ant-skeleton-content h3.ant-skeleton-title': {
      marginTop: 0,
    },
    '& .ant-skeleton-content .ant-skeleton-title+.ant-skeleton-paragraph': {
      marginTop: 0,
      '&>li': {
        width: '50%',
      },
      '&>li:nth-child(5),>li:nth-child(6),>li:nth-child(7)': {
        width: '100%',
      },
    },
  },
  answerBtn: {
    transition: 'all 0.3s ease-in-out',
    opacity: 1,
    '&:hover': {
      color: theme.satinSheetGold,
      borderColor: theme.satinSheetGold,
    },
    '&:active': {
      color: 'rgba(0,0,0,.85)',
      borderColor: 'rgba(0,0,0,.85)',
      backgroundColor: theme.common.white,
    },
    '&:focus': {
      color: 'rgba(0,0,0,.85)',
      borderColor: 'rgba(0,0,0,.85)',
      backgroundColor: theme.common.white,
    },
    '&.checked': {
      color: theme.common.white,
      borderColor: theme.satinSheetGold,
      backgroundColor: theme.satinSheetGold,
      '&:hover': {
        color: theme.common.white,
        borderColor: theme.satinSheetGold,
        backgroundColor: theme.satinSheetGold,
      },
    },
  },
  answerResultBarContent: {
    marginLeft: theme.spacing(1),
    '& span:nth-child(1)': { fontWeight: 'bold' },
    '& span:nth-child(2)': { marginLeft: theme.spacing(2) },
  },
}));

const Voting = () => {
  const {
    appData: { votingData },
    onSetAppData,
  } = useContext(AppDataContext);
  const classes = useStyles();
  const {
    satinSheetGold,
    common: { white },
  } = useTheme();
  const alert = useAlert();
  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { data: poll } = useQuery({
    queryKey: [queryPollTypes.poll, votingData?.pollId],
    queryFn: fetchPoll,
    enabled: !!votingData?.pollId,
    onError: (err) => {
      <div>
        <strong>An error occured while retrieving the selected poll</strong>
        <div>{err.message}</div>
      </div>;
    },
  });

  const { mutate: onAddVote } = useMutation({
    mutationFn: addVote,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryPollTypes.poll] });
      alert.success(
        <div>
          <strong>Your vote has been submitted!</strong>
        </div>,
      );
    },
    onError: (err) => {
      <div>
        <strong>An error occured while submitting your vote</strong>
        <div>{err.message}</div>
      </div>;
    },
  });

  // watcher for poll query param
  useEffect(() => {
    if (searchParams.has('poll_id')) {
      const pollId = parseInt(searchParams.get('poll_id'));
      if (pollId) {
        queryClient.removeQueries({ queryKey: [queryPollTypes.poll] });
        onSetAppData({ pollId }, 'votingData', true);
        navigate('/home', { replace: true });
      }
    }
  }, [searchParams]);

  // polls query invalidation on cleanup
  useEffect(
    () => () => {
      queryClient.invalidateQueries({ queryKey: [queryPollTypes.poll] });
    },
    [],
  );

  // pollQuestion match from user selection - data formatting and display
  const {
    pollAuthor,
    category,
    content,
    description,
    pollAnswers,
    votesNo,
    endsAt,
    ready,
    poll_id,
    pollQuestion_id,
    userHasVoted,
    votedPollAnswer_id,
  } = useMemo(() => {
    if (!votingData || !poll) return { ready: false };

    const {
      id: poll_id,
      category,
      userInitiatedPoll: pollAuthor,
      pollQuestions: [{ id: pollQuestion_id, content, description, pollAnswers }],
      pollIsVotedByUser,
      pollAnswerIdSelectedByUser,
      votesNo,
      endsAt,
    } = poll;

    return {
      pollAuthor,
      category,
      content,
      description,
      pollAnswers,
      votesNo,
      endsAt,
      ready: true,
      poll_id,
      pollQuestion_id,
      userHasVoted: pollIsVotedByUser,
      votedPollAnswer_id: pollAnswerIdSelectedByUser,
    };
  }, [votingData, poll]);

  const remainingTime = generateRemainingTime(endsAt, {
    oneChar: true,
    splitDateTo: ['days', 'hours', 'years', 'minutes'],
  });

  return (
    <Grid container direction='column' spacing={2} className={classes.votingContainer}>
      {!ready ? (
        <Skeleton
          active
          avatar
          paragraph={{
            rows: 10,
          }}
          className={classes.avatarSkeleton}
        />
      ) : (
        <>
          <Grid item container alignItems='center' spacing={1}>
            <Grid item>
              <Avatar src={avatarImg} />
            </Grid>
            <Grid item>
              <div className={classes.avatarName}>{pollAuthor.username}</div>
              {/* <div className={classes.avatarSurname}>Surname</div> */}
            </Grid>
          </Grid>

          <Grid item>
            <div className={classes.votingHeader}>{category.toUpperCase()}</div>
          </Grid>

          <Grid item>
            <div className={classes.votingQuestion}>{content}</div>
          </Grid>

          <Grid item>
            <div className={classes.votingDescription}>{description}</div>
          </Grid>

          <Grid item container className={classes.promptForMoreContainer}>
            {/* <Grid item>
              <LampIcon strokeColor={satinSheetGold} />
            </Grid>
            <Grid item>
              <div className={classes.promptForMoreMessage}>Tell me more</div>
            </Grid> */}
          </Grid>

          <Grid item container spacing={4}>
            {pollAnswers.map(({ id, content }) => (
              <Grid item key={content}>
                <Button
                  disabled={userHasVoted || !remainingTime}
                  shape='round'
                  size='large'
                  className={`${classes.answerBtn} ${votedPollAnswer_id === id ? 'checked' : ''}`}
                  onClick={() =>
                    onAddVote({
                      poll_id,
                      pollQuestion_id,
                      pollAnswer_id: id,
                    })
                  }
                >
                  <Grid container alignItems='center' spacing={1}>
                    {votedPollAnswer_id === id && (
                      <Grid item>
                        <CheckIcon width={12} height={12} fillColor={white} />
                      </Grid>
                    )}
                    <Grid item>
                      <span>{content}</span>
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
            ))}
          </Grid>

          <Grid item container direction='column' spacing={1}>
            <Collapse in={userHasVoted}>
              {pollAnswers.map((pa) => {
                const answerCountPercent = Math.floor((pa.usersVotedThisAnswer / votesNo) * 100);
                return (
                  <Grid item container spacing={1} key={pa.id}>
                    <Grid item sm={7}>
                      <ResultBar percent={answerCountPercent}>
                        <div className={classes.answerResultBarContent}>
                          <span>{pa.content}</span>
                          <span>{pa.usersVotedThisAnswer} votes</span>
                        </div>
                      </ResultBar>
                    </Grid>
                    <Grid item sm={4}>
                      {answerCountPercent}%
                    </Grid>
                  </Grid>
                );
              })}
            </Collapse>
          </Grid>

          <Grid item container spacing={3} className={classes.votingDataContainer}>
            <Grid item>
              <div>{generateMembersVotedText(votesNo)}</div>
            </Grid>
            <Grid item>
              <div>{!!remainingTime ? `${remainingTime}remaining` : 'Voting time expired'}</div>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Voting;
