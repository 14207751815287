import { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import DatePicker from 'react-datepicker';
import { Progress as ProgressBar } from 'antd';
import { PlantIcon, GrapeIcon, BarelsIcon, BottleIcon, ArrowBottomIcon } from 'components/Icons';

import 'react-datepicker/dist/react-datepicker.css';

const useStyles = makeStyles((theme) => ({
  introHeader: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
  },
  monthSpaceText: {
    fontSize: '0.9rem',
  },
  progressText: {
    fontSize: '0.8rem',
  },
  datePickerGrid: {
    '& .react-datepicker-popper[data-placement^=bottom]': {
      paddingTop: 0,
      borderRadius: 8,
      // right: `-16px !important`,
    },
    '& .react-datepicker__header': {
      backgroundColor: 'transparent',
      borderBottom: 'none',
      padding: 0,
    },
    '& .react-datepicker__navigation': {
      top: theme.spacing(1.8),
    },
    '& .react-datepicker__navigation-icon::before': { borderColor: 'unset' },
    '& .react-datepicker__current-month': { padding: theme.spacing(1.8) },
    '& .react-datepicker__day-names': {
      marginBottom: theme.spacing(1),
    },
    '& .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected': {
      borderRadius: '50%',
      backgroundColor: theme.grey[500],
    },
    '& .react-datepicker__day:hover': { borderRadius: '50%', backgroundColor: theme.grey[300] },
  },
  datePickerToggler: {
    cursor: 'pointer',
  },
  arrow: { marginLeft: theme.spacing(1), marginTop: theme.spacing(0.8) },
}));

const SeasonProgress = () => {
  const [startDate, setStartDate] = useState(new Date());

  const classes = useStyles();
  const { grey, satinSheetGold } = useTheme();

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item container justifyContent='space-between' alignItems='center'>
        <Grid item className={classes.introHeader}>
          GROWING SEASON
        </Grid>
        <Grid item className={classes.datePickerGrid}>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            showPopperArrow={false}
            customInput={
              <Grid container alignItems='center' className={classes.datePickerToggler}>
                <Grid item>Today</Grid>
                <Grid item>
                  <ArrowBottomIcon width={11} height={11} className={classes.arrow} />
                </Grid>
              </Grid>
            }
            popperPlacement='bottom-start'
            monthsShown={2}
          />
        </Grid>
      </Grid>

      <Grid item container justifyContent='space-between' alignItems='center'>
        <Grid item>
          <Grid container direction='column' alignItems='center'>
            <Grid item>
              <PlantIcon width={30} height={30} fillColor={grey.A400} />
            </Grid>
            <Grid>
              <div className={classes.monthSpaceText}>Feb - Apr</div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction='column' alignItems='center'>
            <Grid item>
              <GrapeIcon width={30} height={30} fillColor={grey.A400} />
            </Grid>
            <Grid>
              <div className={classes.monthSpaceText}>May - Aug</div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction='column' alignItems='center'>
            <Grid item>
              <BarelsIcon width={30} height={30} fillColor={grey.A400} />
            </Grid>
            <Grid>
              <div className={classes.monthSpaceText}>Sep - Oct</div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container direction='column' alignItems='center'>
            <Grid item>
              <BottleIcon width={30} height={30} fillColor={grey.A400} />
            </Grid>
            <Grid>
              <div className={classes.monthSpaceText}>Nov</div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container>
        <ProgressBar
          percent={50}
          showInfo={false}
          strokeColor={satinSheetGold}
          trailColor={grey.A100}
        />
      </Grid>

      <Grid item container>
        <div className={classes.progressText}>
          Your vine is in week 14 of a typical 32 week growing season.
        </div>
      </Grid>
    </Grid>
  );
};

export default SeasonProgress;
