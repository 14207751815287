import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Skeleton } from 'antd';

const RowDetail = ({ classes, label, value }) => (
  <Grid item container alignItems='center' spacing={2}>
    <Grid item>
      <div className={classes.label}>{label}:</div>
    </Grid>
    <Grid item>
      <div className={classes.value}>{value}</div>
    </Grid>
  </Grid>
);

const useStyles = makeStyles(() => ({
  header: { fontSize: '1.2rem', fontFamily: 'Alegreya-Sans-Medium' },
  label: { fontSize: '0.85rem', fontFamily: 'Alegreya-Sans-Bold' },
  value: { fontSize: '1.1rem' },
  avatarSkeleton: {
    '& .ant-skeleton-content h3.ant-skeleton-title': {
      marginTop: 0,
    },
    '& .ant-skeleton-content .ant-skeleton-title+.ant-skeleton-paragraph': {
      marginTop: 0,
      '&>li': {
        width: '50%',
      },
      '&>li:nth-child(5),>li:nth-child(6),>li:nth-child(7)': {
        width: '100%',
      },
    },
  },
}));

const LocationDetails = ({ data, rows }) => {
  const classes = useStyles();

  return (
    <Grid item container direction='column' spacing={1}>
      <Grid item>
        <div className={classes.header}>Location Details</div>
      </Grid>
      <Grid item container direction='column' spacing={1}>
        {data ? (
          rows.map(({ label, value }) => (
            <RowDetail key={value} label={label} value={data[value]} classes={classes} />
          ))
        ) : (
          <Skeleton
            active
            paragraph={{
              rows: 4,
            }}
            className={classes.avatarSkeleton}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default LocationDetails;
