import { bool } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import avatarImg from 'assets/images/avatar.png';

const commonStyles = { fontFamily: 'Roboto', fontWeight: 'bold', fontSize: '0.8rem' };

const useStyles = makeStyles((theme) => ({
  messageTime: { ...commonStyles },
  avatarName: {
    ...commonStyles,
  },
  messageText: {
    ...commonStyles,
    fontSize: '0.7rem',
    backgroundColor: theme.grey[750],
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(1.5),
  },
}));

const ChatRow = ({ left = false, right = false }) => {
  const classes = useStyles();

  return (
    <Grid item container>
      {!!right && <Grid item xs />}

      <Grid item xs={7}>
        <Grid container direction='column' spacing={1}>
          <Grid item container justifyContent='space-between'>
            {!!left && (
              <Grid item>
                <div className={classes.messageTime}>12:45</div>
              </Grid>
            )}
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <Avatar src={avatarImg} />
                </Grid>
                <Grid item>
                  <div className={classes.avatarName}>Winemaker</div>
                </Grid>
              </Grid>
            </Grid>
            {!!right && (
              <Grid item>
                <div className={classes.messageTime}>12:45</div>
              </Grid>
            )}
          </Grid>

          <Grid item>
            <div className={classes.messageText}>
              You know, I think I am going to water every week.
            </div>
          </Grid>
        </Grid>

        {!!left && <Grid item xs />}
      </Grid>
    </Grid>
  );
};

ChatRow.propTypes = {
  left: bool,
  right: bool,
};

export default ChatRow;
