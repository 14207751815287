import { number, string, object } from 'prop-types';
import common from '@material-ui/core/colors/common';

const BarelsIcon = ({
  className = '',
  fillColor = common.black,
  height = 25,
  style = {},
  width = 25,
}) => (
  <svg style={style} className={className} height={height} viewBox='0 0 30 30' width={width}>
    <path
      d='M22.4383 13.6048C21.9435 13.6048 21.4595 13.6521 20.9903 13.7414C22.1759 12.3827 22.8951 10.607 22.8951 8.6664C22.8951 4.40493 19.4281 0.937988 15.1667 0.937988C10.9052 0.937988 7.43824 4.40493 7.43824 8.6664C7.43824 10.607 8.15742 12.3827 9.34301 13.7414C8.87379 13.652 8.38987 13.6048 7.89498 13.6048C3.63357 13.6048 0.166626 17.0717 0.166626 21.3331C0.166626 25.5946 3.63357 29.0615 7.89498 29.0615C11.238 29.0615 14.0917 26.9278 15.1667 23.9508C16.2416 26.9278 19.0954 29.0615 22.4383 29.0615C26.6998 29.0615 30.1667 25.5946 30.1667 21.3331C30.1667 17.0717 26.6997 13.6048 22.4383 13.6048ZM8.35166 8.6664C8.35166 4.9086 11.4088 1.85147 15.1666 1.85147C18.9244 1.85147 21.9815 4.9086 21.9815 8.6664C21.9815 12.4242 18.9244 15.4813 15.1667 15.4813C11.4089 15.4813 8.35166 12.4241 8.35166 8.6664ZM7.89498 28.1481C4.13724 28.1481 1.0801 25.0909 1.0801 21.3331C1.0801 17.5753 4.13724 14.5182 7.89498 14.5182C11.6528 14.5182 14.7099 17.5753 14.7099 21.3331C14.7099 25.0909 11.6527 28.1481 7.89498 28.1481ZM13.7186 16.2582C14.1878 16.3475 14.6718 16.3948 15.1667 16.3948C15.6615 16.3948 16.1455 16.3474 16.6147 16.2582C15.9903 16.9738 15.4953 17.8052 15.1667 18.7154C14.8379 17.8052 14.3431 16.9738 13.7186 16.2582ZM22.4383 28.1481C18.6805 28.1481 15.6234 25.091 15.6234 21.3332C15.6234 17.5754 18.6805 14.5182 22.4383 14.5182C26.1961 14.5182 29.2532 17.5754 29.2532 21.3332C29.2532 25.0909 26.1961 28.1481 22.4383 28.1481Z'
      fill={fillColor}
    />
    <path
      d='M7.89497 15.0122C4.40963 15.0122 1.57404 17.8478 1.57404 21.3331C1.57404 24.8185 4.40957 27.6541 7.89497 27.6541C11.3804 27.6541 14.216 24.8186 14.216 21.3331C14.216 17.8478 11.3804 15.0122 7.89497 15.0122ZM3.91972 24.9949C3.03115 24.031 2.48751 22.7444 2.48751 21.3332C2.48751 19.922 3.03115 18.6355 3.91972 17.6715V24.9949ZM6.26541 26.4896C5.75055 26.3266 5.26891 26.0883 4.83309 25.7879V16.8786C5.26879 16.5781 5.75049 16.3399 6.26541 16.1768V26.4896ZM8.6111 26.6932C8.37672 26.7243 8.13772 26.7407 7.89497 26.7407C7.65221 26.7407 7.41327 26.7243 7.17889 26.6932V15.9731C7.41321 15.942 7.65227 15.9256 7.89497 15.9256C8.13772 15.9256 8.37672 15.942 8.6111 15.9731V26.6932ZM10.9567 25.7879C10.521 26.0883 10.0394 26.3266 9.52458 26.4896V16.1768C10.0394 16.3399 10.521 16.5782 10.9567 16.8786V25.7879ZM11.8702 24.9949V17.6715C12.7588 18.6354 13.3025 19.922 13.3025 21.3332C13.3025 22.7444 12.7588 24.031 11.8702 24.9949Z'
      fill={fillColor}
    />
    <path
      d='M22.4384 15.0122C18.953 15.0122 16.1174 17.8478 16.1174 21.3331C16.1174 24.8185 18.9529 27.6541 22.4384 27.6541C25.9237 27.6541 28.7593 24.8186 28.7593 21.3331C28.7592 17.8478 25.9237 15.0122 22.4384 15.0122ZM18.4631 24.9949C17.5744 24.031 17.0307 22.7444 17.0307 21.3331C17.0307 19.9218 17.5744 18.6354 18.4631 17.6714V24.9949ZM20.8087 26.4896C20.2939 26.3266 19.8123 26.0883 19.3766 25.7879V16.8786C19.8123 16.5782 20.2939 16.3399 20.8087 16.1768V26.4896ZM23.1544 26.6932C22.9201 26.7243 22.6811 26.7407 22.4384 26.7407C22.1956 26.7407 21.9566 26.7243 21.7222 26.6932V15.9731C21.9566 15.942 22.1956 15.9256 22.4384 15.9256C22.6811 15.9256 22.9201 15.942 23.1544 15.9731V26.6932ZM25.5002 25.7879C25.0645 26.0883 24.5828 26.3266 24.0679 26.4896V16.1768C24.5827 16.3398 25.0644 16.5781 25.5002 16.8785V25.7879ZM26.4136 24.9949V17.6715C27.3022 18.6355 27.8459 19.922 27.8459 21.3333C27.8458 22.7444 27.3022 24.031 26.4136 24.9949Z'
      fill={fillColor}
    />
    <path
      d='M15.1666 14.9876C18.652 14.9876 21.4876 12.152 21.4876 8.66663C21.4876 5.18124 18.652 2.3457 15.1666 2.3457C11.6812 2.3457 8.84564 5.18124 8.84564 8.66663C8.84564 12.152 11.6812 14.9876 15.1666 14.9876ZM19.1419 5.00493C20.0306 5.96885 20.5743 7.2554 20.5743 8.66663C20.5743 10.0779 20.0306 11.3644 19.1419 12.3284V5.00493ZM16.7962 3.5102C17.311 3.67326 17.7926 3.91157 18.2283 4.21198V13.1212C17.7926 13.4216 17.311 13.6599 16.7962 13.823V3.5102ZM14.4505 3.30664C14.6849 3.27553 14.9239 3.25912 15.1666 3.25912C15.4094 3.25912 15.6484 3.27553 15.8828 3.30664V14.0267C15.6484 14.0578 15.4094 14.0742 15.1666 14.0742C14.9238 14.0742 14.6849 14.0578 14.4505 14.0267V3.30664ZM12.1048 4.21198C12.5405 3.91157 13.0222 3.67326 13.537 3.5102V13.823C13.0222 13.6599 12.5406 13.4216 12.1048 13.1212V4.21198ZM11.1913 5.00493V12.3283C10.3027 11.3644 9.759 10.0779 9.759 8.66657C9.75912 7.25534 10.3028 5.9688 11.1913 5.00493Z'
      fill={fillColor}
    />
  </svg>
);

BarelsIcon.propTypes = {
  className: string,
  fillColor: string,
  height: number,
  style: object,
  width: number,
};

export default BarelsIcon;
