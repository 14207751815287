import { methods, fetchApi } from 'utils/apiConfig';
const { POST } = methods;

const register = (data) =>
  fetchApi('/auth/signup', POST, {
    data,
    headers: {
      [process.env.REACT_APP_LOGIN_HEADER_NAME]: process.env.REACT_APP_LOGIN_HEADER_VALUE,
    },
  });

export { register };
