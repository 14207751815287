import { number, string, object } from 'prop-types';
import common from '@material-ui/core/colors/common';

const CheckCircleIcon = ({
  className = '',
  fillColor = common.black,
  height = 17,
  style = {},
  width = 17,
}) => (
  <svg
    style={style}
    className={className}
    height={height}
    width={width}
    viewBox='0 0 17 17'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.5 16.6887C10.6217 16.6887 12.6566 15.8459 14.1569 14.3456C15.6571 12.8453 16.5 10.8105 16.5 8.68872C16.5 6.56699 15.6571 4.53216 14.1569 3.03187C12.6566 1.53158 10.6217 0.688721 8.5 0.688721C6.37827 0.688721 4.34344 1.53158 2.84315 3.03187C1.34285 4.53216 0.5 6.56699 0.5 8.68872C0.5 10.8105 1.34285 12.8453 2.84315 14.3456C4.34344 15.8459 6.37827 16.6887 8.5 16.6887ZM12.207 7.39572C12.3892 7.20712 12.49 6.95452 12.4877 6.69232C12.4854 6.43012 12.3802 6.17931 12.1948 5.9939C12.0094 5.80849 11.7586 5.70332 11.4964 5.70105C11.2342 5.69877 10.9816 5.79956 10.793 5.98172L7.5 9.27472L6.207 7.98172C6.0184 7.79956 5.7658 7.69877 5.5036 7.70105C5.2414 7.70333 4.99059 7.80849 4.80518 7.9939C4.61977 8.17931 4.5146 8.43012 4.51233 8.69232C4.51005 8.95452 4.61084 9.20712 4.793 9.39572L6.793 11.3957C6.98053 11.5832 7.23484 11.6885 7.5 11.6885C7.76516 11.6885 8.01947 11.5832 8.207 11.3957L12.207 7.39572Z'
      fill={fillColor}
    />
  </svg>
);

CheckCircleIcon.propTypes = {
  className: string,
  fillColor: string,
  height: number,
  style: object,
  width: number,
};

export default CheckCircleIcon;
