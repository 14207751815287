import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  barrelsContainer: {},
}));

const Barrels = () => {
  const classes = useStyles();

  return (
    <Grid container direction='column' spacing={2} className={classes.barrelsContainer}></Grid>
  );
};

export default Barrels;
