const commonBtnStyles = {
  textTransform: 'none',
  transition: 'all 0.3s ease-in-out',
};

const containedOrangeButtonStyles = (theme) => ({
  ...commonBtnStyles,
  backgroundColor: theme.orange,
  color: theme.common.white,
  '&:hover': {
    opacity: 0.8,
    backgroundColor: theme.orange,
  },
});

export { commonBtnStyles, containedOrangeButtonStyles };
