import { useState, useCallback, useMemo } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAlert } from 'react-alert';
import { useMutation } from '@tanstack/react-query';
import jwt_decode from 'jwt-decode';
import { useNavigate, useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Collapse } from '@material-ui/core';
import { Divider } from 'antd';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import PasswordMatcher from 'components/PasswordMatcher';
import { LogoIcon } from 'components/Icons';
import { resetPassword } from 'store/auth/resetPassword';

const passwordValidationProps = [
  {
    text: 'An English uppercase character (A-Z)',
    type: 'matches',
    rule: /^.*[A-Z].*$/,
    errorMessage: 'An uppercase character is required',
  },
  {
    text: 'An English lowercase character (a-z)',
    type: 'matches',
    rule: /^.*[a-z].*$/,
    errorMessage: 'A lowercase character is required',
  },
  {
    text: 'A number (0-9) and/or symbol (such as !, #, or %)',
    type: 'matches',
    rule: /^.*([0-9]|[!@#$%^&*()]).*$/,
    errorMessage: 'A number and/or a symbol is required',
  },
  {
    text: 'Ten or more characters total',
    type: 'matches',
    rule: /^.{10,}$/,
    errorMessage: 'Ten or more characters are required',
  },
];

const useStyles = makeStyles((theme) => ({
  resetPasswordContainer: {
    height: 'inherit',
  },
  boxContainer: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '60%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '70%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '85%',
    },
    padding: theme.spacing(4),
    backgroundColor: theme.boxBackgroundColor,
    boxShadow: theme.boxShadow,
    borderRadius: 8,
  },
  resetPasswordText: {
    fontSize: '1.8rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.7rem',
    },
  },
  formElement: { width: '100%' },
  textField: {
    '& label.Mui-focused': {
      color: theme.brown,
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: theme.grey[600],
      },
      '&.Mui-error:hover fieldset': {
        borderColor: theme.muiError,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.brown,
      },
      '&.Mui-focused.Mui-error fieldset': {
        borderColor: theme.muiError,
      },
    },
    '& legend span': {
      padding: 10,
    },
  },
  button: {
    textTransform: 'none',
    borderRadius: 25,
    height: theme.spacing(6.5),
    fontWeight: 'bold',
    fontSize: '1.3rem',
    backgroundColor: theme.brown,
    color: theme.common.white,
    '&:hover': {
      color: theme.common.white,
      backgroundColor: theme.lightBrown,
      borderColor: theme.lightBrown,
    },
  },
  circular: { color: theme.common.white },
  resetPasswordErrorMessage: {
    paddingTop: theme.spacing(1.5),
    fontSize: '1rem',
    color: theme.muiError,
  },
  divider: { margin: 0 },
}));

const ResetPassword = () => {
  const classes = useStyles();
  const {
    common: { black },
  } = useTheme();
  const alert = useAlert();
  const navigate = useNavigate();

  const [showPasswordMatcher, setShowPasswordMatcher] = useState(false);

  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const { error, isLoading, mutate, reset } = useMutation({
    mutationFn: (data) =>
      resetPassword({
        email: jwt_decode(searchParams.get('token')).email,
        password: data.password,
        resetPwdToken: searchParams.get('token'),
      }),
    onSuccess: () => {
      alert.success(
        <div>
          <strong>New password created successfully.</strong>
        </div>,
      );
      navigate('/auth/login', { replace: true });
    },
  });

  const { handleSubmit, handleChange, handleBlur, getFieldProps, errors, values, touched } =
    useFormik({
      initialValues: {
        password: '',
        confirmPassword: '',
      },
      validationSchema: Yup.object({
        password: passwordValidationProps.reduce((obj, { type, rule, message }) => {
          obj = obj[type](rule, message);
          return obj;
        }, Yup.string().required('Password is required')),
        confirmPassword: Yup.string()
          .required('Password is required')
          .oneOf([Yup.ref('password'), null], 'Passwords must match'),
      }),
      onSubmit: (data) => {
        mutate(data);
      },
    });

  const handleFieldChange = useCallback(
    (e) => {
      if (error) reset();
      handleChange(e);
    },
    [error, handleChange],
  );

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      className={classes.resetPasswordContainer}
    >
      <Grid item className={classes.boxContainer}>
        <Grid container direction='column' alignItems='center' spacing={3}>
          <Grid item>
            <LogoIcon width={160} height={40} fillColor={black} />
          </Grid>
          <Grid item container direction='column' alignItems='center' spacing={4}>
            <Grid item>
              <div className={classes.resetPasswordText}>Enter New Password</div>
            </Grid>
            <Grid item container>
              <form className={classes.formElement} onSubmit={handleSubmit}>
                <Grid container direction='column' spacing={3}>
                  <Grid item>
                    <TextField
                      label='Password'
                      variant='outlined'
                      type='password'
                      fullWidth
                      classes={{ root: classes.textField }}
                      error={!!errors.password && !!touched.password}
                      // helperText={errors.password && touched.password && errors.password}
                      {...getFieldProps('password')}
                      onChange={handleFieldChange}
                      onBlur={(e) => {
                        setShowPasswordMatcher(false);
                        handleBlur(e);
                      }}
                      onFocus={() => setShowPasswordMatcher(true)}
                    />
                  </Grid>
                  <Collapse in={showPasswordMatcher}>
                    <Grid item>
                      <PasswordMatcher
                        value={values.password}
                        validationProps={passwordValidationProps}
                      />
                    </Grid>
                  </Collapse>
                  <Grid item>
                    <TextField
                      label='Confirm Password'
                      variant='outlined'
                      type='password'
                      fullWidth
                      classes={{ root: classes.textField }}
                      error={!!errors.confirmPassword && !!touched.confirmPassword}
                      helperText={
                        errors.confirmPassword && touched.confirmPassword && errors.confirmPassword
                      }
                      {...getFieldProps('confirmPassword')}
                      onChange={handleFieldChange}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={isLoading}
                      type='submit'
                      fullWidth
                      size='large'
                      classes={{ root: classes.button }}
                    >
                      {isLoading ? (
                        <CircularProgress size={30} classes={{ root: classes.circular }} />
                      ) : (
                        'Save'
                      )}
                    </Button>
                    {error && (
                      <div className={classes.resetPasswordErrorMessage}>{error.message}</div>
                    )}
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item container>
              <Divider className={classes.divider} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
