import { useMemo, useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { DateTime } from 'luxon';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlert } from 'react-alert';
import { Dropdown } from 'antd';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import NotificationMenuItem from './NotificationMenuItem';
import {
  NotificationIcon,
  UserProfileIcon,
  LogoIcon,
  WalletIcon,
  DiscordIcon,
} from 'components/Icons';
import { AppDataContext } from 'Context';
import useMediaQueries from 'hooks/mediaQueries';
import {
  queryNotificationsTypes,
  fetchNotifications,
  clickNotification,
  updateNotificationReadStatus,
  dismissNotification,
} from 'store/notifications';
import { browserTabAppTitle } from 'config/constants';
import styles from './styles';
import notificationBell from 'assets/images/notification-sleeping-bell.png';

const generateUpdatedHtmlTitle = (count) => `(${count}) New notifications`;

const useStyles = makeStyles(styles);

const Header = ({ toWalletRoute, toAdminRoutes }) => {
  const {
    onLogout,
    appData: { user, walletData },
  } = useContext(AppDataContext);
  const classes = useStyles();
  const {
    common: { black, white },
  } = useTheme();
  const { smMatch, xsMatch } = useMediaQueries();
  const alert = useAlert();

  const queryClient = useQueryClient();

  const [notificationsDropdownOpen, setNotificationsDropdownOpen] = useState(false);
  const [currentBrowserTabTitle, setCurrentBrowserTabTitle] = useState(browserTabAppTitle);
  const titleChangeIntervalRef = useRef(null);

  const { mutate: onUpdateNotificationReadStatus } = useMutation({
    mutationFn: updateNotificationReadStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryNotificationsTypes.notifications] });
    },
    onError: (err) => {
      alert.error(
        <div>
          <strong>An error occured while updating notification status</strong>
          <div>{err.message}</div>
        </div>,
      );
    },
  });

  const { mutate: onClickNotification } = useMutation({
    mutationFn: clickNotification,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryNotificationsTypes.notifications] });
    },
    onError: (err) => {
      alert.error(
        <div>
          <strong>An error occured while updating notification status</strong>
          <div>{err.message}</div>
        </div>,
      );
    },
  });

  const { mutate: onDismissNotification } = useMutation({
    mutationFn: dismissNotification,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryNotificationsTypes.notifications] });
    },
    onError: (err) => {
      alert.error(
        <div>
          <strong>An error occured while dismissing notification</strong>
          <div>{err.message}</div>
        </div>,
      );
    },
  });

  const { data: notifications } = useQuery({
    queryKey: [queryNotificationsTypes.notifications],
    queryFn: fetchNotifications,
    enabled: !!user,
    refetchInterval: 600000,
    refetchIntervalInBackground: true,
    onError: (err) => {
      alert.error(
        <div>
          <strong>An error occured while retrieving notifications</strong>
          <div>{err.message}</div>
        </div>,
      );
    },
  });

  const unreadNotifications = useMemo(
    () => (notifications ? notifications.filter((item) => !item.isRead) : []),
    [notifications],
  );

  // if there any unread notifications
  // trigger interval that updates browser tab title, with notifications counter
  useEffect(() => {
    clearInterval(titleChangeIntervalRef.current);
    if (notificationsDropdownOpen || !unreadNotifications.length) {
      setCurrentBrowserTabTitle(browserTabAppTitle);
      return;
    }

    titleChangeIntervalRef.current = setInterval(() => {
      setCurrentBrowserTabTitle((prev) =>
        prev === browserTabAppTitle
          ? generateUpdatedHtmlTitle(unreadNotifications.length)
          : browserTabAppTitle,
      );
    }, [2000]);
  }, [unreadNotifications, notificationsDropdownOpen]);

  // when user logs out, clear notifications and reset browser tab title
  useEffect(() => {
    if (!user) {
      clearInterval(titleChangeIntervalRef.current);
      queryClient.removeQueries({ queryKey: [queryNotificationsTypes.notifications] });
      setCurrentBrowserTabTitle(browserTabAppTitle);
    }
  }, [user]);

  const notificationMenuItems = useMemo(
    () =>
      notifications
        ? notifications.length
          ? notifications
              .sort((a, b) => DateTime.fromISO(b.created_at) - DateTime.fromISO(a.created_at))
              .map((item) => ({
                key: item.userNotification_id,
                label: (
                  <NotificationMenuItem
                    {...item}
                    onClickNotification={onClickNotification}
                    onUpdateNotificationReadStatus={onUpdateNotificationReadStatus}
                    onDismissNotification={onDismissNotification}
                  />
                ),
              }))
          : [
              {
                key: 'no notifications',
                label: (
                  <Grid
                    container
                    spacing={3}
                    className={classes.noNotificationsContainer}
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Grid item>
                      <img
                        src={notificationBell}
                        className={classes.notificationBellImage}
                        alt='no notifications'
                      />
                    </Grid>
                    <Grid item>
                      <div>You have no notifications</div>
                    </Grid>
                  </Grid>
                ),
              },
            ]
        : [],
    [notifications, onClickNotification, onUpdateNotificationReadStatus, onDismissNotification],
  );

  const profileMenuItems = useMemo(() => {
    const adminPagesLink = user?.isAdmin
      ? {
          key: 'admin',
          label: (
            <div className={classes.link}>
              <Link to='/admin'>Admin</Link>
            </div>
          ),
        }
      : null;

    return [
      adminPagesLink,
      {
        key: 'wallet',
        label: (
          <div className={classes.link}>
            <Link to='/sotw/mint'>Wallet</Link>
          </div>
        ),
      },
      {
        key: 'account',
        label: <div className={classes.link}>Account</div>,
      },
      {
        key: 'settings',
        label: (
          <div className={classes.link}>
            <Link to='/settings'>Settings</Link>
          </div>
        ),
      },
      {
        key: 'logout',
        label: (
          <div className={classes.link} onClick={onLogout}>
            Logout
          </div>
        ),
      },
    ].filter((v) => v);
  }, [user]);

  // if walletData are filled, we assume that we are on wallet route
  const { contractData, hideHeaderOnMint, onWalletConnect, onWalletReset } = walletData || {};

  return (
    <>
      <Helmet defer={false}>
        <title>{currentBrowserTabTitle}</title>
      </Helmet>

      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        className={`${classes.headerContainer} ${
          toAdminRoutes || hideHeaderOnMint
            ? 'hide'
            : !!toWalletRoute
            ? 'transparent'
            : user
            ? 'lightGreyBg'
            : ''
        }`}
      >
        <Grid item>
          <Link to='/home'>
            <LogoIcon
              className={classes.logo}
              width={xsMatch ? 120 : smMatch ? 140 : 160}
              height={xsMatch ? 30 : smMatch ? 35 : 40}
              fillColor={toWalletRoute ? white : black}
            />
          </Link>
        </Grid>
        {!!toWalletRoute ? (
          <Grid item>
            <Grid container spacing={smMatch ? 1 : 2}>
              <Grid item>
                <a href='https://discord.com/invite/wXAqWpGQ5w' target='_blank'>
                  <Button
                    size={smMatch ? 'medium' : 'large'}
                    variant='contained'
                    className={classes.discordBtn}
                    startIcon={<DiscordIcon fillColor={black} />}
                  >
                    Discord
                  </Button>
                </a>
              </Grid>
              <Grid item>
                <Button
                  size={smMatch ? 'medium' : 'large'}
                  variant='contained'
                  className={classes.walletConnectBtn}
                  startIcon={<WalletIcon fillColor={white} />}
                  onClick={contractData ? onWalletReset : onWalletConnect}
                >
                  {contractData ? 'Disconnect' : 'Connect'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : !!user ? (
          <Grid item>
            <Grid container spacing={3}>
              <Grid item>
                <Dropdown
                  onOpenChange={(isOpen) => {
                    if (isOpen) {
                      setNotificationsDropdownOpen(true);
                      queryClient.invalidateQueries({
                        queryKey: [queryNotificationsTypes.notifications],
                      });
                    } else setNotificationsDropdownOpen(false);
                  }}
                  trigger={['click']}
                  menu={{ items: notificationMenuItems }}
                  overlayClassName={`${classes.overlay} 
                  ${notificationMenuItems.length > 5 ? 'scrollable' : ''}
                   notifications`}
                  placement='bottomRight'
                >
                  <Badge
                    badgeContent={notificationsDropdownOpen ? 0 : unreadNotifications.length}
                    classes={{ badge: classes.badge }}
                    children={<NotificationIcon className={classes.actionIcon} />}
                    max={9}
                  />
                </Dropdown>
              </Grid>

              <Grid item>
                <Dropdown
                  trigger={['click']}
                  menu={{ items: profileMenuItems }}
                  overlayClassName={classes.overlay}
                  placement='bottomRight'
                >
                  <UserProfileIcon className={classes.actionIcon} />
                </Dropdown>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export default Header;
