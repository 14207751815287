import Grid from '@material-ui/core/Grid';
import { Dropdown } from 'antd';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useAlert } from 'react-alert';
import { VerticalDotsIcon, StatusBulletIcon } from 'components/Icons';
import { generatePastTime } from 'utils/formaters';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  notificationContainer: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(1)}px ${theme.spacing(1.5)}px 0`,
    backgroundColor: 'transparent',
    '&.isRead': {
      color: theme.common.lightBlack,
    },
  },
  subject: { fontSize: theme.spacing(2.1) },
  content: { fontSize: theme.spacing(1.8) },
  time: { fontSize: theme.spacing(1.6), color: theme.grey[600] },
  overlay: {
    '& .ant-dropdown-menu': { padding: 0 },
    '& .ant-dropdown-menu-item': {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
  },
  link: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(
      0.5,
    )}px`,
  },
}));

const NotificationMenuItem = (props) => {
  const {
    userNotification_id: id,
    subject,
    content,
    created_at,
    cta_destination,
    ctaClicked,
    isInformational,
    isRead,
    onClickNotification,
    onUpdateNotificationReadStatus,
    onDismissNotification,
  } = props;
  const classes = useStyles();
  const { orange } = useTheme();
  const alert = useAlert();

  const navigate = useNavigate();

  const actionItems = [
    {
      key: 'dismiss',
      label: (
        <div
          onClick={() => {
            onDismissNotification({ ids: [id], isDismissed: true });
          }}
          className={classes.link}
        >
          Dismiss
        </div>
      ),
    },
    !isInformational
      ? {
          key: 'mark',
          label: (
            <div
              onClick={() => {
                onUpdateNotificationReadStatus({ ids: [id], isRead: !isRead });
              }}
              className={classes.link}
            >
              {isRead ? 'Mark As Unread' : 'Mark As Read'}
            </div>
          ),
        }
      : null,
  ].filter((a) => a);

  return (
    <Grid
      onClick={(e) => {
        if (!cta_destination) e.stopPropagation(); //stops dropdown closing
        else navigate(cta_destination);
        if (!isRead)
          onUpdateNotificationReadStatus(
            { ids: [id], isRead: true },
            {
              onSuccess: () => {
                if (isInformational)
                  alert.success(
                    <div>
                      <strong>Notification marked as read</strong>
                    </div>,
                  );
              },
            },
          );
        if (!ctaClicked) onClickNotification({ ids: [id], ctaClicked: true });
      }}
      container
      spacing={2}
      alignItems='center'
      className={`${classes.notificationContainer} ${isRead ? 'isRead' : ''}`}
    >
      <Grid item xs={1}>
        {!isRead ? (
          <StatusBulletIcon width={15} height={15} strokeColor={orange} fillColor={orange} />
        ) : null}
      </Grid>
      <Grid item xs={10}>
        <Grid container direction='column' spacing={1}>
          <Grid item>
            <div className={classes.subject}>{subject}</div>
          </Grid>
          <Grid item>
            <div className={classes.content}>{content}</div>
          </Grid>
          <Grid item>
            <div className={classes.time}>
              {generatePastTime(created_at, {
                toArray: false,
                oneChar: false,
                displayTheBiggestOnly: true,
                splitDateTo: ['months', 'days', 'hours', 'minutes', 'seconds'],
              })}{' '}
              ago
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={1}
        onClick={(e) => {
          e.stopPropagation(); // prevents accidental click propagation to grid and update read status
        }}
      >
        <Dropdown menu={{ items: actionItems }} overlayClassName={classes.overlay}>
          <div className={classes.verticalDotsContainer}>
            <VerticalDotsIcon height={16} width={16} />
          </div>
        </Dropdown>
      </Grid>
    </Grid>
  );
};

export default NotificationMenuItem;
