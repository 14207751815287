import { methods, fetchApi } from 'utils/apiConfig';
const { GET, POST } = methods;

const queryWeb3Types = {
  isWhitelisted: 'isWhitelisted',
  whitelistOptions: 'whitelistOptions',
};

const generateSignature = (address) =>
  fetchApi('/web3-api-version-b/generateSignature', POST, {
    data: { address },
    headers: {
      [process.env.REACT_APP_LOGIN_HEADER_NAME]: process.env.REACT_APP_LOGIN_HEADER_VALUE,
    },
  });

const fetchWhitelistOptions = ({ signal }) =>
  fetchApi('/web3-api-version-b/WhitelistSettings', GET, {
    headers: {
      [process.env.REACT_APP_LOGIN_HEADER_NAME]: process.env.REACT_APP_LOGIN_HEADER_VALUE,
    },
    signal,
  });

const fetchIsWhiteListed = ({ signal, queryKey: [_, address] }) =>
  fetchApi('/web3-api-version-b/isWhiteListed', GET, {
    params: { address },
    headers: {
      [process.env.REACT_APP_LOGIN_HEADER_NAME]: process.env.REACT_APP_LOGIN_HEADER_VALUE,
    },
    signal,
  });

const saveTransactionDetails = (transaction_hash) =>
  fetchApi('/web3-api-version-b/saveTransactionDetails', POST, {
    data: { transaction_hash },
    headers: {
      [process.env.REACT_APP_LOGIN_HEADER_NAME]: process.env.REACT_APP_LOGIN_HEADER_VALUE,
    },
  });

export {
  queryWeb3Types,
  generateSignature,
  fetchWhitelistOptions,
  fetchIsWhiteListed,
  saveTransactionDetails,
};
